import { sessionService } from 'redux-react-session';
import * as sessionApi from '../api/sessionApi';
import swal from 'sweetalert2';
import RequestService from '../api/RequestService';

export const login = (
  user,
  id,
  history,
  pwhash,
  redirectAllowed,
  redirectPage
) => {
  return () => {
    return sessionApi.login(user).then((response) => {
      const { token } = response;
      sessionService
        .saveSession({ token })
        .then(() => {
          response.data.marketing = pwhash;

          sessionService
            .saveUser(response.data)
            .then(() => {
              fetch(
                `/api/getrole/${response.data.id}/${response.data.id}/${response.data.marketing}`,
                { method: 'get' }
              )
                .then((response) => {
                  return response.json();
                })
                .then(async (data) => {
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].Role === 'SuperAdmin') {
                      swal({
                        title: 'Signing you in!',
                        timer: 2000,
                        allowOutsideClick: false,
                        onOpen: () => {
                          swal.showLoading();
                        },
                      });

                      if (redirectAllowed > 0 && redirectPage !== '') {
                        setTimeout(() => {
                          window.location = redirectPage;
                        }, 500);
                      } else {
                        setTimeout(function () {
                          window.location = '/app/UserNotifications';
                        }, 500);
                      }

                      break;
                    } else if (data[i].Role === 'Admin') {
                      swal({
                        title: 'Signing you in!',
                        timer: 2000,
                        allowOutsideClick: false,
                        onOpen: () => {
                          swal.showLoading();
                        },
                      });

                      if (redirectAllowed > 0 && redirectPage !== '') {
                        setTimeout(() => {
                          window.location = redirectPage;
                        }, 500);
                      } else {
                        setTimeout(function () {
                          window.location = '/app/UserNotifications';
                        }, 500);
                      }

                      break;
                    } else {
                      try {
                        const { data } = await RequestService.get(
                          '/api/getSignupProgress'
                        );
                        if (data.sc === 0) {
                          swal({
                            title: 'Signing you in!',
                            timer: 2000,
                            allowOutsideClick: false,
                            onOpen: () => {
                              swal.showLoading();
                            },
                          }).then((result) => {
                            history.push({
                              pathname: '/register',
                              state: {
                                UserID: response.data.id,
                                PWHash: response.data.marketing,
                                Email: user.email,
                                RegistrationStep: 1,
                                UserType: data.fa === 1 ? 2 : 1,
                                NeedAcc: true,
                              },
                            });
                          });
                        } else if (data.sc === 1) {
                          swal({
                            title: 'Signing you in!',
                            timer: 2000,
                            allowOutsideClick: false,
                            onOpen: () => {
                              swal.showLoading();
                            },
                          }).then((result) => {
                            history.push({
                              pathname: '/register',
                              state: {
                                UserID: response.data.id,
                                PWHash: response.data.marketing,
                                Email: user.email,
                                RegistrationStep: 2,
                                UserType: data.fa === 1 ? 2 : 1,
                                NeedTOS: true,
                              },
                            });
                          });
                        } else {
                          // (data.sc === 2) {
                          swal({
                            title: 'Signing you in!',
                            timer: 2000,
                            allowOutsideClick: false,
                            onOpen: () => {
                              swal.showLoading();
                            },
                          });
                          let params = new URLSearchParams(
                            window.location.search
                          );
                          // Only allow Allowed Redirects, to prevent a XSS and CSRF Security Issues.
                          // history.push doesn't seem to work here, it shows them logged out.
                          if (redirectAllowed > 0 && redirectPage !== '') {
                            setTimeout(() => {
                              window.location = redirectPage;
                            }, 500);
                          } else if (
                            params.get('redirect') &&
                            params.get('redirect') != ''
                          ) {
                            localStorage.setItem('autoredirect', true);
                            setTimeout(() => {
                              window.location =
                                params.get('redirect') + window.location.search;
                            }, 500);
                          } else {
                            let entityId;
                            let FSstatus;
                            let myInvestments;
                            try {
                              const { data } = await RequestService.get(
                                `/api/getInvestorDwollaBankAccounts`
                              );
                              const { data: myInvestmentsData } =
                                await RequestService.get(`/api/myInvestments`);
                              if (data.code === 200) {
                                entityId = data?.data[0].EntityID;
                                if (entityId) {
                                  const { data: BankData } =
                                    await RequestService.post({
                                      url: `/api/getInvestorFundingSourceData`,
                                      data: {
                                        entityId,
                                      },
                                    });
                                  FSstatus = BankData;
                                }
                              }
                              if (myInvestmentsData.code === 200) {
                                myInvestments = myInvestmentsData.reg_d;
                              }
                            } catch (err) {
                              console.log(err);
                            }

                            if (FSstatus.length > 0) {
                              //  check new user
                              myInvestments?.length > 0
                                ? setTimeout(() => {
                                    window.location =
                                      '/app/InvestmentOpportunities';
                                  }, 500)
                                : setTimeout(() => {
                                    window.location = '/app/welcome';
                                  }, 500);
                            } else
                              setTimeout(() => {
                                window.location = '/app/regverified';
                              }, 500);
                          }
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    }
                  }
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    });
  };
};

export const logout = (history) => {
  return () => {
    return sessionApi
      .logout()
      .then(() => {
        sessionService.deleteSession();
        sessionService.deleteUser();
        history.push('/login');
      })
      .catch((err) => {
        throw err;
      });
  };
};
