import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";
import CollapsiblePanelComponent from "../CollapsiblePanelComponent/CollapsiblePanelComponent";
import { EnergyFunders_Prefered_Equity_Fund, formatMoney, getImpersonateID, getUUID, GP_Project_ID, ROI, Yield_Fund_2_ID } from "../../../utils/General";
import RequestService from "../../../api/RequestService";
import { withRouter } from "react-router-dom";
import InvestorEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorEarningsChart";
import swal from "sweetalert2";

class DistributionTableComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			itemsPerPage: props.itemsPerPage,
			totalItems: 0,
			selectedItemsList: [],
			pastRegDInvestments: [],
			projectDetails: [],
			showModal: false,
			selectedInvestorInvestment: [],
			yieldFund1MarketPrice: 10,
			gpTolpRequests: [],
		};
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage =
			this.handleChangeItemsPerPage.bind(this);
	}
	async getGpLpRequests() {
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/investor/getGpToLpRequests/${impersonateID}`);
		console.log("ddddddddddd", data?.data);
		if (data.code === 200) {
			this.setState({ gpTolpRequests: data?.data });
		}
	}
	componentDidMount() {
		const { itemsPerPage } = this.state;
		const { investorInvestmentsList } = this.props;
		let page = 1;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorInvestmentsList.slice(prev, next),
			totalItems: investorInvestmentsList.length,
		});
		this.getMarketPriceOfYF1();
		this.getGpLpRequests();
	}
	componentDidUpdate(prevProps) {
		const { investorInvestmentsList } = this.props;
		const { activePage } = this.state;
		const _investorEarningsList = JSON.stringify(
			prevProps.investorInvestmentsList
		);
		const strInvestorEarningsList = JSON.stringify(investorInvestmentsList);
		if (_investorEarningsList !== strInvestorEarningsList) {
			this.setState({ totalItems: investorInvestmentsList.length }, () =>
				this.handlePageChange(activePage, false)
			);
		}
	}
	async getMarketPriceOfYF1() {
		const { data } = await RequestService.get(`/api/getTzeroMarketPrice/ENFD`);
		const res = data?.result;
		this.setState({ ...this.state, yieldFund1MarketPrice: Number(res?.prevClosePx).toFixed(2) })
	}
	handlePageChange(page, scrollUp) {
		const { itemsPerPage } = this.state;
		const { investorInvestmentsList } = this.props;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorInvestmentsList.slice(prev, next),
		});
		if (scrollUp) {
			//document.getElementById('DistributionTableComponent').scrollIntoView();
		}
	}
	handleChangeItemsPerPage() {
		this.setState(
			{
				itemsPerPage: Number(this.refs.selectItemsPerPage.value), // Must be typecast.
				activePage: 1,
			},
			() => this.handlePageChange(1, true)
		);
	}

	render() {
		const { activePage, itemsPerPage, totalItems, selectedItemsList } =
			this.state;
		const refundedData = selectedItemsList?.find(i => i?.Deleted === 1 || i?.Refunded === 1);
		const yieldFund1LpData = selectedItemsList?.find(i => i?.ProjectName == 'EnergyFunders Yield Fund I LLC');
		const onGpToLpClick = async (investment) => {
			console.log("ineeeeee", investment)
			const result = await swal({
				title: `Are you sure you want to convert your ${investment?.ProjectID_RegD == GP_Project_ID ? "Class A GP" : "Class B"} shares in ${investment?.ProjectID_RegD == GP_Project_ID ? "Yield Fund I GP LLC" : `${investment?.ProjectName ?? ""}`} to Class A${investment?.ProjectID_RegD == GP_Project_ID ? ` LP` : ""} shares?`,
				text: `Once converted, the shares will remain Class A ${investment?.ProjectID_RegD == GP_Project_ID ? ` LP` : ''} shares for the remainder of the fund life. `,
				type: 'info',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonText: 'No',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
			});
			console.log("result", result);
			if (result.value) {
				let formBody = [];
				formBody.push("userId=" + encodeURIComponent(investment?.UserID));
				formBody.push("entityId=" + encodeURIComponent(investment?.EntityID));
				formBody.push("entityName=" + encodeURIComponent(investment?.Entity));
				formBody.push("userInvestmentId=" + encodeURIComponent(investment?.UserInvestmentID));
				formBody.push("userMembershipunitsId=" + encodeURIComponent(investment?.UserMembershipUnitID));
				formBody.push("investedAmount=" + encodeURIComponent(investment?.InvestmentAmount));
				formBody.push("investmentDate=" + encodeURIComponent(investment?.InvestmentDate));

				formBody = formBody.join("&");

				try {
					const { data: status } = await RequestService.post({
						url: `/api/investor/saveGpToLpRequest`,
						data: formBody,
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/x-www-form-urlencoded'
						}
					});
					if (status.code === 200) {
						this.getGpLpRequests();
						swal("Request submitted successfully. Your share conversion will be effective as of January 1, 2024.", "", "success")
					}
					else if (status.code === 400) {
						swal("Somthing went wrong", "", "error");
					}
				} catch (err) {
					swal("Somthing went wrong", "Please try again later", "error");
					console.log(err);
				}
			}
		}
		return (
			<div id="InvestmentsComponent">
				{this.props?.title && <h3>{this.props?.title}</h3>}
				{/* <p>As of {moment().format("MM/DD/YYYY h:mm A")}</p> */}
				<div className="col-xs-12 p-0">
					<fieldset>
						<div className="table-responsive">
							<table className="table table-striped jambo_table">
								<thead>
									<tr className="no-text-wrap">
										{/* <th>Investor</th> */}
										<th>Project</th>
										<th>Invested</th>
										{/* <th>Returned</th> */}
										<th>Investment Date</th>
										<th>Investor Entity</th>
										{!!yieldFund1LpData && <>
											<th>Fund Price</th>
											<th>MarketValue</th>
										</>}
										{/* <th>Notes</th> */}

										{!!refundedData && <th>Refunded</th>}
										{/* {!!deletedOrRefunded && <th>Deleted</th>} */}
									</tr>
								</thead>
								<tbody>
									{(() => {
										return selectedItemsList.map((p) => (

											<tr key={getUUID()}
												style={{ textDecoration: (p?.Refunded === 1 || p?.Deleted === 1) ? 'line-through' : '' }}>
												{console.log("ppppppppp", p)}
												{/* <td>
														{p.Entity}
												</td> */}
												<td>
													<div className="d-flex" style={{ justifyContent: 'space-between' }}>
														<span>{p.ProjectID_RegD === 0
															? p.InvestmentName
															: p.ProjectName}</span>
														{((p?.ProjectID_RegD == GP_Project_ID || (p?.ProjectID_RegD == Yield_Fund_2_ID && p?.GpLp == 1) || (p?.ProjectID_RegD == EnergyFunders_Prefered_Equity_Fund && p?.GpLp == 1)) && !(p?.Refunded === 1 || p?.Deleted === 1)) ? <span>
															{!this.state.gpTolpRequests?.find(i => i?.UserInvestmentId == p?.UserInvestmentID) ? <button className="btn" onClick={() => onGpToLpClick(p)} >Convert shares to Class A {p?.ProjectID_RegD == GP_Project_ID && "LP"}</button>
																: <span className="green">Shares converted to Class A {p?.ProjectID_RegD == GP_Project_ID && "LP"}, effective January 1, 2024.</span>} </span> : <></>}
													</div>

												</td>
												<td>
													$
													{formatMoney(
														p.InvestmentAmount
													)}
												</td>
												{/* <td>
													$
													{formatMoney(
														p.ReturnAmount
													)}
												</td> */}
												<td>
													{new Date(p.InvestmentDate).toLocaleDateString()}
												</td>
												{/* <td>{p.PaymentStatusNotes}</td> */}
												<td>
													{p.Entity}
												</td>
												{!!yieldFund1LpData && <>
													<td>{p?.ProjectName == 'EnergyFunders Yield Fund I LLC' ? `$${formatMoney(this.state?.yieldFund1MarketPrice)}` : ""}</td>
													<td>{p?.ProjectName == 'EnergyFunders Yield Fund I LLC' ? (`$${formatMoney(Number(this.state?.yieldFund1MarketPrice) * Number(p?.MembershipUnits))}`) : ""}</td>
												</>}
												{!!refundedData && <td>{p?.Refunded === 1 ? "Yes" : "No"}</td>}
												{/* {!!deletedOrRefunded && <td>{p?.Deleted === 1 ? "Yes" : "No"}</td>} */}
											</tr>
										));
									})()}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={
								<i className="glyphicon glyphicon-chevron-left" />
							}
							lastPageText={
								<i className="glyphicon glyphicon-chevron-right" />
							}
							prevPageText={
								<i className="glyphicon glyphicon-menu-left" />
							}
							nextPageText={
								<i className="glyphicon glyphicon-menu-right" />
							}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div
							className="pull-left"
							style={{ margin: "0 10px 0 0" }}
						>
							<select
								ref="selectItemsPerPage"
								style={{
									margin: "20px 0",
									padding: "0 10px",
									height: 32,
								}}
								onChange={this.handleChangeItemsPerPage}
							>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
DistributionTableComponent.propTypes = {
	investorInvestmentsList: PropTypes.array.isRequired,
	totalPaymentsRegCf: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	itemsPerPage: PropTypes.number.isRequired,
};
DistributionTableComponent.defaultProps = {
	investorInvestmentsList: [],
	totalPaymentsRegCf: 0,
	totalPaymentsRegD: 0,
	itemsPerPage: 10,
};

export default withRouter(DistributionTableComponent);
