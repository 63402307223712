import RequestService from '../../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST } from '../../../../utils/ApplicationConstants';
import NotificationService from '../../../../utils/NotificationService';
import { getImpersonateID } from '../../../../utils/General';
export const INVESTOR_FILES_START_REQUEST = 'INVESTOR_FILES_START_REQUEST';
export const INVESTOR_FILES_END_REQUEST = 'INVESTOR_FILES_END_REQUEST';
export const INVESTOR_FILES_GET_FILE_SUCCESS =
  'INVESTOR_FILES_GET_FILE_SUCCESS';
export const INVESTOR_CONTRACT_FILES_GET_FILE_SUCCESS =
  'INVESTOR_CONTRACT_FILES_GET_FILE_SUCCESS';
export const INVESTOR_FILES_GET_INVESTOR_FILE_TYPES_SUCCESS =
  'INVESTOR_FILES_GET_INVESTOR_FILE_TYPES_SUCCESS';
export const INVESTOR_FILES_GET_ALL_PROJECTS_SUCCESS =
  'INVESTOR_FILES_GET_ALL_PROJECTS_SUCCESS';
export const ALL_PROJECT_FILES = 'ALL_PROJECT_FILES';

const startRequest = () => ({ type: INVESTOR_FILES_START_REQUEST });
const endRequest = () => ({ type: INVESTOR_FILES_END_REQUEST });
const getFilesSuccess = (data) => ({
  type: INVESTOR_FILES_GET_FILE_SUCCESS,
  data,
});
const getContractFilesSuccess = (data) => ({
  type: INVESTOR_CONTRACT_FILES_GET_FILE_SUCCESS,
  data,
});
const successInvestorFileTypes = (data) => ({
  type: INVESTOR_FILES_GET_INVESTOR_FILE_TYPES_SUCCESS,
  data,
});
const getAllProjectSuccess = (data) => ({
  type: INVESTOR_FILES_GET_ALL_PROJECTS_SUCCESS,
  data,
});

const getAllProjectFiles = (data) => ({
  type: ALL_PROJECT_FILES,
  data,
});

export function uploadFilesToS3(investorId, data, isAdmin, clean = () => null) {
  return async (dispatch) => {
    const adminUrl = `/api/admin/investor/${investorId}/files`;
    const investorUrl = '/api/investor/files';
    const url = isAdmin ? adminUrl : investorUrl;

    dispatch(startRequest());

    const { status } = await RequestService.post({
      url,
      data,
    });

    dispatch(endRequest());

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error('Error uploading files to the server', 6000);
      return;
    }

    NotificationService.success();
    dispatch(getAllFilesForUser(investorId, isAdmin));
    clean();
  };
}

export function getAllFilesForUser(investorId, isAdmin) {
  return async (dispatch) => {
    const impersonateID = await getImpersonateID();
    const adminUrl = `/api/admin/investor/${investorId}/files/all`;
    const investorUrl = `/api/investor/files/all/${impersonateID}`;
    const url = isAdmin ? adminUrl : investorUrl;

    console.log('in action all files', isAdmin);
    console.log('in action all files url', url);

    dispatch(startRequest());

    const { data, status } = await RequestService.get(url);

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error(
        'Error extracting the files from the server',
        6000
      );
      dispatch(endRequest());
      return;
    }

    dispatch(getFilesSuccess(data));
  };
}

export function getContractFilesForUser(selectedInvestorId, isAdmin) {
  return async (dispatch) => {
    const impersonateID = selectedInvestorId
      ? selectedInvestorId
      : await getImpersonateID();
    const adminUrl = `/api/getHelloSignContractDocList/${impersonateID}`;
    const investorUrl = `/api/getHelloSignContractDocList`;
    // const investorUrl = '/api/getHelloSignContractDocList';
    console.log('in action contract', isAdmin);
    const url = adminUrl;
    console.log('url', impersonateID);
    console.log('in action contract url', url);

    dispatch(startRequest());

    const { data, status } = await RequestService.get(url);

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error(
        'Error extracting the files from the server',
        6000
      );
      dispatch(endRequest());
      return;
    }
    dispatch(getContractFilesSuccess(data));
  };
}

export function downloadFileFromServer(investorId, fileId, isAdmin) {
  return async (dispatch) => {
    const impersonateID = await getImpersonateID();
    const adminUrl = `/api/admin/investor/${investorId}/files/${fileId}/download`;
    const investorUrl = `/api/investor/files/${fileId}/download?impersonateID=${impersonateID}`;
    const url = isAdmin ? adminUrl : investorUrl;
    const otherConfig = { responseType: 'blob' };

    dispatch(startRequest());

    const { data, status, headers } = await RequestService.get(
      url,
      otherConfig
    );

    dispatch(endRequest());

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error('Error downloading the requested file', 6000);
      return;
    }

    const URL = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = URL;
    link.setAttribute('download', headers['file-name']);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

export function markFileAsDeleted(investorId, fileId, isAdmin) {
  return async (dispatch) => {
    const url = `/api/admin/investor/${investorId}/files/${fileId}`;

    dispatch(startRequest());

    const { status } = await RequestService.delete(url);

    dispatch(endRequest());

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error(
        'Error deleting the file from the server',
        6000
      );
      return;
    }

    dispatch(getAllFilesForUser(investorId, isAdmin));
  };
}

export function getAllInvestorFileTypes(investorId, isAdmin) {
  return async (dispatch) => {
    dispatch(startRequest());

    const adminUrl = `/api/admin/investor/${investorId}/files/types`;
    const investorUrl = '/api/investor/file/types';
    const url = isAdmin ? adminUrl : investorUrl;

    const { data, status } = await RequestService.get(url);

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error('', 6000);
      dispatch(endRequest());
      return;
    }

    dispatch(successInvestorFileTypes(data));
  };
}

export function getAllProjectsInServer() {
  return async (dispatch) => {
    dispatch(startRequest());

    const { data, status } = await RequestService.get(
      '/api/admin/projects/all'
    );
    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error(
        'Error extracting the projects from the server',
        6000
      );
      dispatch(endRequest());
      return;
    }

    dispatch(getAllProjectSuccess(data));
  };
}

export function updateInvestorFile(investorId, fileId, data, isAdmin) {
  return async (dispatch) => {
    const url = `/api/admin/investor/${investorId}/files/${fileId}`;

    dispatch(startRequest());

    const { status } = await RequestService.put({
      url,
      data,
    });

    dispatch(endRequest());

    if (status >= HTTP_ERR_BAD_REQUEST) {
      NotificationService.error('Error updating the file information', 6000);
      return;
    }

    NotificationService.success();
    dispatch(getAllFilesForUser(investorId, isAdmin));
  };
}

export const getAllProjectFile =
  (projectId, projectType) => async (dispatch) => {
    const dataCollection = [];

    try {
      dispatch(startRequest());
      const URL = `/api/project/${projectId}/details/documents/${projectType}`;
      const { data, status } = await RequestService.get(URL);
      dataCollection.push(data);

      dispatch(getAllProjectFiles(dataCollection));
    } catch (error) {
      dispatch(endRequest());
      console.log('Error requesting all the stored files: ERROR: ', error);
      NotificationService.error('Error updating the file information', 6000);
    }
  };
