import PropTypes from 'prop-types';
import React from 'react';
import RadioInputComponent from '../../InputComponents/RadioInputComponent/RadioInputComponent';
import InfoComponent from '../../ProjectCardComponent/InfoComponent';
import { getUUID } from '../../../../utils/General';


const getDisplayEntity = (props) => {
    const { entityType } = props;
    let displayEntity = null;

    if (entityType === 'LLC' || entityType === 'Corporation' || entityType === 'Partnership') {
        displayEntity = 'An Entity ';
    } else if (entityType === 'Rocket Dollar IRA') {
        displayEntity = 'An IRA ';
    } else if (entityType === 'Trust') {
        displayEntity = 'A Trust ';
    }

    return displayEntity;
};


class SecondStep extends React.Component {
    ACCREDITED_INVESTOR_OPTION = (
        <React.Fragment>
            {getDisplayEntity(this.props)}
            in which all of the equity owners are accredited investors as defined in 17 CFR $230.501.
            {' '}
            <InfoComponent>
                <div style={{ textAlign: 'left' }}>
                    <p>An Accredited Investor is a natural person:</p>
                    <p>
                        Whose individual net worth, or joint net worth with that person’s spouse, exceeds
                        $1,000,000, excluding their primary residence; or
                    </p>
                    <p>
                        Who had an individual income in excess of $200,000 in each of the two most recent
                        years or joint income with that person’s spouse in excess of $300,000 in each of
                        those years and has a reasonable expectation of reaching the same income level in
                        the current year.
                    </p>
                </div>
            </InfoComponent>
        </React.Fragment>
    );

    // Currently Unused, but will use in future. Commented out for now.
    PERSONAL_ENTITY = [
        {
            title: 'I earn more than $200K per year or I, jointly with my spouse, earn more than $300K per year.',
            value: '2',
        }, {
            title: 'I have more than $1M in assets, not including my primary residence.',
            value: '1',
        }, {
            title: 'None of the above apply to me.',
            value: '-1',
        },
    ];

    COMPANY_CORPORATION_OPTIONS = [
        {
            title: this.ACCREDITED_INVESTOR_OPTION,
            value: '6',
        }, {
            title: 'A Corporation, Company, Partnership, or 501(c)(3) Corporation, not formed for the specific '
                + 'purpose of acquiring the securities offered, with total assets in excess of $5,000,000.',
            value: '7',
        }, {
            title: 'None of the above apply to me.',
            value: '-1',
        },
    ];

    TRUST_OPTIONS = [
        {
            title: this.ACCREDITED_INVESTOR_OPTION,
            value: '6',
        }, {
            title: 'A Trust, with total assets in excess of $5,000,000, not formed for the specific purpose of '
                + 'acquiring the securities offered, whose purchase is directed by a person who has such knowledge '
                + 'and experience in financial and business matters that he or she is capable of evaluating the merits '
                + 'and risks of the prospective investment.',
            value: '8',
        }, {
            title: 'None of the above apply to me.',
            value: '-1',
        },
    ];

    IRA_OPTIONS = [
        {
            title: this.ACCREDITED_INVESTOR_OPTION,
            value: '6',
        }, {
            title: 'An IRA, with total assets in excess of $5,000,000, not formed for the specific purpose of '
                + 'acquiring the securities offered, whose purchase is directed by a person who has such knowledge '
                + 'and experience in financial and business matters that he or she is capable of evaluating the merits '
                + 'and risks of the prospective investment.',
            value: '8',
        }, {
            title: 'None of the above apply to me.',
            value: '-1',
        },
    ];

    renderQuestions = () => {
        const { entityType, onInputChange, questionAnswer } = this.props;
        let questionList = [];

        if (entityType === 'Personal') {
        	questionList = this.PERSONAL_ENTITY;
		}
        else if (entityType === 'LLC' || entityType === 'Corporation' || entityType === 'Partnership') {
            questionList = this.COMPANY_CORPORATION_OPTIONS;
        }
        else if (entityType === 'Trust') {
            questionList = this.TRUST_OPTIONS;
        }
        else if (entityType === 'Rocket Dollar IRA') {
            questionList = this.IRA_OPTIONS;
        }
        else if (entityType === 'IRA or Other Retirement Account') {
            questionList = this.IRA_OPTIONS;
        }

        return questionList.map((item) => {
            const { title, value } = item;

            return (
                <RadioInputComponent
                    key={getUUID()}
                    onClick={onInputChange}
                    checked={value === questionAnswer}
                    checkTitle={title}
                    elementName="questionAnswer"
                    elementValue={value}
                    className="no-capitalization"
                />
            );
        });
    };

    render = () => (
        <div className="container">
            <div className="col-xs-12">
                <div className="col-xs-12">
                    <fieldset>
                        <legend>We are required to collect this information to comply with regulations. Please select one of the statements below that best describes your entity:</legend>
                        {this.renderQuestions()}
                    </fieldset>
                </div>
            </div>
        </div>
    );
}


SecondStep.propTypes = {
    entityType: PropTypes.string.isRequired,
    questionAnswer: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
};

SecondStep.defaultProps = {
    entityType: '',
    questionAnswer: '',
    onInputChange: () => null,
};

export default SecondStep;
