/*
 * This is the /login page.
 * the login() function is kept in /src/actions/sessionActions.js
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sessionService } from 'redux-react-session';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert2';
import queryString from 'query-string';
import * as sessionActions from './actions/sessionActions';
import NavBar from './components/shared/NavigationManager/NavigationBar/NavBarNoAuth';
import FooterFirst from './components/shared/NavigationManager/Footer/FooterFirst';
import RequestService from './api/RequestService';
import {
  BUTTON_CONFIRM_COLOR,
  BUTTON_CANCEL_COLOR,
} from './utils/StyleConstants';
// import {withParallelMarketsHOC} from './components/investor/WithParallelMarketsHOC';

class Login extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);

    this.state = {
      loggingIn: false,
      user: {
        email: '',
        id: '',
        roles: {},
      },
      SITE_KEY: '6LcElCMhAAAAAD8TkK1BgugdzSngYxr7dlpIfu7J',
      data: '',
      id: '',
      checkUser: '',
      redirectPage:
        queryString.parse(this?.props?.location?.search)?.page ??
        params.get('redirect') + window.location.search,
      rmeChecked: false,

      AutoUID:
        props?.location?.state && props?.location?.state.AutoUID
          ? props?.location?.state?.AutoUID
          : params.get('userID')
          ? parseInt(params.get('userID'))
          : 0,
      AutoPWHash:
        props?.location?.state && props?.location?.state?.AutoPWHash
          ? props?.location?.state?.AutoPWHash
          : params.get('pwHash')
          ? params.get('pwHash')
          : '',
      AutoEmail:
        props?.location?.state && props?.location?.state?.AutoEmail
          ? props?.location?.state?.AutoEmail
          : params.get('email')
          ? params.get('email')
          : '',
    };
    this.onLogin = this.onLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.keydownHandler = this.keydownHandler.bind(this);
    this.forgotpassword = this.forgotpassword.bind(this);
    this.rememberMeClicked = this.rememberMeClicked.bind(this);
  }

  async componentDidMount() {
    await window.scrollTo(0, 0);

    await localStorage.clear();
    const params = new URLSearchParams(this.props.location.search);

    console.log(
      'in login.js to save outside condition',
      params?.get('invitecode')
    );
    if (!!params?.get('invitecode') && params?.get('invitecode') != '') {
      localStorage.setItem(
        'invitecode',
        params?.get('invitecode')?.trim()?.toLowerCase()
      );
      // console.log("invitecode",localStorage.getItem('invitecode'));
    }
    console.log(
      'in login after  invitecode:',
      localStorage.getItem('invitecode')
    );
    await sessionService.deleteSession();
    await sessionService.deleteUser();
    await document.addEventListener('keydown', this.keydownHandler);
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${this.state.SITE_KEY}`,
      () => {
        console.log('Script loaded!');
      }
    );
    if (
      this.state.AutoUID > 0 &&
      this.state.AutoPWHash &&
      this.state.AutoEmail
    ) {
      const { data } = await RequestService.get(
        `/api/getUserID/${this.state.AutoEmail}`
      );

      if (data.length > 0) {
        this.setState(
          {
            user: {
              email: this.state.AutoEmail,
              id: data[0].UserID,
              role: data[0].roles,
            },
          },
          () => {
            const { login } = this.props.actions;

            login(
              this.state.user,
              this.state.AutoUID,
              this.props.history,
              this.state.AutoPWHash,
              0,
              ''
            );
            console.log(
              'rrrr',
              this.state.user,
              this.state.AutoUID,
              this.props.history,
              this.state.AutoPWHash,
              0,
              ''
            );
          }
        );
      }
    } else {
      document.getElementById('email').focus();
      const UserLoginCookie = document.cookie.replace(
        /(?:(?:^|.*;\s*)USER_LOGIN\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      );

      this.setState({ rmeChecked: UserLoginCookie });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydownHandler);
  }

  forgotpassword() {
    swal({
      title: 'Please enter your registered email to reset your password:',
      input: 'email',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (email) =>
        new Promise((resolve) => {
          setTimeout(async () => {
            if (email) {
              try {
                const { data: status } = await RequestService.post({
                  url: '/api/forgotPassword',
                  data: `email=${email}`,
                });

                if (status.code === 200) {
                  resolve();
                } else if (status.code === 400) {
                  swal(`Error:${status.message.code}`, ' ', 'error');
                } else {
                  swal(`Error:${status.message.code}`, ' ', 'error');
                }
              } catch (err) {
                console.log(err);
                swal('Error occured', ' ', 'error');
              }
            }
          }, 2000);
        }),
      allowOutsideClick: () => !swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        swal({
          type: 'success',
          title: 'Success!',
          html: `Email sent to: ${result.value}`,
        });
      }
    });
  }

  onChange(e) {
    const { value, name } = e.target;
    const { user } = this.state;

    user[name] = value;
    this.setState({ user });

    e.target.classList.remove('login-form-control-warning');
  }

  searchParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const utm_source = searchParams?.get('utm_source');
    const utm_medium = searchParams?.get('utm_medium');
    const utm_campaign = searchParams?.get('utm_campaign');
    const utm_term = searchParams?.get('utm_term');
    const utm_content = searchParams?.get('utm_content');
    const data = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };

    sessionStorage.setItem('utm_codes', JSON.stringify(data));
  }

  async onLogin() {
    if (this.refs.email.value !== '' && this.refs.pass.value !== '') {
      const { data } = await RequestService.get(
        `/api/getUserID/${this.refs.email.value}`
      );

      if (data.length > 0) {
        this.setState({
          user: {
            email: this.refs.email.value,
            id: data[0].UserID,
            role: data[0].roles,
          },
        });

        this.onSubmit(
          data[0].UserID,
          this.props.history,
          this.refs.email.value
        );
      } else {
        this.setState({ loggingIn: false });
        swal(
          'Invalid email or password.',
          'Please try again or Register',
          'error'
        );
      }
    } else {
      if (this.refs.email.value === '') {
        this.refs.email.classList.add('login-form-control-warning');
      }

      if (this.refs.pass.value === '') {
        this.refs.pass.classList.add('login-form-control-warning');
      }

      swal('Please enter your email and password.', ' ', 'info');
    }
  }

  async addOpportunityCode(id) {
    const invitecode = localStorage.getItem('invitecode');

    console.log(invitecode);
    console.log(!!invitecode);
    console.log(invitecode != '');
    if (!!invitecode && invitecode != '') {
      console.log('userId:', id, 'invitecode:', invitecode);
      try {
        const { data } = await RequestService.post({
          url: `/api/addInviteCode/${id}/${invitecode}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        console.log('Opportunity fund user added ', data);
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  async onSubmit(id, history, email) {
    this.searchParams();
    const { user } = this.state;
    const { login } = this.props.actions;

    window.grecaptcha.ready(async () => {
      const val = await window.grecaptcha.execute(this.state.SITE_KEY, {
        action: 'submit',
      });

      console.log('recaptcha verified', val);
      if (val) {
        let formBody = [];

        formBody.push(
          `email=${encodeURIComponent(this.refs.email.value.toLowerCase())}`
        );
        formBody.push(`pass=${encodeURIComponent(this.refs.pass.value)}`);
        formBody.push(
          `rme=${encodeURIComponent(this.refs.rememberMe.checked ? 1 : 0)}`
        );
        formBody.push(`recapToken=${encodeURIComponent(val)}`);

        if (
          this.state.redirectPage !== '' &&
          this.state.redirectPage !== undefined
        ) {
          formBody.push(`rdp=${encodeURIComponent(this.state.redirectPage)}`);
        }

        formBody = formBody.join('&');

        const { data: status } = await RequestService.post({
          url: '/api/login',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: formBody,
        });

        if (status.code === 200) {
          await this.addOpportunityCode(id);
          login(
            user,
            id,
            history,
            status.pwhash,
            status.rdp,
            this.state.redirectPage
          );
        } else if (status.code === 444) {
          swal('Invalid Recaptcha Token.', 'Please try again.', 'error');
        } else if (status.code === 201) {
          if (status.gamfa) {
            const { value } = await swal({
              title: 'Enter code from Google Authenticator',
              text: 'Check Google Authenticator',
              type: 'info',
              input: 'text',
              inputPlaceholder: 'Enter Code',
              showCancelButton: true,
              confirmButtonColor: BUTTON_CONFIRM_COLOR,
              cancelButtonColor: BUTTON_CANCEL_COLOR,
              confirmButtonText: 'Submit',
              inputValidator: (value) =>
                !(value.replace(/\s/g, '') / 0 === 1 / 0) &&
                'The input must be a number!',
            });

            if (value) {
              try {
                let formBody = [];

                formBody.push(`id=${encodeURIComponent(id)}`);
                formBody.push(
                  `gamfatoken=${encodeURIComponent(value.replace(/\s/g, ''))}`
                );
                formBody.push(`ts=${encodeURIComponent(status.ts)}`);
                formBody.push(`rdp=${encodeURIComponent(status.rdp)}`);
                formBody.push(
                  `rme=${encodeURIComponent(
                    this.refs.rememberMe.checked ? 1 : 0
                  )}`
                );

                formBody = formBody.join('&');

                const { data: statusMFA } = await RequestService.post({
                  url: '/api/googleAuthMFA',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  data: formBody,
                });

                if (statusMFA.code === 200) {
                  // success -- perform login function
                  await this.addOpportunityCode(id);
                  login(
                    user,
                    id,
                    history,
                    status.pwhash,
                    status.rdp,
                    this.state.redirectPage
                  );
                } else if (statusMFA.code === 201) {
                  // Code failure, display error, then return to login & wipe cookies.
                  swal('Invalid Code.', ' ', 'error').then(
                    (window.location = '/login')
                  );
                } else if (statusMFA.code === 202) {
                  // user or time failure, display error, then return to login & wipe cookies.
                  swal(
                    'Invalid User or you took too long',
                    'If you feel this to be in error, please contact support.',
                    'error'
                  ).then((window.location = '/login'));
                } else {
                  // failure -- return to login & wipe cookies.
                  window.location = '/login';
                }
              } catch (e) {}
            }
          }
        } else if (status.code === 400) {
          this.setState({ loggingIn: false });
          swal(
            'Invalid email or password.',
            'Please try again or Register',
            'error'
          );
        } else if (status.code === 204) {
          this.setState({ loggingIn: false });
          swal('Please enter valid credentials', ' ', 'error');
        } else if (status.code === 206) {
          this.setState({ loggingIn: false });
          swal('Account Locked', 'Please try again after 1 hour', 'error');
        } else if (status.code === 210) {
          this.setState({ loggingIn: false });
          swal({
            title: 'Please verify your Phone Number to login',
            text: `Resend Verification code to +1${status?.phone} ?`,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#5bb462',
            cancelButtonColor: '#3fc3ee',
            confirmButtonText: 'Resend Code',
          }).then(async (result) => {
            // alert(JSON.stringify(status));
            if (result.value) {
              const { data: resend } = await RequestService.get(
                `/api/resendVerificationCode/${status?.UserId}/${status?.phone}`
              );
              // console.log("rrr",resend);

              if (resend?.code === 200) {
                localStorage.setItem('Email', email);
                history.push({
                  pathname: '/register/step1',
                  state: {
                    RegistrationStep: 0.5,
                    regStepZeroPointFiveCompleted: true,
                    currentStep: 0.5,
                    UserID: id,
                    PWHash: status?.pwhash,
                    EntityID: status?.EntityID,
                  },
                });
              } else {
                swal(
                  'Please contact Customer Support.',
                  'Your account requires further assistance. Please call customer support at 713-863-3297',
                  'error'
                );
              }
            }
          });
        } else if (status.code === 205) {
          this.setState({ loggingIn: false });
          swal({
            title: 'Please verify Email to login',
            text: "Didn't receive any Email?",
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#5bb462',
            cancelButtonColor: '#3fc3ee',
            confirmButtonText: 'Resend Email',
          }).then((result) => {
            // alert(JSON.stringify(status));
            if (result.value) {
              history.push({
                pathname: this.props.isMarketplace
                  ? '/marketplace/resendmail'
                  : '/resendmail',
                state: {
                  Email: email,
                  UserID: id,
                  PWHash: status.pwhash,
                  AutoSend: 1,
                },
              });
            }
          });
        }
      }
    });
  }

  rememberMeClicked() {
    this.setState({ rmeChecked: !this.state.rmeChecked }, () => {
      if (!this.state.rmeChecked) {
        document.cookie =
          'USER_LOGIN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      }
    });
  }

  keydownHandler(e) {
    if (e.keyCode === 13) {
      this.onLogin();
    }
  }

  render() {
    const {
      user: { email, password },
      loggingIn,
    } = this.state;
    const SubmitButton = withRouter(({ history }) => (
      <button
        className="btn btn-primary center"
        style={{
          height: 45,
          width: 150,
          backgroundColor: '#5bb462',
          color: '#fff',
          margin: '5px 5px 5px 0',
          border: '0',
        }}
        disabled={loggingIn}
        onClick={() => {
          this.setState({ loggingIn: !loggingIn });
          this.onLogin();
        }}
        type="submit"
      >
        {loggingIn ? 'Please Wait...' : 'Login'}
      </button>
    ));
    const cursorPointer = { cursor: 'pointer' };

    return (
      <div className="container white-bg">
        <div>
          <NavBar isMarketplace={this.props.isMarketplace} />

          <div style={{ overflow: 'hidden' }}>
            <div className="col-md-8 paddingLeft-30px">
              <div
                className="panel panel-primary"
                style={{
                  border: 0,
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <div>
                  <p
                    className=""
                    style={{
                      fontSize: 24,
                      textAlign: 'left',
                      padding: '10px 0 0 0',
                    }}
                  >
                    Sign In
                  </p>
                </div>

                <div className="panel-body">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={this.onChange.bind(this)}
                        ref="email"
                        className="login-form-control form-control"
                        autoComplete="new-password"
                      />
                      <span id="error_email" className="text-danger" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="pass"
                        name="password"
                        value={password}
                        onChange={this.onChange.bind(this)}
                        ref="pass"
                        className="login-form-control form-control"
                        autoComplete="new-password"
                      />
                      <span id="error_password" className="text-danger" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <div className="check_tc" style={{ marginTop: 10 }}>
                        <label className="custom-container">
                          Remember Me
                          <input
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                            ref="rememberMe"
                            onClick={this.rememberMeClicked}
                            checked={this.state.rmeChecked}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-md-6 col-sm-6 col-xs-12 margin-top-10px">
                    <SubmitButton className="btn btn-primary" />{' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="to_register"
                      style={cursorPointer}
                      onClick={this.forgotpassword}
                    >
                      {' '}
                      Forgot Password?{' '}
                    </a>
                    <div className="clearfix" />
                    {/* 1/10/24: Disable registration for now as we determine next steps in the life of the application. */}
                    {/* <div style={{ marginTop: 10 }}>
                                            {(() => {
                                                if (this.props.isMarketplace) {
                                                    return (<a href="/marketplace/register" rel="noopener noreferrer"><p className="font-bold" style={{ textAlign: 'left' }}>Don't have an account? Click here to Register.</p></a>);
                                                }

                                                return (<a href="/register" rel="noopener noreferrer"><p className="font-bold" style={{ textAlign: 'left' }}>Don't have an account? Click here to Register.</p></a>);
                                            })()}
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 nopadding imghide"
              style={{ paddingLeft: 0 }}
            />
          </div>
          {/* <div className='paddingLeft-30px mb-5'><p className="text-black" style={{fontStyle:'italic', fontWeight:'bold'}}><b style={{textAlign:'center'}}>IMPORTANT NOTICE:</b> Due to payment processing lag times, if you would like your investment to count toward the 2022 tax year, please WIRE YOUR INVESTMENT to us. If you invest via our online portal at this time, the funds may not reach us by year end. Please call us at <a href="tel:7138633297" className='text-green text-decoration-underline'>713-863-3297</a> or email at <a href="mailto: info@energyfunders.com" className='text-green text-decoration-underline'>info@energyfunders.com</a> for details on how to wire your investment.</p></div> */}
          <FooterFirst isMarketplace={this.props.isMarketplace} />
        </div>
      </div>
    );
  }
}

const { object } = PropTypes;

Login.propTypes = {
  actions: object.isRequired,
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(sessionActions, dispatch),
});

export default withRouter(connect(null, mapDispatch)(Login));
