import React, { Component, useEffect } from 'react';
import swal from 'sweetalert2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Stepper from 'react-stepper-horizontal';
import moment from 'moment';
import RequestService from './api/RequestService';
import { getHash, getImpersonateID } from './utils/General';
import CheckboxInputComponent from './components/shared/InputComponents/CheckboxInputComponent/CheckboxInputComponent';
import HideComponent from './components/shared/HideComponent/HideComponent';
import { ROCKET_DOLLAR_IRA_ACCOUNT } from './utils/ApplicationConstants';

const Observer = ({ value, didUpdate }) => {
    useEffect(() => {
        console.log('useEffect', value);
        if (value.status === 'done' && !!value.EntityID && !!value.selectedEntityType) {
            console.log('configuring dwolla');
            didUpdate(value.EntityID);
        } else {
            console.log('Skip configuring dwolla');
        }
    }, [value]);
    return null; // component does not render anything
};

class Setupprofile4 extends Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search);

        console.log('method', params.get('Method'));
        console.log('location', this?.props?.location);
        this.state = {
            id: props.session.id,
            EntityID: props?.location?.state?.EntityID
                ? props?.location?.state?.EntityID
                : '',
            FirstEntityID: props?.location?.state?.EntityID,
            Method: props?.location?.state?.Method ?? params?.get('Method'),
            Token: props?.location?.state?.token,
            Entities: [],
            isLoaded: false,
            errorOnLoad: false,
            IsRocketDollarIRA: false,
            ivaClicked: false,
            EntitySelectedForMd: false,
            selectedEntityType: '',
            selectedDwollaCustomerId: '',
            steps: [
                { title: 'Verify Your Identity' },
                { title: 'Connect Funds' },
            ],
            currentStep: 1,
            showFinishButton: false,
            status: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const location = this?.props?.location;
        const addMissingAccount = location?.state?.addMissingAccount;

        console.log('addmissing account', addMissingAccount);
        console.log('state value', this.state);
        if (addMissingAccount) {
            this.getInvestorEntitiesWithoutFundingSource();
        } else {
            this.getInvestorEntitiesAvailable();
        }

        this.doLoad();
    }

	loadScript = (src) => new Promise((resolve, reject) => {
	        const script = document.createElement('script');

	        script.src = src;
	        script.addEventListener('load', () => {
	            resolve();
	        });
	        script.addEventListener('error', (e) => {
	            reject(e);
	        });
	        document.body.appendChild(script);
	    });

	doLoad = () => {
	    const self = this;

	    if (window.dwolla) {
	        self.setState({ status: 'done' });
	        console.log('window.dwolla = ', window.dwolla);
	        return;
	    }
	    const my_script = this.loadScript(
	        this.state.Method === 'md'
	            ? 'https://cdn.dwolla.com/v2.2.0/dwolla-web.js'
	            : 'https://cdn.dwolla.com/1/dwolla.js',
	    );

	    my_script
	        .then(() => {
	            self.setState({ status: 'done' });
	            console.log('window.dwolla = ', window.dwolla);
	        })
	        .catch((err) => {
	            self.setState({ status: 'error' });
	            console.log('Dwolla load failed', err);
	        });
	};

	iav = async () => {
	    const EntityID = this.refs.Entity.value;

	    document.getElementById('entity').setAttribute('disabled', 'disabled');

	    if (EntityID > 0) {
	        swal({
	            title: 'Loading...',
	            text: 'Please wait a few seconds',
	            timer: 4000,
	            allowOutsideClick: false,
	            onOpen: () => {
	                swal.showLoading();
	            },
	        });

	        const { data } = await RequestService.get(
	            `/api/getDwollaIAVToken/${EntityID}`,
	        );

	        if (data.code === 200) {
	            const { dwolla } = window;

	            this.refs.addBankButton.setAttribute('style', 'display: none;');

	            // if (data.dwolla_environment !== "production") {
	            // 	dwolla.configure("sandbox");
	            // }

	            dwolla.iav.start(
	                data.token,
	                {
	                    container: 'iavContainer',
	                    stylesheets: [
	                        // 'https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext',
	                    ],
	                    microDeposits: false,
	                    fallbackToMicroDeposits: true,
	                },
	                async (err, res) => {
	                    if (!err) {
	                        // console.log(res,"dwolla response");
	                        let formBody = [];

	                        formBody.push(
	                            `data=${encodeURIComponent(
	                                JSON.stringify(res),
	                            )}`,
	                        );
	                        formBody.push(
	                            `fundingsource=${encodeURIComponent(
	                                res._links['funding-source'].href,
	                            )}`,
	                        );
	                        formBody.push(`EntityID=${EntityID}`);
	                        formBody = formBody.join('&');

	                        const { data: status } = await RequestService.post({
	                            url: '/api/createIAVFundingSource',
	                            data: formBody,
	                            headers: {
	                                Accept: 'application/json',
	                                'Content-Type':
										'application/x-www-form-urlencoded',
	                            },
	                        });

	                        if (status.code === 200) {
	                            document
	                                .getElementById('finishButtonIAV')
	                                .setAttribute(
	                                    'style',
	                                    'backgroundColor: #1cd9d6; display: \'\';',
	                                );
	                            // console.log('Error: ' + JSON.stringify(err) + ' -- Response: ' + JSON.stringify(res));
	                        } else if (status.code === 400) {
	                            alert(
	                                'Error occured while adding bank account.',
	                            );
	                        }
	                    } else {
	                        console.log(err);
	                        swal(
	                            'Error while adding bank account.',
	                            `${JSON.stringify(err)}`,
	                            'error',
	                        );
	                    }
	                },
	            );
	        } else {
	            alert('Could not connect to Dwolla. Please try again later.');
	        }
	    } else {
	        swal('Please Select An Entity', '', 'info').then(() => {
	            document.getElementById('entity').removeAttribute('disabled');
	        });
	    }
	};

	onSubmitMicroDeposits = async (e) => {
	    const { selectedEntityType } = this.state;
	    const isIRAAccount =			selectedEntityType === 'IRA or Other Retirement Account';

	    if (this.refs.Entity.value === '-1') {
	        swal('Please Select An Entity', '', 'info');
	    } else if (
	        this.refs.rnum.value.trim() === ''
			|| this.refs.anum.value.trim() === ''
	    ) {
	        swal(
	            'Please correctly enter your routing and account numbers.',
	            '',
	            'info',
	        );
	    } else if (this.refs.name.value === '') {
	        swal('Please give your account a Name.', '', 'info');
	    } else {
	        e.preventDefault();
	        this.refs.mdSubmitButton.setAttribute('disabled', 'disabled');
	        this.refs.mdSubmitButton.innerHTML = 'Please Wait ... ';

	        let formBody = [];

	        formBody.push(`id=${encodeURIComponent(this.state.id)}`);
	        formBody.push(`Eid=${this.refs.Entity.value}`);
	        formBody.push(`atype=${encodeURIComponent(this.refs.atype.value)}`);
	        formBody.push(
	            `rnum=${encodeURIComponent(this.refs.rnum.value.trim())}`,
	        );
	        formBody.push(
	            `anum=${encodeURIComponent(this.refs.anum.value.trim())}`,
	        );
	        formBody.push(`name=${encodeURIComponent(this.refs.name.value)}`);
	        formBody.push(
	            `IsRocketDollarIRA=${
	                isIRAAccount ? this.state.IsRocketDollarIRA : 'false'
	            }`,
	        );

	        formBody = formBody.join('&');

	        // do fetch call
	        const { data: status } = await RequestService.post({
	            url: '/api/createMDFundingSource',
	            data: formBody,
	            headers: {
	                Accept: 'application/json',
	                'Content-Type': 'application/x-www-form-urlencoded',
	            },
	        });

	        if (status.code === 200) {
	            this.refs.finishButtonMD.setAttribute(
	                'style',
	                'backgroundColor: #1cd9d6; display: \'\';',
	            );
	            swal(
	                'Microdeposits sent to your account',
	                'Please check your account in the next 48 hours',
	                'success',
	            ).then(this.props.history.push('/app/Allinvestments'));
	        } else if (status.code === 400) {
	            swal(status.msg, '', 'info').then(() => {
	                if (
	                    status.msg
						=== 'Micro deposits already initiated for this funding source.'
	                ) {
	                    this.props.history.push('/app/verifymicrodeposits');
	                } else {
	                    this.refs.mdSubmitButton.removeAttribute('disabled');
	                    this.refs.mdSubmitButton.innerHTML = 'Submit ';
	                }
	            });
	        } else {
	            alert('Error occured');
	        }
	    }
	};

	onFinish = () => {
	    const iframe = document.getElementById('iavContainer').children[0];

	    if (!iframe) return;

	    iframe.src = null;
	    iframe.innerHTML = '';
	    window.location = '/app/Allinvestments';
	};

	onCancel = () => {
	    window.location = '/app/Allinvestments'; // changed because changing location causes double adding of bank accounts.
	};

	getInvestorEntitiesWithoutFundingSource = async () => {
	    try {
	        const { data } = await RequestService.get(
	            '/api/getInvestorEntitiesWithoutFundingSource',
	        );

	        if (data.rows.length === 1) {
	            this.setState({
	                EntityID: data.rows[0].EntityID,
	                Entities: data.rows,
	            });
	        } else if (data.rows.length > 1) {
	            this.setState({
	                Entities: data.rows,
	            });
	        } else {
	            swal(
	                data.error,
	                'Please contact investor relations if you think this message is in error.',
	                'error',
	            ).then(this.props.history.push('/app/Allinvestments'));
	        }
	    } catch (err) {
	        console.log(err);
	    }
	};

	getInvestorEntitiesAvailable = async () => {
	    try {
	        const impersonateID = await getImpersonateID();
	        const { data } = await RequestService.get(
	            `/api/getInvestorEntitiesAvailable/${impersonateID}`,
	        );

	        if (data.rows.length === 1) {
	            this.setState({
	                EntityID: data.rows[0].EntityID,
	                Entities: data.rows,
	            });
	        } else if (data.rows.length > 1) {
	            this.setState({
	                Entities: data.rows,
	            });
	        } else {
	            swal(
	                data.error,
	                'Please contact investor relations if you think this message is in error.',
	                'error',
	            ).then(this.props.history.push('/app/Allinvestments'));
	        }
	    } catch (err) {
	        console.log(err);
	    }
	};

	render() {
	    const { selectedEntityType, selectedDwollaCustomerId } = this.state;
	    const pathname = window.location.pathname.toLocaleLowerCase();

	    if (pathname.match('/setupprofile4')) {
	        document.body.classList.add('videntity-vcenter');
	    }
	    const openMDDwollaDropInComponent = (EntityID) => {
	        const { dwolla } = window;

	        console.log('status', this.state?.status, 'dwolla', dwolla);
	        if (!this.state.EntityID || !this.state.selectedEntityType) {
	            console.log('entityid or selectedentitytype not found', this.state);
	            swal('Please Select An Entity', '', 'info');
	        } else {
	            dwolla.configure({
	                environment: 'production',
	                tokenUrl: `/api/getDwollaToken/${this.state?.id}/${EntityID}`,
	                success: (res) => Promise.resolve(res).then(async (res) => {
	                        console.log('success the res is', res);
	                        const { selectedEntityType } = this?.state;
	                        const isIRAAccount =								selectedEntityType
								=== 'IRA or Other Retirement Account';

	                        let formBody = [];

	                        formBody.push(
	                            `id=${encodeURIComponent(this.state?.id)}`,
	                        );
	                        formBody.push(
	                            `Eid=${encodeURIComponent(EntityID)}`,
	                        );
	                        formBody.push(
	                            `fundingResourceResponce=${encodeURIComponent(
	                                JSON.stringify(res),
	                            )}`,
	                        );
	                        formBody.push(
	                            `IsRocketDollarIRA=${encodeURIComponent(
	                                isIRAAccount,
	                            )}`,
	                        );

	                        formBody = formBody.join('&');
	                        console.log('formbody', formBody);
	                        // do fetch call
	                        try {
	                            if (pathname.match('/setupprofile4')) {
	                                const { data: status } =										await RequestService.post({
										    url: '/api/MDFundingSourceCreated',
										    data: formBody,
										    headers: {
										        Accept: 'application/json',
										        'Content-Type':
													'application/x-www-form-urlencoded',
										    },
	                            });

	                                if (status.code === 200) {
	                                    this.setState({
	                                        showFinishButton: true,
	                                    });
	                                    swal(
	                                        'Microdeposits sent to your account',
	                                        'Please check your account in the next 48 hours',
	                                        'success',
	                                    ).then(() => {
	                                        this.props.history.push(
	                                            '/app/Profile',
	                                        );
	                                        window.location.reload();
	                                    });
	                                } else if (status.code === 400) {
	                                    swal(status.msg, '', 'info').then(
	                                        () => {
	                                            if (
	                                                status.msg
													=== 'Micro deposits already initiated for this funding source.'
	                                            ) {
	                                                this.props.history.push(
	                                                    '/app/verifymicrodeposits',
	                                                );
	                                                window.location.reload();
	                                            } else {
	                                                alert(
	                                                    'Error occured Please Try again',
	                                                );
	                                                this.props.history.push(
	                                                    '/app/Profile',
	                                                );
	                                                window.location.reload();
	                                            }
	                                        },
	                                    );
	                                } else {
	                                    alert('Error occured');
	                                }
	                            } else {
	                                console.log('wrong pathname');
	                                swal(
	                                    'Error occured Please Try again',
	                                    '',
	                                    'info',
	                                ).then(() => {
	                                    window.location.reload();
	                                });
	                            }
	                        } catch (e) {
	                            console.log(
	                                'Error occured on inserting success',
	                                e,
	                            );
	                        }
	                    }),
	                error: async (err) => {
	                    console.log(err);
	                    swal(
	                        'Error while adding bank account.',
	                        `${JSON.stringify(err)}`,
	                        'error',
	                    );
	                },
	            });
	        }
	    };
	    const onEntitySelectChange = ({ target }) => {
	        const { value: selectedEntityId } = target;
	        const { Entities } = this.state;

	        const {
	            type: selectedEntityType,
	            DwollaCustomerURL,
	            microdeposit_last_failed,
	        } = Entities?.find(
	            (item) => item.EntityID === parseInt(selectedEntityId),
	        );

	        console.log('this.refs?.Entity?.value', this.refs?.Entity?.value);
	        console.log('selectedEntityId', parseInt(selectedEntityId));
	        console.log(
	            'selectedDwollaCustomerId On select',
				DwollaCustomerURL?.split('customers/')[1],
	        );
	        console.log('selectsss', microdeposit_last_failed);
	        console.log(
	            'dddd',
	            moment(new Date().toISOString()).diff(
	                moment(microdeposit_last_failed?.toString()),
	                'hours',
	            ),
	        );
	        if (
	            microdeposit_last_failed
				&& moment(new Date().toISOString()).diff(
				    moment(microdeposit_last_failed?.toString()),
				    'hours',
				) <= 48
	        ) {
	            swal(
	                'Your bank account has not yet been properly verified. Please wait 48 hours and try again, or contact customer support at 713-863-3297 or info@energyfunders.com for assistance',
	                '',
	                'warning',
	            );
	        } else {
	            this.setState({
	                selectedEntityType,
	                EntityID: parseInt(selectedEntityId),
	                EntitySelectedForMd: true,
	                selectedDwollaCustomerId:
						        DwollaCustomerURL?.split('customers/')[1],
	            });

	            // openMDDwollaDropInComponent(parseInt(selectedEntityId));
	        }
	    };

	    // if (this.state.Method === 'md') {
	    //     return (

	    //     );
	    // }

	    const { steps, currentStep } = this.state;
	    const imgAddBank = '/img/add-your-bank.png';

	    return (
	        <div className="verify-identity add-bank micro">
        <Observer value={{ EntityID: this.state.EntityID, status: this.state.status, selectedEntityType: this.state.selectedEntityType }} didUpdate={openMDDwollaDropInComponent} />
	            <div
	                className="stepper-horizontal"
	                style={{
	                    backgroundColor: '#092736',
	                    paddingBottom: 20,
	                }}
    >
	                <Stepper
	                    steps={steps}
	                    activeStep={currentStep}
	                    defaultColor="#fff"
	                    activeColor="#5bb462"
	                    circleFontColor="#000"
	                    circleFontSize={14}
	                    defaultTitleColor="#fff"
	                    activeTitleColor="#5bb462"
	                    completeColor="#fff"
	                    completeTitleColor="#fff"
	                    completeBarColor="#fff"
    />
    </div>
	            <div className="vi-container-fluid position-relative">
	                <div className="linear-gradient" />
	                <div className="container">
	                    <div className="d-md-flex  align-items-center">
	                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 d-flex flex-column align-items-center justify-content-center position-relative lt-sec">
	                            <img src={imgAddBank} />
	                            <h1>Add Your Bank</h1>
    </div>
	                        <div className="col-xs-12 col-sm-12  col-md-7 col-lg-7 d-md-flex rt-sec">
	                            <div>
	                                {this.state.Method === 'md' ? (
	                                    <div
	                                        id="step-1"
	                                        className="content"
	                                        style={{ display: 'block' }}
    >
	                                        <div>
	                                            <h3 className="font-bold">
        Micro Deposit Verification
	                                            </h3>
	                                            <br />
	                                            <form name="myform">
	                                                <div className="form-group">
	                                                    <label htmlFor="ename">
        Please select the
        entity to connect to
        this bank account *
	                                                    </label>
	                                                    <br />
	                                                    <select
	                                                        className="microdeposit-form-control form-control"
	                                                        onChange={
	                                                            onEntitySelectChange
	                                                        }
	                                                        ref="Entity"
	                                                        id="entity"
    >
	                                                        <option
	                                                            value={-1}
	                                                            selected
	                                                            disabled
    >
        Please select a
        Person or Entity
    </option>
	                                                        {this.state.Entities.map(
	                                                            (Ent, i) => (
	                                                                <option
	                                                                    key={getHash(
	                                                                        Ent,
	                                                                    )}
	                                                                    value={
	                                                                        Ent.EntityID
	                                                                    }
	                                                                    style={{
	                                                                        color: Ent.IsDisabled
	                                                                            ? 'grey'
	                                                                            : 'black',
	                                                                    }}
	                                                                    disabled={
	                                                                        Ent.IsDisabled
	                                                                    }
    >
	                                                                    {
	                                                                        Ent.EntityLabel
	                                                                    }
    </option>
	                                                            ),
	                                                        )}
    </select>
    </div>
	                                                {this.state
	                                                    ?.EntitySelectedForMd && (
	                                                        <div className="text-center mb-2">
	                                                            <dwolla-funding-source-create
	                                                                customerId={
	                                                                    selectedDwollaCustomerId
	                                                                }
	                                                                initiateMicroDeposits
    />
	                                                        </div>
	                                                    )}
	                                                {/* <div className="form-group">
															<label htmlFor="gender">
																Select Account
																Type: *
															</label>
															<br />
															<select
																className="microdeposit-form-control form-control"
																ref="atype"
																id="category"
															>
																<option
																	value="Checking"
																	style={{
																		color: "black",
																	}}
																>
																	Checking
																</option>
																<option
																	value="Savings"
																	style={{
																		color: "black",
																	}}
																>
																	Savings
																</option>
															</select>
														</div> */}
	                                                {/* <div className="form-group">

															<input
																id="ename"
																name="fname"
																className="microdeposit-form-control form-control"
																type="text"
																ref="rnum"
																placeholder="Routing Number: *"
															/>
															<span
																id="error_ename"
																className="text-danger"
															/>
														</div> */}
	                                                {/* <div className="form-group"> */}
	                                                {/* <label htmlFor="eaddress">Account Number: *</label> */}

	                                                {/* <input
																id="eaddress"
																name="lname"
																className="microdeposit-form-control form-control"
																type="text"
																ref="anum"
																placeholder="Account Number: *"
															/>
															<span
																id="error_eaddress"
																className="text-danger"
															/>
														</div> */}
	                                                {/* <div className="form-group"> */}
	                                                {/* <label htmlFor="estate">Account Name: *</label> */}
	                                                {/*
															<input
																id="estate"
																name="city"
																className="microdeposit-form-control form-control"
																type="text"
																ref="name"
																placeholder="Account Name: *"
															/>
															<span
																id="error_estate"
																className="text-danger"
															/>
														</div> */}

	                                                {/* <HideComponent
															hide={
																selectedEntityType !==
																ROCKET_DOLLAR_IRA_ACCOUNT
															}
														>
															<CheckboxInputComponent
																onClick={({
																	target,
																}) =>
																	this.setState(
																		{
																			IsRocketDollarIRA:
																				target.value,
																		}
																	)
																}
																checked={
																	this.state
																		.IsRocketDollarIRA
																}
																checkTitle="This is a Rocket Dollar IRA"
																name="IsRocketDollarIRA"
															/>
														</HideComponent> */}

	                                                {/* <div className="form-group">
															<button
																id="submit"
																ref="mdSubmitButton"
																type="button"
																onClick={
																	this
																		.onSubmitMicroDeposits
																}
																className="btn semi-bold"
																style={{
																	height: 50,
																	width: 140,
																	backgroundColor:
																		"#5bb462",
																	color: "#fff",
																	marginTop: 0,
																}}
															>
																Submit
															</button>
														</div> */}
	                                                <div className="form-group text-center">
	                                                    <button
	                                                        onClick={() => {
	                                                            this.props.history.push(
	                                                                '/app/setupprofile3',
	                                                            );
	                                                        }}
	                                                        type="button"
	                                                        className="btn pl-0 box-shadow-none"
	                                                        style={{
	                                                            height: 50,
	                                                            width: 'auto',
	                                                            backgroundColor:
																	'#fff',
	                                                            color: '#182F3B9A',
	                                                            margin: 0,
	                                                        }}
    >
        Back
    </button>
    </div>
	                                                <div className="clearfix" />
    </form>
	                                            <div className="done">
	                                                {/* <a
                                                href="javascript:"
                                                onClick={this.onCancel}
                                            >
                                                Cancel
                                            </a>
                                            &nbsp;&nbsp;&nbsp; */}
	                                                <button
	                                                    className="btn btn-success"
	                                                    ref="finishButtonMD"
	                                                    id="finishButtonMD"
	                                                    onClick={this.onFinish}
	                                                    style={{
	                                                        backgroundColor:
																'#1cd9d6',
	                                                        display: 'none',
	                                                    }}
    >
        Finish
    </button>
    </div>
    </div>
    </div>
	                                ) : (
	                                    <div role="main">
	                                        <h3 className="font-bold">
        Instant Account Verification
	                                        </h3>
	                                        <br />
	                                        <div className="form-group">
	                                            <label htmlFor="ename">
        Please select the entity to
        connect this bank account *
	                                            </label>
	                                            <select
	                                                className="form-control"
	                                                ref="Entity"
	                                                id="entity"
    >
	                                                <option value={-1}>
        Please select a Person
        or Entity
	                                                </option>
	                                                {this.state.Entities.map(
	                                                    (Ent, i) => (
	                                                        <option
	                                                            key={i}
	                                                            value={
	                                                                Ent.EntityID
	                                                            }
	                                                            style={{
	                                                                color: Ent.IsDisabled
	                                                                    ? 'grey'
	                                                                    : 'black',
	                                                            }}
	                                                            disabled={
	                                                                Ent.IsDisabled
	                                                            }
    >
	                                                            {
	                                                                Ent.EntityLabel
	                                                            }
    </option>
	                                                    ),
	                                                )}
    </select>
    </div>

	                                        <div className="stepContainer" />
	                                        <div className="stepContainer">
	                                            <div
	                                                id="step-1"
	                                                className="content"
	                                                style={{
	                                                    display: 'block',
	                                                }}
    >
	                                                <span
	                                                    style={{
	                                                        'font-size': 18,
	                                                        'font-weight':
																'bold',
	                                                        color: 'black',
	                                                    }}
    >
	                                                    {/* Instructions:
                                <br/>
                                <br/> */}
	                                                    <p
	                                                        style={{
	                                                            'font-size': 14,
	                                                            color: '#ABAFB3',
	                                                            'font-weight':
																	'normal',
	                                                        }}
    >
	                                                        {' '}
	                                                        <br />
        After selecting your
        bank, you will be
        prompted to use your
        banking credentials
        to login and
        authorize an account
        to be used for ACH
        transactions.
	                                                    </p>
	                                                    <br />
	                                                    <br />
    </span>
	                                                <div
	                                                    id="mainContainer"
	                                                    className="d-md-flex text-center"
    >
	                                                    <div className="form-group ">
	                                                        <button
	                                                            className="btn semi-bold"
	                                                            type="button"
	                                                            onClick={() => {
	                                                                this.iav();
	                                                            }}
	                                                            ref="addBankButton"
	                                                            id="start"
	                                                            style={{
	                                                                height: 50,
	                                                                width: 140,
	                                                                backgroundColor:
																		'#5bb462',
	                                                                color: '#fff',
	                                                                marginTop: 0,
	                                                            }}
    >
        Add Bank
    </button>
    </div>
	                                                    <div
	                                                        className="form-group"
	                                                        style={{
	                                                            'font-size': 13,
	                                                            color: '#ABAFB3',
	                                                            'font-weight':
																	'normal',
	                                                            paddingLeft: 20,
	                                                            fontStyle:
																	'italic',
	                                                        }}
    >
	                                                        <span>
        *We do not store
        your
        credentials, as
        they are only
        used to verify
        your account
        information, and
        then discarded.
	                                                        </span>
    </div>
    </div>
	                                                <div className="form-group text-center d-md-left">
	                                                    <button
	                                                        onClick={() => {
	                                                            this.props.history.push(
	                                                                '/app/setupprofile3',
	                                                            );
	                                                        }}
	                                                        type="button"
	                                                        className="btn pl-0 box-shadow-none"
	                                                        style={{
	                                                            height: 50,
	                                                            width: 'auto',
	                                                            backgroundColor:
																	'#fff',
	                                                            color: '#182F3B9A',
	                                                            margin: 0,
	                                                        }}
    >
        Back
    </button>
    </div>
	                                                <div id="iavContainer" />
	                                                <br />
    </div>
	                                            <div className="done">
	                                                {/* <button
                                className="btn btn-primary"
                                ref="cancelButtonIAV"
                                id="cancelButtonIAV"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </button> */}
	                                                <button
	                                                    className="btn btn-success"
	                                                    ref="finishButtonIAV"
	                                                    id="finishButtonIAV"
	                                                    onClick={this.onFinish}
	                                                    style={{
	                                                        backgroundColor:
																'#1cd9d6',
	                                                        display: this.state
	                                                            .showFinishButton
	                                                            ? ''
	                                                            : 'none',
	                                                    }}
    >
        Finish
    </button>
    </div>
    </div>
    </div>
	                                )}
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
	    );
	}
}

const { object, bool } = PropTypes;

Setupprofile4.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(Setupprofile4));
