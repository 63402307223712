import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';
import { Button, Modal, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Icon from '../../shared/IconComponent/IconComponent';
import * as Actions from './actions/actions';
import RequestService from '../../../api/RequestService';
import InvestmentLogBase from './utils/InvestmentLogBase';
import moment from 'moment';

import './invest.styles.css';
import {
  formatMoney,
  formatMoneyShowZero,
  getHash,
  No_Fee_Project,
  Half_Fee_Project,
  Yield_Fund_2_ID,
  America_First_Energy_Fund_1,
  Wildcat_Project_ID,
  Bitcoin_Project_ID,
  Innovation_Fund_1,
  Opportunity_Fund_1_ID,
} from '../../../utils/General';
import HideComponent from '../../shared/HideComponent/HideComponent';

const INVESTMENT_LIMIT = 25000000;

class Invest extends InvestmentLogBase {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(window.location.search);
    this.state = {
      id: props.session.id,
      ProjectType:
        props?.location?.state?.ProjectType ?? params?.get('ProjectType'),
      ProjectID:
        props?.location?.state?.ProjectID ?? parseInt(params?.get('ProjectID')),
      Entities: [],
      EntityName: '',
      show:
        (props?.location?.state?.ProjectType ?? params?.get('ProjectType')) ===
        'RegCF'
          ? 0
          : 1,
      max: INVESTMENT_LIMIT,
      EntityID: '',
      UnitPrice: '',
      InvestmentAmount: '',
      myPreviousEnergyFundersInvestment: 0,
      CurrentFunding: 0,
      FundingGoalMax: 0,
      MinimumInvestment: 0,
      Incomes: [],
      gplp: -1,
      GP_LP_Forced: 0,
      isCashCall: props?.location?.state?.IsCashCall,
      CashCallInvestmentOpen: true,
      IsDuplicate: false,
      skipHelloSign: 0,
      isLoaded: false,
      errorOnLoad: false,
      entityAccounts: [],
      selectedEntityId: -1,
      selectedBankAccount: -1,
      reduceInvestmentDisabled: true,
      btcAddress: '',
      ethAddress: '',
      hasFee: 0,
      FeeAmount: 0,
      priorInvestments: {
        yf1InvestmentBeforeCutoff: 0,
        yf1TotalInvestment: 0,
        yf1LastInvestmentDate: '2030-12-31',
      },
      investedProjects: [],
      entityIsAccredited: false,
    };

    this.getEntity = this.getEntity.bind(this);
    this.getTotalInvestment = this.getTotalInvestment.bind(this);
    this.slider = this.slider.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getCurrentFunding = this.getCurrentFunding.bind(this);
    this.calcRegCFInvestAmount = this.calcRegCFInvestAmount.bind(this);
    this.checkInvestmentAmount = this.checkInvestmentAmount.bind(this);
    this.getEntityInfo = this.getEntityInfo.bind(this);
  }

  async componentDidMount() {
    const { ProjectType, ProjectID, id: userId } = this.state;

    window.scrollTo(0, 0);
    this.getCurrentFunding();
    this.getEntity();
    this.getTotalInvestment();
    this.getuserInvestedProjects();
    await this.init();
    await this.setProjectIDs(ProjectID, ProjectType);
    await this.setLogData({
      userId,
      stepNumber: 1,
    });
  }

  async getuserInvestedProjects() {
    try {
      const { data } = await RequestService.get('/api/currentUserInvestedList');
      console.log('user investedProjects res', data);
      console.log('user invested projects', data);
      this.setState({ investedProjects: data });
    } catch (err) {
      console.log(err);
    }
  }

  async getEntity() {
    try {
      const { data } = await RequestService.get('/api/getVerifiedEntities');

      this.setState({
        Entities: data.rows,
      });

      let element = document.getElementById('more');
      element.hide();
    } catch (err) {
      console.log(err);
    }
  }

  async getTotalInvestment() {
    try {
      const { data } = await RequestService.get('/api/getTotalInvestment');
      // const priorInv = { yf1InvestmentBeforeCutoff: 0, yf1TotalInvestment: 0, yf1LastInvestmentDate: "2030-12-31" }
      let dataFound = false;
      console.log('Prior investments', data);
      if (data?.data?.length > 0) dataFound = true;
      // if (data.data.yf1InvestmentBeforeCutoff && parseFloat(data.data.yf1InvestmentBeforeCutoff) > 0) {
      //     priorInv.yf1InvestmentBeforeCutoff = parseFloat(data.data.yf1InvestmentBeforeCutoff);
      //     dataFound = true;
      // }
      // if (data.data.yf1TotalInvestment && parseFloat(data.data.yf1TotalInvestment) > 0) {
      //     priorInv.yf1TotalInvestment = parseFloat(data.data.yf1TotalInvestment);
      //     //  priorInv.yf1TotalInvestment = 0;
      //     priorInv.yf1LastInvestmentDate = data.data.yf1LastInvestmentDate
      //     dataFound = true;
      // }

      if (dataFound)
        this.setState({
          priorInvestments: data.data,
        });
      else
        this.setState({
          priorInvestments: [],
        });
    } catch (err) {
      console.log(err);
    }
  }

  async getEntityInfo({ target }) {
    // These commented lines below are a "hack" to allow the Personal Entity Bank accounts to be used for other non-verified Entities.
    // const personalEntity = this.state.Entities.filter(item => item.EntityType === 'Personal')[0];
    // let entityId = personalEntity.EntityID

    let entityId = target.value;

    try {
      const { ProjectType: projectType, ProjectID: projectId } = this.state;
      const { data } = await RequestService.post({
        url: `/api/getInvestorFundingSourceData`,
        data: {
          entityId,
          projectId,
          projectType,
        },
      });
      const entityAccounts = data || data.length;
      this.setState({
        entityAccounts: entityAccounts?.filter(
          (item) => item.bankAccountType !== 'Receive only'
        ),
        selectedEntityId: entityId,
      });
      const { data: entity } = await RequestService.get(
        `/api/checkEntityIsAccredited/${entityId}`
      );
      console.log('entityIsAccredited', entity);
      this.setState({
        entityIsAccredited: entity?.verified,
      });
    } catch (e) {
      this.setState({
        entityAccounts: [],
      });
    }

    if (parseInt(target.value) !== -1) {
      const obj = this.state.Entities.find(
        (x) => x.EntityID === parseInt(target.value)
      );

      if (obj.default_gp_lp_election === 1) {
        this.setState({ gplp: 1 });
      } else {
        this.setState({ gplp: 2 });
      }
    } else {
      this.setState({ gplp: 2 });
    }

    // Reload CurrentFunding on Entity selection if it is a cash call, to determine the minimum investment amount for that entity.
    if (this.state.isCashCall) {
      this.getCurrentFunding();
    }
  }

  checkInvestmentAmount() {
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };
    console.log('matching priorInv', priorInv);
    this.refs.range.value = this.refs.range.value.replace(/\D/g, '').trim();
    console.log(
      'this.refs.range.value + priorInv.TotalInvestment',
      Number(this.refs.range.value) + priorInv.TotalInvestment
    );
    if (
      Number(this.refs.range.value) + priorInv.TotalInvestment <
      this.state.MinimumInvestment
    ) {
      swal('Amount Less Than Minimum Investment.', '', 'info').then(
        (this.refs.range.value =
          this.state.MinimumInvestment - priorInv.TotalInvestment)
      );
    } else if (this.refs.range.value > this.state.max) {
      swal('Amount Greater Than Maximum Investment Allowed.', '', 'info').then(
        (this.refs.range.value = this.state.max)
      );
    } else {
    }
  }

  async getCurrentFunding() {
    if (this.state.ProjectType === 'RegCF') {
      //get current funding
      fetch(
        '/api/getonecurrentfundingCF/' +
          this.state.ProjectID +
          '/' +
          this.state.id +
          '/' +
          this.props.session.marketing,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          // console.log(JSON.stringify(response));
          return response.json();
        })
        .then((data) => {
          // console.log(data)
          let CurrentFunding = Math.round(data[0].CurrentFunding);
          this.setState({
            CurrentFunding: CurrentFunding,
            isLoaded: true,
            err: {},
            errorOnLoad: false,
          });
        })
        .catch((err) => {
          this.setState({
            err: err,
            errorOnLoad: true,
          });
        });
      fetch(
        '/api/getlastyearinvestments/' +
          this.state.id +
          '/' +
          this.props.session.marketing,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.setState({
            myPreviousEnergyFundersInvestment: data[0].myOneYearInvestmentTotal,
          });
        });
      fetch(
        '/api/getprojectinvestmentCF/' +
          this.state.ProjectID +
          '/' +
          this.state.id +
          '/' +
          this.props.session.marketing,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          // console.log(JSON.stringify(response));
          return response.json();
        })
        .then((data) => {
          // console.log(data)
          this.setState({
            UnitPrice: data[0].IncrementPrice,
            FundingGoalMax: data[0].FundingGoalMax,
            MinimumInvestment: data[0].MinimumInvestment,
            isLoaded: true,
            err: {},
            errorOnLoad: false,
          });
          this.refs.range.value = this.state.MinimumInvestment;
          this.refs.range.value = this.state.MinimumInvestment;
        })
        .catch((err) => {
          this.setState({
            err: err,
            errorOnLoad: true,
          });
        });
    } else {
      let { data: pcf } = await RequestService.get(
        `/api/getProjectCurrentFunding/${this.state.ProjectID}`
      );

      if (pcf) {
        let CurrentFunding = Math.round(pcf[0].CurrentFunding);
        this.setState({
          CurrentFunding: CurrentFunding,
        });
      }

      fetch(
        '/api/getprojectinvestment/' +
          this.state.ProjectID +
          '/' +
          this.state.id +
          '/' +
          this.props.session.marketing,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          // console.log(JSON.stringify(response));
          return response.json();
        })
        .then(async (data) => {
          if (data[0].IsCashCall === 1 && this.state.selectedEntityId > 0) {
            let { data: data1 } = await RequestService.get(
              `/api/getParentInvestment/${this.state.ProjectID}/${data[0].ParentProjectID}/${this.state.selectedEntityId}`
            );

            if (data1) {
              if (!data1.CashCallInvestmentOpen) {
                if (data1.IsDuplicate) {
                  swal(
                    'You already invested.',
                    'An investment was already made with this entity for this cash call.',
                    'error'
                  ).then(
                    this.props.history.push({ pathname: '/app/Allinvestments' })
                  );
                } else if (data1.ParentInvestmentInfo[0].GpLp === 1) {
                  swal(
                    'Investment not allowed at this time.',
                    'Cash Call is currently available only to LP Investors.',
                    'error'
                  );
                } else if (data1.ParentInvestmentInfo[0].GpLp === 2) {
                  swal(
                    'Investment not allowed at this time.',
                    'Cash Call is currently available only to GP Investors.',
                    'error'
                  );
                } else {
                  swal(
                    'Please select another Entity.',
                    'Or please contact support for additional help.',
                    'error'
                  );
                }
              }

              let maxInvestment = data[0].CashCallOpenToAll
                ? Math.min(
                    data[0].FundingGoalMax - this.state.CurrentFunding,
                    INVESTMENT_LIMIT
                  )
                : Math.min(
                    data[0].FundingGoalMax - this.state.CurrentFunding,
                    data1.CashCallInvestAmount,
                    INVESTMENT_LIMIT
                  );

              this.setState({
                UnitPrice: data[0].IncrementPrice,
                FundingGoalMax: data[0].FundingGoalMax,
                MinimumInvestment: data1.CashCallInvestAmount,
                max: maxInvestment,
                gplp: data1.ParentInvestmentInfo[0].GpLp,
                isCashCall: true,
                CashCallInvestmentOpen: data1.CashCallInvestmentOpen,
                IsDuplicate: data1.IsDuplicate,
                skipHelloSign: data[0].SkipHelloSign,
                btcAddress: data[0].BTCAddress,
                ethAddress: data[0].ETHAddress,
                hasFee: data[0].HasFee,
              });

              this.refs.range.value = this.state.MinimumInvestment;
              this.refs.range.value = this.state.MinimumInvestment;

              this.refs.minInvText.innerHTML = `The minimum investment is $${this.state.MinimumInvestment.toLocaleString(
                'en-us'
              )} with increasing increments of $${this.state.UnitPrice}.`;
            }
          } else {
            // console.log(data)
            this.setState({
              UnitPrice: data[0].IncrementPrice,
              FundingGoalMax: data[0].FundingGoalMax,
              MinimumInvestment: data[0].MinInvestment,
              max: Math.min(
                data[0].FundingGoalMax - this.state.CurrentFunding,
                INVESTMENT_LIMIT
              ),
              GP_LP_Forced: data[0].GP_LP_Forced,
              gplp: data[0].GP_LP_Forced,
              skipHelloSign: data[0].SkipHelloSign,
              btcAddress: data[0].BTCAddress,
              ethAddress: data[0].ETHAddress,
              hasFee: data[0].HasFee,
              InvestmentAmount: data[0].MinInvestment,
              FeeAmount: this.setInitialFeeAmount(
                data[0].HasFee,
                data[0].MinInvestment
              ),
            });

            this.refs.range.value = this.state.MinimumInvestment;
          }
        })
        .catch((err) => {
          this.setState({
            err: err,
            errorOnLoad: true,
          });
        });
    }
  }

  setInitialFeeAmount(HasFee, MinInvestment) {
    let feeAmount;
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };

    let priorInvestments = Number(priorInv.TotalInvestment);
    // console.log("priorinvestments",priorInvestments);
    // console.log("this.state?.InvestmentAmount",this.state?.InvestmentAmount);
    // console.log("MinInvestment",MinInvestment);
    let daysLeft = moment('2022-10-31').diff(moment(new Date()), 'days');
    if (HasFee && this.state?.ProjectID == Wildcat_Project_ID) {
      feeAmount = MinInvestment * 0.02;
    } else if (
      HasFee &&
      (this.state?.ProjectID == Yield_Fund_2_ID ||
        this.state?.ProjectID == America_First_Energy_Fund_1)
    ) {
      if (daysLeft > 0 && this.state.investedProjects?.length > 0) {
        console.log('setting 0 by daysleft and already invested');
        feeAmount = 0;
      } else {
        feeAmount =
          MinInvestment + priorInvestments < 100000
            ? MinInvestment * 0.05
            : MinInvestment + priorInvestments >= 100000 &&
              MinInvestment + priorInvestments <= 249999
            ? MinInvestment * 0.0325
            : MinInvestment + priorInvestments >= 250000 &&
              MinInvestment + priorInvestments <= 499999
            ? MinInvestment * 0.025
            : MinInvestment + priorInvestments >= 500000 &&
              MinInvestment + priorInvestments >= 999999
            ? MinInvestment * 0.0175
            : MinInvestment + priorInvestments >= 1000000
            ? MinInvestment * 0.01
            : 0;
      }
    } else if (HasFee) {
      // if(this.state.investedProjects?.length > 0) {
      //     feeAmount = 0;
      // } else {
      feeAmount =
        this.state?.InvestmentAmount + priorInvestments < 100000
          ? MinInvestment * 0.03
          : this.state?.InvestmentAmount + priorInvestments >= 100000 &&
            this.state?.InvestmentAmount + priorInvestments <= 249999
          ? MinInvestment * 0.025
          : this.state?.InvestmentAmount + priorInvestments >= 250000 &&
            this.state?.InvestmentAmount + priorInvestments <= 499999
          ? MinInvestment * 0.0175
          : this.state?.InvestmentAmount + priorInvestments >= 500000
          ? MinInvestment * 0.01
          : 0;
      // }
    }
    if (
      (HasFee && Number(this.state?.ProjectID) === No_Fee_Project) ||
      Number(this.state?.ProjectID) === Opportunity_Fund_1_ID
    ) {
      feeAmount = 0;
    } else if (HasFee && Number(this.state?.ProjectID) === Half_Fee_Project) {
      feeAmount = feeAmount / 2;
    }
    return feeAmount;
  }

  async onNext() {
    const selectedEntity = parseInt(this.state.selectedEntityId);
    const bankAccount = parseInt(this.state.selectedBankAccount);
    this.refs.range.value = this.refs.range.value.replace(/\D/g, '').trim();
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };
    console.log(
      'this.refs.range.value + priorInv.TotalInvestment',
      Number(this.refs.range.value) + priorInv.TotalInvestment
    );

    if (
      this.state.ProjectType === 'RegCF' &&
      (this.refs.nRegCFAnnualIncome.value === '' ||
        this.refs.nRegCFNetWorth.value === '' ||
        this.refs.regcf_preinvested.value === '')
    ) {
      swal('Please enter all data to continue.', '', 'error');
    }
    // else if (!document.getElementById('terms1').checked || !document.getElementById('terms2').checked || !document.getElementById('terms3').checked) {
    //     swal('Please Accept the Terms and Conditions to Continue.', '', 'error');
    // }
    else if (
      this.state.ProjectType === 'RegCF' &&
      (!document.getElementById('terms4').checked ||
        !document.getElementById('terms5').checked ||
        !document.getElementById('terms6').checked)
    ) {
      swal('Please Accept the Terms and Conditions to Continue.', '', 'error');
    } else if (selectedEntity === -1) {
      swal('Please Select Your Entity.', '', 'error');
    } else if (bankAccount === -1) {
      swal('Please Select Your Bank Account.', '', 'error');
    } else if (
      Number(this.refs.range.value) + priorInv.TotalInvestment <
      this.state.MinimumInvestment
    ) {
      swal('Amount Less Than Minimum Investment.', '', 'info').then(
        (this.refs.range.value = this.state.MinimumInvestment)
      );
    } else if (this.refs.range.value > this.state.max) {
      swal('Amount Greater Than Maximum Investment Allowed.', '', 'info').then(
        (this.refs.range.value = this.state.MinimumInvestment)
      );
    } else if (
      (this.state.ProjectID == Yield_Fund_2_ID ||
        this.state.ProjectID == America_First_Energy_Fund_1) &&
      !(Number(this.refs.classA.value) + Number(this.refs.classB.value) == 100)
    ) {
      swal(
        this.state.ProjectID == Opportunity_Fund_1_ID
          ? 'Class A LP & Class A GP should add upto 100%'
          : 'Class A & Class B should add upto 100%',
        '',
        'info'
      );
      // .then(this.refs.range.value = this.state.MinimumInvestment);
    } else if (!this.state.entityIsAccredited) {
      swal("You're not accredited", '', 'error'); //change the wording to whatever fits
    } else if (
      Number(this.refs.range.value) === 0 ||
      !this.state.CashCallInvestmentOpen
    ) {
      if (this.state.IsDuplicate) {
        swal(
          'You already invested.',
          'An investment was already made with this entity for this cash call.',
          'error'
        );
      }
      if (this.state.gplp === 1) {
        swal(
          'Investment not allowed at this time.',
          'Cash Call is currently available only to LP Investors.',
          'error'
        );
      } else if (this.state.gplp === 2) {
        swal(
          'Investment not allowed at this time.',
          'Cash Call is currently available only to GP Investors.',
          'error'
        );
      } else {
        swal(
          'Please select another Entity.',
          'Or please contact support for additional help.',
          'error'
        );
      }

      this.props.history.push({ pathname: '/app/Allinvestments' });
    } else {
      if (this.state.ProjectType === 'RegCF') {
        if (
          this.refs.nRegCFAnnualIncome.value !== '' &&
          this.refs.nRegCFNetWorth.value !== '' &&
          this.refs.regcf_preinvested.value !== '' &&
          this.refs.range.value !== '' &&
          this.refs.entity.value > 0
        ) {
          document
            .getElementById('invest1next')
            .setAttribute('disabled', 'disabled');
          console.log('invest.js state if 1', this.state);
          const redirectObj = {
            pathname: '/app/invest2',
            state: {
              ProjectType: this.state.ProjectType,
              ProjectID: this.state.ProjectID,
              EntityID: this.refs.entity.value,
              InvestmentAmount: this.refs.range.value.replace(/\D/g, '').trim(),
              Incomes: [
                this.refs.nRegCFAnnualIncome.value,
                this.refs.nRegCFNetWorth.value,
                this.refs.regcf_preinvested.value,
                this.state.myPreviousEnergyFundersInvestment,
              ],
              GpLp: 2,
              bankAccount,
              priorInvestments: this.state.priorInvestments,
              investedProjectsCount: this.state.investedProjects?.length,
              classAPercent:
                this.state.ProjectID == Opportunity_Fund_1_ID
                  ? 100
                  : Number(this.refs?.classA?.value),
              classBPercent:
                this.state.ProjectID == Opportunity_Fund_1_ID
                  ? 0
                  : Number(this.refs?.classB?.value),
            },
          };
          const stepData = JSON.stringify({ ...redirectObj.state });

          await this.setLogData({
            stepData,
            eventName: 'next',
          });
          this.performStepLog();
          this.props.history.push(redirectObj);
        } else {
          // console.log(this.refs,'refs');
          swal('Please select all fields', '', 'error');
        }
      } else {
        if (this.refs.range.value !== '' && this.refs.entity.value > 0) {
          document
            .getElementById('invest1next')
            .setAttribute('disabled', 'disabled');
          console.log('invest.js state if 2', this.state);
          const redirectObj = {
            pathname: '/app/invest2',
            state: {
              ProjectType: this.state.ProjectType,
              ProjectID: this.state.ProjectID,
              EntityID: this.refs.entity.value,
              InvestmentAmount: this.refs.range.value.replace(/\D/g, '').trim(),
              Incomes: [],
              // GpLp: (this.state.gplp > 0 ? this.state.gplp : 2),
              GpLp:
                this.state.GP_LP_Forced > 0
                  ? this.state.GP_LP_Forced
                  : this.state.gplp > 0
                  ? this.state.gplp
                  : 2,
              bankAccount,
              skipHelloSign: this.state.skipHelloSign,
              btcAddress: this.state.btcAddress,
              ethAddress: this.state.ethAddress,
              taxBasis:
                this.refs.taxBasis.value > 0 ? this.refs.taxBasis.value : 0,
              hasFee: this.state.hasFee,
              investedProjectsCount: this.state.investedProjects?.length,
              priorInvestments: this.state.priorInvestments,
              classAPercent:
                this.state.ProjectID == Opportunity_Fund_1_ID
                  ? 100
                  : Number(this.refs?.classA?.value),
              classBPercent:
                this.state.ProjectID == Opportunity_Fund_1_ID
                  ? 0
                  : Number(this.refs?.classB?.value),
            },
          };

          const stepData = JSON.stringify({ ...redirectObj.state });

          await this.setLogData({
            stepData,
            eventName: 'next',
          });
          this.performStepLog();
          this.props.history.push(redirectObj);
        } else {
          swal('Please select all fields', '', 'error');
        }
      }
    }
  }

  onCancelInvestment = async () => {
    if (this.state.ProjectType === 'RegCF') {
      const stepData = {
        ProjectType: this.state.ProjectType,
        ProjectID: this.state.ProjectID,
        EntityID: this.refs.entity.value,
        InvestmentAmount: this.refs.range.value.replace(/\D/g, '').trim(),
        Incomes: [
          this.refs.nRegCFAnnualIncome.value,
          this.refs.nRegCFNetWorth.value,
          this.refs.regcf_preinvested.value,
          this.state.myPreviousEnergyFundersInvestment,
        ],
        GpLp: 2,
      };

      await this.setLogData({
        stepData: JSON.stringify(stepData),
        eventName: 'cancel investment',
      });

      this.performStepLog();
      this.props.history.push('/app/UserNotifications');
    } else {
      const stepData = {
        ProjectType: this.state.ProjectType,
        ProjectID: this.state.ProjectID,
        EntityID: this.refs.entity.value,
        InvestmentAmount: this.refs.range.value.replace(/\D/g, '').trim(),
        Incomes: [],
        GpLp: this.state.gplp,
      };

      await this.setLogData({
        stepData: JSON.stringify(stepData),
        eventName: 'cancel investment',
      });

      this.performStepLog();
      this.props.history.push('/app/UserNotifications');
    }
  };

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  calcRegCFInvestAmount() {
    this.refs.nRegCFAnnualIncome.value = this.refs.nRegCFAnnualIncome.value
      .replace(/\D/g, '')
      .trim();
    this.refs.nRegCFNetWorth.value = this.refs.nRegCFNetWorth.value
      .replace(/\D/g, '')
      .trim();
    this.refs.regcf_preinvested.value = this.refs.regcf_preinvested.value
      .replace(/\D/g, '')
      .trim();

    let minimumSetInvestment = this.state.MinimumInvestment;
    let maxAllowed;
    let regcf_annual_income = this.refs.nRegCFAnnualIncome.value; // Remove commas and spaces.
    let regcf_networth = this.refs.nRegCFNetWorth.value;
    let regcf_preinvested = this.refs.regcf_preinvested.value;
    let regcf_ef_preinvested = this.state.myPreviousEnergyFundersInvestment;

    // alert(regcf_annual_income + ' - ' + regcf_networth + ' - ' + regcf_preinvested);

    if (
      regcf_annual_income > 0 &&
      regcf_networth > 0 &&
      regcf_preinvested >= 0 &&
      regcf_preinvested !== ''
    ) {
      if (regcf_annual_income >= 107000 && regcf_networth >= 107000) {
        // Both must be >= 107000 to get 10% rule.
        maxAllowed = Math.min(regcf_annual_income, regcf_networth) * 0.1;
      } // Either of annual income OR net worth are less than 107000. Get 5% rule.
      else {
        maxAllowed = Math.min(regcf_annual_income, regcf_networth) * 0.05;
      }

      maxAllowed = Math.max(maxAllowed, 2200); // If less than 2200, the least allowed to invest is $2200.  If you can invest more, great!  It takes the larger number.
      maxAllowed = Math.min(maxAllowed, 107000); // Max allowed total is 107000.
      maxAllowed = Math.max(
        maxAllowed - regcf_ef_preinvested - regcf_preinvested,
        0
      ); // Cut your max allowed by the amount you've invested before, without showing negative number.
      maxAllowed = Math.min(
        maxAllowed,
        this.state.FundingGoalMax - this.state.CurrentFunding
      ); // Max allowed total is the max of the project.

      if (maxAllowed < minimumSetInvestment) {
        maxAllowed = 0;
      }

      if (maxAllowed === 0) {
        this.setState({ show: 0 });

        swal(
          'We regret to tell you that you are unable to invest in this project as your investment will exceed the threshold set for Regulation CF investing.',
          'Please feel free to contact us if you have any questions.',
          'info'
        ).then(() => {
          this.props.history.push('/app/Allinvestments');
        });
      } else if (maxAllowed > 0) {
        maxAllowed = Math.floor(maxAllowed / 100) * 100;

        this.setState({
          show: 1,
        });
      }
    } else {
      // $('#recalcButton').show();
      // $('#gen_error_regCF_calc').html('Please fill in all inputs above.').show();
    }

    this.setState({
      max: maxAllowed,
    });

    // console.log(maxAllowed);
  }

  calculateFee = () => {
    let FeeAmount = 0;
    let InvestmentAmount = Number(
      this.refs.range.value || this.state.InvestmentAmount
    );
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };

    let priorInvestments = Number(priorInv.TotalInvestment);

    let daysLeft = moment('2022-10-31').diff(moment(new Date()), 'days');
    console.log('daysleftt', daysLeft);
    console.log(
      'priorInvestments',
      this.state.ProjectID,
      '-----hf',
      this.state.hasFee
    );
    if (this.state.hasFee) {
      //    if(this.state.priorInvestments.yf1InvestmentBeforeCutoff > 0) {
      //         FeeAmount = 0;
      //     }
      if (this.state?.ProjectID == Wildcat_Project_ID) {
        FeeAmount = InvestmentAmount * 0.02;
      } else if (
        this.state?.ProjectID == Yield_Fund_2_ID ||
        this.state.ProjectID == America_First_Energy_Fund_1
      ) {
        if (daysLeft >= 0 && this.state.investedProjects?.length > 0) {
          console.log(
            'setting fee as 0 by daysleft and already invested in projects'
          );
          FeeAmount = 0;
        } else {
          if (InvestmentAmount + priorInvestments < 100000) {
            FeeAmount = InvestmentAmount * 0.05;
          } else if (
            InvestmentAmount + priorInvestments >= 100000 &&
            InvestmentAmount + priorInvestments <= 249999
          ) {
            FeeAmount = InvestmentAmount * 0.0325;
          } else if (
            InvestmentAmount + priorInvestments >= 250000 &&
            InvestmentAmount + priorInvestments <= 499999
          ) {
            FeeAmount = InvestmentAmount * 0.025;
          } else if (
            InvestmentAmount + priorInvestments >= 500000 &&
            InvestmentAmount + priorInvestments <= 999999
          ) {
            FeeAmount = InvestmentAmount * 0.0175;
          } else if (InvestmentAmount + priorInvestments >= 1000000) {
            FeeAmount = InvestmentAmount * 0.01;
          }
        }
      } else {
        if (this.state.investedProjects?.length > 0) {
          FeeAmount = 0;
        } else if (InvestmentAmount < 100000) {
          FeeAmount = InvestmentAmount * 0.03;
        } else if (
          InvestmentAmount + priorInvestments >= 100000 &&
          InvestmentAmount + priorInvestments <= 249999
        ) {
          FeeAmount = InvestmentAmount * 0.025;
        } else if (
          InvestmentAmount + priorInvestments >= 250000 &&
          InvestmentAmount + priorInvestments <= 499999
        ) {
          FeeAmount = InvestmentAmount * 0.0175;
        } else if (InvestmentAmount + priorInvestments >= 500000) {
          FeeAmount = InvestmentAmount * 0.01;
        }
      }
      if (
        Number(this.state?.ProjectID) === No_Fee_Project ||
        Number(this.state?.ProjectID) === Opportunity_Fund_1_ID
      ) {
        FeeAmount = 0;
      } else if (Number(this.state?.ProjectID) === Half_Fee_Project) {
        FeeAmount = FeeAmount / 2;
      }
    }
    console.log('Fee--Amount', FeeAmount);
    return FeeAmount;
  };

  slider() {
    var reg = /^[0-9]*(\.(?=[0-9]+))*[0-9]+$/;

    this.refs.range.value = this.refs.range.value;

    if (reg.test(this.refs.range.value)) {
      let FeeAmount = this.calculateFee();
      // Set this so that the decrement button activates.
      this.setState({
        reduceInvestmentDisabled: false,
        FeeAmount: FeeAmount,
      });

      this.forceUpdate();
    } else {
      this.refs.range.value = '';
    }
  }

  reduceInvestment = () => {
    const currentValue = (this.refs.range || {}).value;
    const { UnitPrice } = this.state;
    const result = currentValue - UnitPrice;

    // this.refs.range.value = result;
    this.refs.range.value = result;

    let FeeAmount = this.calculateFee();
    this.setState({ InvestmentAmount: result, FeeAmount: FeeAmount });
    this.forceUpdate();
  };

  increaseInvestment = () => {
    const currentValue = (this.refs.range || {}).value;
    const { UnitPrice } = this.state;
    const result = parseInt(currentValue) + UnitPrice;

    // this.refs.range.value = result;
    this.refs.range.value = result;

    let FeeAmount = this.calculateFee();

    // Set this so that the decrement button activates.
    this.setState({
      reduceInvestmentDisabled: false,
      InvestmentAmount: result,
      FeeAmount: FeeAmount,
    });

    this.forceUpdate();
  };

  disableIncrementInvestment = () => {
    const { UnitPrice, max } = this.state;
    const fundingLeft = this.getFundingLeft();
    const currentValue = parseFloat((this.refs.range || {}).value);
    const invGreaterThanMax = currentValue + UnitPrice > max;
    const invGreaterThanFundingLeft = currentValue + UnitPrice > fundingLeft;

    return invGreaterThanFundingLeft || invGreaterThanMax;
  };

  disableReduceInvestment = () => {
    const currentValue = parseFloat((this.refs.range || {}).value);
    const { MinimumInvestment, UnitPrice, reduceInvestmentDisabled } =
      this.state;

    return (
      reduceInvestmentDisabled || currentValue - UnitPrice < MinimumInvestment
    );
  };

  getFundingLeft = () => {
    return this.state.FundingGoalMax - this.state.CurrentFunding;
  };

  onSelectChange = ({ target }) => {
    const { value } = target;

    this.setState({
      selectedBankAccount: value,
    });

    if (value === '-10' || value === '-20') {
      document.getElementById('taxBasisDiv').style.display = 'block';
    } else {
      document.getElementById('taxBasisDiv').style.display = 'none';
    }
  };

  renderRegCFProject = () => {
    if (this.state.ProjectType === 'RegCF') {
      return (
        <div>
          {/* <div className="check_tc">
						<label className="custom-container">&nbsp; <span style={{ color: 'red' }}>*</span> I have
							read the investor education section and understand that
							startups are risky. I can afford to lose my entire
							investment.
							<input type="checkbox" required ref="terms1" name="terms1" id="terms1" />
							<span className="checkmark" />
						</label>
					</div>
					<div className="check_tc">
						<label className="text-size-15px">
							<input type="checkbox" required name="terms2"
								   id="terms2"/>
							&nbsp; <span style={{ color: 'red' }}>*</span> I
							understand these investments are not easily re-sold and
							it may take years before I know if I may see a return,
							if any.
						</label>
					</div>
					<div className="check_tc">
						<label className="text-size-15px">
							<input type="checkbox" required name="terms3"
								   id="terms3"/>
							&nbsp; <span style={{ color: 'red' }}>*</span> I
							understand that EnergyFunders and EF Funding Portal, LLC
							does not offer investment advice and that I have done my
							own due diligence on this investment. I am knowledgable
							enough to make my own investment decisions.
						</label>
					</div>
					 */}
          <div className="check_tc">
            <label className="text-size-15px">
              <input type="checkbox" required name="terms4" id="terms4" />
              &nbsp; <span style={{ color: 'red' }}>*</span> I understand the
              investment limits for Regulation CF. I am complying with my annual
              investment limit.
            </label>
          </div>
          <div className="check_tc">
            <label className="text-size-15px">
              <input type="checkbox" required name="terms5" id="terms5" />
              &nbsp; <span style={{ color: 'red' }}>*</span> I agree to sign the
              contract with my electronic signature and understand that my
              investment may be cancelled if funds are not received within 5-7
              business days.
            </label>
          </div>
          <div className="check_tc">
            <label className="text-size-15px">
              <input type="checkbox" required name="terms6" id="terms6" />
              &nbsp; <span style={{ color: 'red' }}>*</span> I understand that
              in the event of a material change that is made to the offering I
              will be required to reconfirm this investment or it will be
              cancelled and funds returned. I also understand that I can only
              cancel my investment up to 48 hours before the round closes.
            </label>
          </div>
        </div>
      );
    }
  };

  renderYieldFund2Percentages() {
    return (
      <>
        <div id="bottomHalf" className="col-md-12">
          {/* {console.log("fee amount", this.state.FeeAmount)} */}
          <div id="slidecontainer">
            <label htmlFor="classA" className="text-size-15px">
              <span style={{ color: 'red' }}>*</span>
              &nbsp;{' '}
              {this.state.ProjectID != Opportunity_Fund_1_ID
                ? 'Class A:'
                : 'Class A LP Units:'}
            </label>
            {/* <div style={{ padding: '0 0 10px 10px' }}>Please use the slider or + and -
                                                buttons below to change the amount.
                                            </div> */}
            <div>
              {' '}
              <p>
                Shareholders will not be personally liable for any of the Fund’s
                debts; potential tax deductions only against passive income
              </p>{' '}
            </div>
            <div className="input-group" style={{ width: '100px' }}>
              <input
                // onChange={this.slider}
                type="text"
                min={0}
                max={100}
                //    min={this.state.MinimumInvestment} max={this.state.max}
                //    step={this.state.UnitPrice}
                defaultValue={100}
                ref="classA"
                className="slider"
                //    id="myRange"

                id="classA"
              />
              <span className="input-group-addon">%</span>
            </div>
          </div>
          <div id="slidecontainer">
            <label htmlFor="classB" className="text-size-15px">
              <span style={{ color: 'red' }}>*</span>
              &nbsp;{' '}
              {this.state.ProjectID != Opportunity_Fund_1_ID
                ? 'Class B:'
                : 'Class A GP Units:'}
            </label>
            {/* <div style={{ padding: '0 0 10px 10px' }}>Please use the slider or + and -
                                                buttons below to change the amount.
                                            </div> */}
            <div>
              {' '}
              <p>
                Shareholders are liable for their proportionate share of the
                fund’s debts and liabilities; potential tax deductions against
                active income
              </p>{' '}
            </div>
            <div className="input-group" style={{ width: '100px' }}>
              <input
                // onChange={this.slider}
                type="text"
                min={0}
                max={100}
                //    min={this.state.MinimumInvestment} max={this.state.max}
                //    step={this.state.UnitPrice}
                defaultValue={0}
                ref="classB"
                className="slider"
                //    id="myRange"

                id="classB"
              />
              <span className="input-group-addon">%</span>
            </div>
          </div>
        </div>
        {console.log(
          'class a',
          this.refs?.classA?.value,
          'class b',
          this.refs?.classB?.value
        )}
      </>
    );
  }

  renderRegDProject = () => {
    const GpButton = (
      <Panel.Toggle>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ showGPModal: true })}
          type="button"
        >
          Set as General Partner
        </button>
      </Panel.Toggle>
    );
    const LpButton = (
      <Panel.Toggle>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ showLPModal: true })}
          type="button"
        >
          Set as Limited Partner
        </button>
      </Panel.Toggle>
    );

    if (this.state.ProjectType === 'RegD') {
      return (
        <div
          className="margin-bottom-10 margin-top-20"
          style={{
            marginTop: 30,
            marginBottom: 0,
          }}
        >
          <br />
          <HideComponent
            hide={
              this.state.isCashCall ||
              this.state.GP_LP_Forced > 0 ||
              this.state.ProjectID == Opportunity_Fund_1_ID
            }
          >
            <label htmlFor="pox-gl-lp-id" className="text-size-15px">
              <span className="red-text">*</span>
              &nbsp;Investor Type:
            </label>
            <Panel id="pox-gl-lp-id">
              <Panel.Heading>
                <Panel.Title toggle>
                  <strong>
                    <span id="default_gp_lp_election_span text-size-20px">
                      You will be investing as a{' '}
                      {this.state.gplp === 1 ? (
                        <span>General Partner</span>
                      ) : (
                        <span>Limited Partner</span>
                      )}
                      .
                    </span>
                  </strong>
                  <Panel.Toggle className="pull-right">
                    <Icon iconName="chevron-down" />
                  </Panel.Toggle>
                </Panel.Title>
                <Panel.Collapse>
                  <Panel.Body>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {GpButton}
                      {LpButton}
                    </div>
                    <p
                      style={{
                        textAlign: 'center',
                        margin: 0,
                      }}
                    >
                      You can change your default election in your{' '}
                      <a href={'/app/Profile'}>user profile</a>.
                    </p>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel.Heading>
            </Panel>
            <br />
          </HideComponent>

          {/* <div className="check_tc" style={{ 'clear': 'both' }}>
                        <label className="custom-container text-size-15px"><span style={{ color: 'red' }}>*</span> I
							understand that the entire amount of my investment may
							be lost, and I am in a financial condition to bear the
							loss of this investment.
                            <input type="checkbox" required ref="terms1" name="terms1" id="terms1" />
							<span className="checkmark" />
                        </label>
                    </div>
                    <div className="check_tc">
                        <label className="custom-container text-size-15px"><span style={{ color: 'red' }}>*</span> I
							understand it may be difficult or impossible for me to liquidate the
							securities that I am acquiring.
                            <input type="checkbox" required ref="terms2" name="terms2" id="terms2" />
							<span className="checkmark" />
                        </label>
                    </div>
                    <div className="check_tc">
                        <label className="custom-container text-size-15px"><span style={{ color: 'red' }}>*</span> I
							understand that there are restrictions on my ability to
							cancel an investment commitment and obtain a return of
							my investment.
                            <input type="checkbox" required ref="terms3" name="terms3" id="terms3" />
							<span className="checkmark" />
                        </label>
                    </div>
                 */}
        </div>
      );
    }
  };

  onSelectGP = () => {
    const check = document.getElementById('confirm_GP').checked;

    if (check) {
      this.setState({
        gplp: 1,
        showGPModal: false,
      });
    } else {
      swal('Please certify your understanding.', '', 'error');
    }
  };

  onSelectLP = () => {
    const check = document.getElementById('confirm_LP').checked;

    if (check) {
      this.setState({
        gplp: 2,
        showLPModal: false,
      });
    } else {
      swal('Please certify your understanding.', '', 'error');
    }
  };

  render() {
    const fundingLeft = this.getFundingLeft();
    let { MinimumInvestment } = this.state;

    if (MinimumInvestment > fundingLeft) {
      this.setState({ MinimumInvestment: fundingLeft });
    }

    return (
      <div className="container body">
        <div className="main_container">
          <div className="right_col" role="main">
            <div className="Mainstep">
              <div className="container">
                <div className="row">
                  <ul className="breadcrumb">
                    <li className="completed1">
                      <a href="javascript:void(0);">Details</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Signature</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Payment</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Confirmation</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <h3 className="text-center">
              {' '}
              {this.state.ProjectID == America_First_Energy_Fund_1
                ? 'America First Energy Fund I'
                : this.state.ProjectID == Yield_Fund_2_ID
                ? 'Yield Fund II'
                : this.state.ProjectID == Bitcoin_Project_ID
                ? 'Bitcoin Discovery Fund'
                : this.state.ProjectID == Wildcat_Project_ID
                ? 'Drilling Fund I LLC'
                : this.state.ProjectID == Innovation_Fund_1
                ? 'Innovation Fund I'
                : this.state.ProjectID == Opportunity_Fund_1_ID
                ? 'Opportunity Fund I'
                : ''}{' '}
            </h3>
            <br />
            <div className="stepContainer">
              <div id="step-1" className="content" style={{ display: 'block' }}>
                <form
                  className="form-horizontal form-label-left"
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <span
                    className="text-size-20px"
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    {/*{`There is currently $${(this.state.FundingGoalMax - this.state.CurrentFunding).toLocaleString('en-us')} remaining in the funding goal. `}*/}
                    <span id="minInvText" ref="minInvText">
                      {this.state.isCashCall
                        ? `Please select your Investor Entity below.`
                        : `The minimum investment is $${this.state.MinimumInvestment.toLocaleString(
                            'en-us'
                          )}.`}{' '}
                      {/*with increasing increments of $${this.state.UnitPrice}.`}*/}
                    </span>
                  </span>

                  <br />
                  <br />

                  <div className="form-group">
                    <div>
                      {(() => {
                        if (this.state.ProjectType === 'RegCF') {
                          return (
                            <div className="col-md-9 margin-bottom-20 text-size-15px">
                              <p style={{ 'font-size': 16 }}>
                                According to the rules, there is a maximum
                                amount you can invest in this offering. By
                                entering in your correct information below, we
                                will automatically calculate this maximum amount
                                for you.
                              </p>
                              <p>
                                <label className="text-size-15px">
                                  <span style={{ color: 'red' }}>*</span>
                                  <strong>
                                    Enter your current annual income:
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="regcf_annual_income"
                                  name="nRegCFAnnualIncome"
                                  ref="nRegCFAnnualIncome"
                                  className="form-control"
                                  onBlur={this.calcRegCFInvestAmount}
                                />
                              </p>
                              <p>
                                <label className="text-size-15px">
                                  <span style={{ color: 'red' }}>*</span>
                                  <strong>Enter your current net worth:</strong>
                                </label>
                                <input
                                  type="text"
                                  id="regcf_networth"
                                  name="nRegCFNetWorth"
                                  ref="nRegCFNetWorth"
                                  className="form-control"
                                  onBlur={this.calcRegCFInvestAmount}
                                />
                              </p>
                              <p>
                                <label className="text-size-15px">
                                  <span style={{ color: 'red' }}>*</span>
                                  <strong>
                                    How much have you invested total in all
                                    Regulation Crowdfunding projects in the last
                                    12 months outside of EnergyFunders?
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id="regcf_preinvested"
                                  ref="regcf_preinvested"
                                  name="nRegCFPreInvested"
                                  className="form-control"
                                  onBlur={this.calcRegCFInvestAmount}
                                />
                              </p>
                              <p>
                                <label>
                                  <strong>
                                    You have invested $
                                    {parseInt(
                                      this.state
                                        .myPreviousEnergyFundersInvestment
                                    ).toLocaleString('en-us')}{' '}
                                    with EnergyFunders Marketplace in the last
                                    12 months.
                                  </strong>
                                </label>
                              </p>
                            </div>
                          );
                        }
                      })()}

                      {(() => {
                        if (this.state.show !== 0) {
                          const entityText = `* By entering an Entity you represent instead of your Name, you affirmatively represent: that you have the full right and power to bind the entity and execute banking transactions on behalf of the entity, all without notice to, or consent of, any other person or entity. If you wish to add a new entity, you can do so under your Profile Settings.`;

                          return (
                            <div id="more">
                              <div className="margin-bottom-20 invform1">
                                <div className="form-group">
                                  <label
                                    htmlFor="investorEntity"
                                    className="text-size-15px"
                                  >
                                    <span style={{ color: 'red' }}>*</span>
                                    &nbsp;You are investing as (Select Your
                                    Investor Entity):
                                  </label>
                                  <div className="col-lg-10 col-sm-10 col-xs-12 ml-0 pl-0">
                                    <select
                                      className="form-control"
                                      ref="entity"
                                      id="entity"
                                      onChange={this.getEntityInfo}
                                      style={{ fontSize: 16 }}
                                    >
                                      <option value={-1}>
                                        Please select an Entity
                                      </option>

                                      {this.state.Entities.map((entity) => (
                                        <option
                                          key={entity.EntityID}
                                          value={entity.EntityID}
                                          style={{
                                            color: entity.IsDisabled
                                              ? 'grey'
                                              : 'black',
                                          }}
                                          disabled={entity.IsDisabled}
                                        >
                                          {entity.EntityLabel}
                                        </option>
                                      ))}
                                    </select>
                                    <span
                                      id="error_entity"
                                      className="text-danger"
                                    />
                                    <p>
                                      {this.state.Entities.length > 1
                                        ? entityText
                                        : ''}
                                    </p>
                                  </div>
                                  <div className="col-lg-2 col-sm-2">
                                    <div
                                      className="btn btn-green mt-0"
                                      onClick={() =>
                                        this.props.history.push('/app/Profile')
                                      }
                                    >
                                      Add New Entity
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="bankAccount"
                                    className="text-size-15px"
                                  >
                                    <span style={{ color: 'red' }}>*</span>
                                    &nbsp; Select a Bank Account:
                                  </label>
                                  <select
                                    className="form-control"
                                    style={{ fontSize: 16 }}
                                    onChange={this.onSelectChange}
                                  >
                                    <option value={-1}>
                                      Please Select a Bank Account
                                    </option>
                                    {this.state.entityAccounts.map((entity) => (
                                      <option
                                        key={getHash(entity)}
                                        value={entity.fsId}
                                      >
                                        {entity.name.toUpperCase()}
                                        &ensp;|&ensp;
                                        {entity.bankName.toUpperCase()}
                                      </option>
                                    ))}
                                    <option value="-10">Use Bitcoin</option>
                                    <option value="-20">Use Ethereum</option>
                                  </select>
                                  <span
                                    id="error_entity"
                                    className="text-danger"
                                  />
                                </div>
                                <br />
                                <div
                                  className="form-group"
                                  id="taxBasisDiv"
                                  style={{ display: 'none' }}
                                >
                                  <label
                                    htmlFor="bankAccount"
                                    className="text-size-15px"
                                  >
                                    <span style={{ color: 'red' }}>*</span>
                                    &nbsp; Enter Your Tax Basis in Dollar
                                    Amounts for the Selected Crypto:
                                  </label>
                                  <input
                                    type="text"
                                    id="taxBasis"
                                    name="taxBasis"
                                    ref="taxBasis"
                                    className="form-control"
                                    style={{
                                      border: '1px solid #cccccc',
                                      borderRadius: '0px',
                                      backgroundColor: '#ffffff',
                                    }}
                                  />
                                </div>
                                <br />
                                <br />
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>

                  <div
                    id="bottomHalf"
                    style={{ display: this.state.show !== 0 ? '' : 'none' }}
                  >
                    {console.log('fee amount', this.state.FeeAmount)}
                    <div id="slidecontainer">
                      <label htmlFor="val" className="text-size-15px">
                        <span style={{ color: 'red' }}>*</span>
                        &nbsp;Investment Amount:
                      </label>
                      {/* <div style={{ padding: '0 0 10px 10px' }}>Please use the slider or + and -
                                                buttons below to change the amount.
                                            </div> */}
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input
                          onChange={this.slider}
                          type="text"
                          //    min={this.state.MinimumInvestment} max={this.state.max}
                          //    step={this.state.UnitPrice}
                          defaultValue={5000}
                          ref="range"
                          className="slider"
                          //    id="myRange"

                          id="val"
                        />
                      </div>
                      <br />
                      {/* <strong>
                                                <p>
                                                    <div className="input-group">
                                                        <span className="input-group-addon">$ checkInvestmentAmount</span>
                                                        <input type="text"
                                                               id="val" ref="val"
                                                               onBlur={this.checkInvestmentAmount}
                                                               style={{
                                                                   borderTop: '1px solid #cccccc',
                                                                   borderRadius: '0px',
                                                                   borderBottom: '1px solid #cccccc',
                                                                   paddingBottom: '8px',
                                                                   backgroundColor: '#ffffff',
                                                               }}
                                                               disabled
														/>

                                                        <div className="input-group-addon btn-group" role="group"
                                                             style={{ padding: 0 }}>
                                                            <button type="button"
                                                                    className={`btn-sm ${this.disableReduceInvestment() ? 'btn-link' : 'btn-danger'}`}
                                                                    onClick={this.reduceInvestment}
                                                                    disabled={this.disableReduceInvestment()}
                                                                    style={{
                                                                        margin: 0,
                                                                        height: '40px',
                                                                        borderRadius: 0
                                                                    }}>

                                                                <Icon iconName="minus"
                                                                      extraClasses={`${this.disableReduceInvestment() ? '' : 'white-text'}`}/>
                                                            </button>
                                                            <button type="button"
                                                                    className={`btn-sm ${this.disableIncrementInvestment() ? 'btn-link' : 'btn-primary'}`}
                                                                    onClick={this.increaseInvestment}
                                                                    disabled={this.disableIncrementInvestment()}
                                                                    style={{
                                                                        margin: 0,
                                                                        height: '40px',
                                                                        borderRadius: '0 4px 4px 0',
                                                                    }}>
                                                                <Icon iconName="plus"
                                                                      extraClasses={`${this.disableIncrementInvestment() ? '' : 'white-text'}`}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </p>
                                            </strong> */}
                    </div>
                    {this?.refs?.range?.value >= 100000 ? (
                      <div className="font-bold">
                        {' '}
                        You are investing in class{' '}
                        {this.refs.range.value >= 100000 &&
                        this.refs.range.value < 250000
                          ? 'A'
                          : this.refs.range.value >= 250000 &&
                            this.refs.range.value < 500000
                          ? 'B'
                          : this.refs.range.value >= 500000
                          ? 'C'
                          : ''}{' '}
                        shares.{' '}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {console.log(this.refs?.range?.value)}
                    {/* {this.state.ProjectID !== Innovation_Fund_1 && this.state.FeeAmount && this.state.FeeAmount > 0 &&
                                        <div className="form-group">
                                            <label
                                                htmlFor="bankAccount"
                                                className="text-size-15px"
                                            >
                                                Origination Fee: ${formatMoneyShowZero(this.state.FeeAmount)}
                                            </label>
                                        </div>
                                        } */}

                    {this.state.ProjectID == Wildcat_Project_ID ||
                    this.state.ProjectID == Yield_Fund_2_ID ||
                    this.state.ProjectID == America_First_Energy_Fund_1
                      ? this.renderYieldFund2Percentages()
                      : null}

                    {this.state.ProjectID !== Innovation_Fund_1 &&
                    this.state.ProjectID !== Wildcat_Project_ID
                      ? this.renderRegDProject()
                      : null}
                    {this.state.ProjectID !== Innovation_Fund_1
                      ? this.renderRegCFProject()
                      : null}
                    <br />
                  </div>
                </form>
                <hr />
              </div>
              {/* <div ><p className="text-black" style={{fontStyle:'italic', fontWeight:'bold'}}><b style={{textAlign:'center'}}>IMPORTANT NOTICE:</b> Due to payment processing lag times, if you would like your investment to count toward the 2022 tax year, please WIRE YOUR INVESTMENT to us. If you invest via our online portal at this time, the funds may not reach us by year end. Please call us at <a href="tel:7138633297" className='text-green text-decoration-underline'>713-863-3297</a> or email at <a href="mailto: info@energyfunders.com" className='text-green text-decoration-underline'>info@energyfunders.com</a> for details on how to wire your investment.</p></div> */}
              <div className="done" id="continueBlock">
                <button
                  onClick={this.onNext}
                  id="invest1next"
                  className="btn btn-success"
                >
                  Continue
                </button>
                <br />
                <br />
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: 0 }}
                  onClick={this.onCancelInvestment}
                >
                  Cancel Investment
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Modal
            show={this.state.showGPModal}
            style={{
              width: '90%',
              margin: '0 auto',
            }}
          >
            <Modal.Header>
              <Modal.Title>Investor General Partner (GP) Interests</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <p>
                  <strong>
                    <em>• Tax Consequences.</em>
                  </strong>{' '}
                  Generally, if you invest as an{' '}
                  <strong>Investor General Partner (GP)</strong>, then your
                  share of the partnership's deduction for{' '}
                  <a
                    href="javascript:void(0);"
                    className="popovers"
                    data-trigger="hover"
                    data-placement="bottom"
                    data-original-title="Intangible Drilling Costs"
                    data-content="Definition: 'Intangible drilling costs', generally, means those costs of drilling and completing a well that are currently deductible, as compared to lease costs, which must be recovered through the depletion allowance, and costs for equipment in the well, which must be recovered through depreciation deductions.  For example, intangible drilling costs include all expenditures made for any well before production in commercial quantities for wages, fuel, repairs, hauling, supplies and other costs and expenses incident to and necessary for drilling the well and preparing the well for production of oil or natural gas.  Intangible drilling costs also include the expense of plugging and abandoning any well before a completion attempt, and the costs (other than non-deductible equipment costs and lease acquisition costs) to re-enter and deepen an existing well, complete the well to deeper reservoirs, or plug and abandon the well if it is non-productive from the targeted deeper reservoirs.  See the materials provided for more information."
                  >
                    intangible drilling costs
                  </a>{' '}
                  will not be subject to the passive activity limitations on
                  losses. You may claim a deduction in an amount equal to not
                  less than the percentage of your net subscription amount used
                  to pay for intangible drilling costs for all of the wells to
                  be drilled by the partnership in that taxable year. Please
                  consult your tax professional to determine how this applies to
                  your particular tax situation.
                </p>
                <p>
                  <strong>
                    <em>• Unlimited Liability.</em>
                  </strong>{' '}
                  If you invest as an Investor General Partner, you will have
                  unlimited liability regarding the partnership's activities.
                  This means that if (i) the partnership's insurance proceeds
                  from any source, (ii) the Managing GP's indemnification of the
                  Investor General Partners, and (iii) the partnership's assets
                  were, collectively, not sufficient to satisfy a partnership
                  liability for which the Investor General Partners were also
                  liable solely because of your status as general partners of
                  the partnership, then the Managing GP would require the
                  Investor General Partners to make additional capital
                  contributions to the partnership to satisfy the liability. In
                  addition, the Investor General Partners will have joint and
                  several liability, which means, generally, that a person with
                  a claim against the partnership and/or an Investor General
                  Partner may sue all or any one or more of the partnership's
                  general partners, including you, for the entire amount of the
                  liability.
                </p>
                <p>
                  <strong>
                    <em>• Assessability.</em>
                  </strong>{' '}
                  You will be able to determine if your Interests are subject to{' '}
                  <a
                    href="javascript:void(0);"
                    className="popovers"
                    data-trigger="hover"
                    data-placement="bottom"
                    data-original-title="Assessability"
                    data-content="Definition: 'Assessability', generally, means that levies may be imposed on the partnership interest held to contribute more funds.  Thus, an assessable partnership interest can be required to contribute more funds, while a non-assessable partnership interest cannot be required to contribute more funds.  The Investor General Partner Interests are assessable but become non-assessable once they convert to Investor Limited Partner Interests.  However, if any obligations or liabilities were assessed on the Investor General Partner Interests before the effective date of conversion (or such obligations or liabilities were incurred but not assessed before conversion), such assessments or obligations will remain."
                  >
                    assessability
                  </a>{' '}
                  based on whether you buy Investor General Partner Interests,
                  which are assessable, or Limited Partner Interests, which are
                  non-assessable.
                </p>
                <p>
                  <strong>
                    <em>• Conversion Timeline.</em>
                  </strong>{' '}
                  Your Investor General Partner Interests will be automatically
                  converted by the Managing GP to Limited Partner Interests upon
                  the occurrence of the earlier of (i) the drilling and
                  completion of all of the partnership's wells, as determined by
                  the Managing GP or its professionals, or (ii) the date that no
                  additional currently deductible intangible drilling costs will
                  be realized by the partnership's Investor General Partners, as
                  determined by the Managing GP. In this regard, a well is
                  deemed to be completed when production equipment is installed
                  on a well, even though the well may not yet be connected to a
                  pipeline for production of oil or natural gas. The timeline
                  for such conversion depends on the timing and amount of the
                  sale of the Interests as well as the availability of
                  appropriate Projects being sourced by the partnership's
                  operators. The partnership will generally invest in Projects
                  at the time leases are acquired through the completion of the
                  wells. Once all of the wells within all of the partnership's
                  Projects are completed, the Investor General Partner Interests
                  will then be converted to Limited Partner Interests. If the
                  offering raises the maximum offering amount, the partnership
                  will be able to drill more wells and the larger number of
                  wells would be expected to take longer to drill. If the
                  offering raises less than the maximum offering amount, the
                  number of wells that may be drilled will be less and,
                  therefore, drilling would be expected to be completed sooner.
                  The conversion is not expected to create any tax liability to
                  the investors. We are not tax advisors, please consult your
                  tax advisor regarding your particular tax situation.
                </p>
                <p>
                  <strong>
                    <em>• Effect of Conversion on Liability.</em>
                  </strong>{' '}
                  Once your Interests are converted, you will have the limited
                  liability of a limited partner under Texas law for partnership
                  obligations and liabilities arising after the conversion.
                  However, you will continue to have the responsibilities of a
                  general partner for partnership liabilities and obligations
                  incurred before the effective date of the conversion. For
                  example, you might become liable for partnership liabilities
                  in excess of your subscription amount during the time the
                  partnership is engaged in drilling activities and for
                  environmental claims that arose during drilling activities,
                  but were not discovered until after the conversion.
                </p>
                <br />
                <div
                  style={{
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}
                >
                  <input
                    type="checkbox"
                    name="confirm_GP"
                    id="confirm_GP"
                    defaultValue={1}
                  />{' '}
                  <label htmlFor="confirm_GP">
                    I certify that I understand 'Investor General Partner (GP)
                    Interests'.
                  </label>
                </div>
                <br />
                <br />
                <div>
                  <a
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onSelectGP}
                  >
                    Invest as a General Partner
                  </a>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                id="closeText_GP"
                style={{
                  float: 'left',
                  marginTop: 8,
                }}
              >
                Click "Close" to close this window without making a selection.
              </div>
              <Button onClick={() => this.setState({ showGPModal: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal
            show={this.state.showLPModal}
            style={{
              width: '90%',
              margin: '0 auto',
            }}
          >
            <Modal.Header>
              <Modal.Title>Investor Limited Partner (LP) Interests</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>
                  <strong>
                    <em>• Tax Consequences.</em>
                  </strong>{' '}
                  If you invest as an{' '}
                  <strong>Investor Limited Partner (LP)</strong>, then your use
                  of your share of the partnership's deduction for intangible
                  drilling costs will be limited to offsetting your net passive
                  income from{' '}
                  <a
                    href="javascript:void(0);"
                    className="popovers"
                    data-trigger="hover"
                    data-placement="bottom"
                    data-original-title="'Passive Trade or Business Activities'"
                    data-content="Definition: 'Passive trade or business activities' generally include the partnership and other limited partner investments, but passive income does not include salaries, dividends or interest.  This means that you will not be able to deduct your share of the partnership's intangible drilling costs in the year in which you invest, unless you have net passive income.  However, any portion of your share of the partnership's deduction for intangible drilling costs that you cannot use in the year in which you invest, because you do not have sufficient net passive income in that year, may becarried forward indefinitely until you can use it to offset your net passive income from the partnership or your other passive activities, if any, in subsequent tax years. See the materials provided for more information."
                  >
                    "passive" trade or business activities
                  </a>
                  . Please consult your tax professional to determine how this
                  applies to your particular tax situation.
                </p>
                <p>
                  <strong>
                    <em>• Limited Liability.</em>
                  </strong>{' '}
                  If you invest as a Limited Partner, then you will have limited
                  liability for the partnership's liabilities and obligations.
                  This means that you will not be liable for any partnership
                  liabilities or obligations beyond the amount of your
                  subscription amount in the partnership and your share of the
                  partnership's undistributed net profits, subject to certain
                  exceptions set forth in the materials provided.
                </p>
                <br />
                <div
                  style={{
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}
                >
                  <input
                    type="checkbox"
                    name="confirm_LP"
                    id="confirm_LP"
                    defaultValue={1}
                  />{' '}
                  <label htmlFor="confirm_LP">
                    I certify that I understand 'Investor Limited Partner (LP)
                    Interests'.
                  </label>
                </div>
                <div
                  id="confirm_LP_error"
                  className="alert-danger"
                  style={{ display: 'none' }}
                />
                <br />
                <br />
                <div>
                  <a
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onSelectLP}
                  >
                    Invest as a Limited Partner
                  </a>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                id="closeText_GP"
                style={{
                  float: 'left',
                  marginTop: 8,
                }}
              >
                Click "Close" to close this window without making a selection.
              </div>
              <Button onClick={() => this.setState({ showLPModal: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const { object, bool } = PropTypes;

Invest.propTypes = {
  ...InvestmentLogBase.propTypes,
  session: object.isRequired,
  authenticated: bool.isRequired,
  checked: bool.isRequired,
};

const mapState = ({ session }) => ({
  ...InvestmentLogBase.defaultProps,
  session: session.user,
  checked: session.checked,
  authenticated: session.authenticated,
});

const mapDispatchToProps = (_dispatch) => ({
  ...bindActionCreators(Actions, _dispatch),
});

export default withRouter(connect(mapState, mapDispatchToProps)(Invest));
