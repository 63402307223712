import React, { Component } from 'react';
import swal from 'sweetalert2';
import ReactPasswordStrength from 'react-password-strength';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { sessionService } from 'redux-react-session';
import { Button, Modal, OverlayTrigger, Panel, Tooltip } from 'react-bootstrap';
import store from '../../index';
import RequestService from '../../api/RequestService';
import NewEntityContainerComponent from '../shared/NewEntityContainerComponent/NewEntityContainerComponent';
import Icon from '../shared/IconComponent/IconComponent';
import { HTTP_SUCCESS } from '../../utils/ApplicationConstants';
import NotificationService from '../../utils/NotificationService';
import {
    ENTITY_VERIFY_BUSINESS_AND_WITH_DOC,
    ENTITY_VERIFY_BUSINESS_WITH_DOC,
    ENTITY_VERIFY_CONTROLLER_WITH_DOC
} from '../../utils/ApplicationConstants';
import { checkValidPWChars, getHash, numberMaskify } from '../../utils/General';
import HelloSignW9 from "../../utils/HelloSignW9";
import CheckboxInputComponent from '../shared/InputComponents/CheckboxInputComponent/CheckboxInputComponent';
import qrcode from 'qrcode';
import { authenticator } from 'otplib';
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BitcoinWallet from './BitcoinWallet'
import { getImpersonateID } from "../../utils/General";
import './css/bankAccounts.css'
// import MyBankAccounts from './MyBankAccounts';
import Switch from "react-switch";
import HideComponent from '../shared/HideComponent/HideComponent';
import { loadParallel } from '@parallelmarkets/vanilla'
import { ParallelProvider, useParallel, PassportButton } from '@parallelmarkets/react'
import { withParallelMarketsHOC } from './WithParallelMarketsHOC';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNewEntityModalOpen: false,

            id: props.session.id,
            User: [],
            num: '',
            PWChangePanel: false,
            MFAChangePanel: false,
            QRCode: '',
            mfaPass: '',
            IsValid: false,
            isAccredited: false,
            numEntities: 0,

            EntityID: '',
            Entities: [],
            default_gp_lp_election: 2,

            ContactPrefSMS: false,
            ContactPrefEmail: false,
            showGPModal: false,
            showLPModal: false,
            showEntityModal: false,

            legalFN: '',
            legalLN: '',

            FirstName: '',
            LastName: '',
            Email: '',

            selectedEntityType: '',
            selectedEntityStatus: 'verified',
            selectedBankStatus: 'verified',
            selectedEntityW9Status: 0,
            selectedEntityFilesUploaded: true,
            selectedEntityController: 'Self',
            isEntityVerificationModalOpen: false,
            selectedEntityBOVStatus: 'verified',
            fundingSources: [],
            verifyBusiness: false,
            verifyController: false,
            isFileVerificationModalOpen: false,
            entityDetails: {
                entityType: '',
                entityName: '',
                legalFirstName: '',
                legalLastName: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                phone: '',
                country: '',
            },

            editEntity: {

                EN: '',
                ENType: '',
                legalFN: '',
                legalLN: '',
                ADD: '',
                CI: '',
                ST: '',
                ZP: '',
                CO: '',
                PH: '',
                EntityID: ''
            }
        };
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ loginStatus: nextProps.loginStatus });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            loginStatus: this.props.loginStatus,
            parallel: this.props.parallel
        });
        console.log("proppeee", this.props);
        store.subscribe(() => {
            const currentState = store.getState();

            this.setState({
                id: currentState.session.user.id,
            });
        });

        this.getInvestorProfile();
        this.getInvestorEntities();
    }



    componentDidUpdate(prevProps, prevState) {
        const { Entities } = this.state;
        const { Entities: _Entities } = prevState;

        if (getHash(Entities) !== getHash(_Entities)) {
            this.onSelectEntity();
        }

    }



    verifyPersonalEntity = async () => {
        try {
            const { data } = await RequestService.get('/api/investor/entity/personal/verify');

            if (!data.verified) {
                this.toggleEntityVerificationModal();
                return;
            }

            this.toggleNewEntityModal();
        }
        catch (error) {
            console.log(error);
            NotificationService.error(
                'Error verifying the Personal entity',
                10000,
            );
        }
    };



    toggleNewEntityModal = () => {
        const { isNewEntityModalOpen } = this.state;

        this.setState({ isNewEntityModalOpen: !isNewEntityModalOpen });
    };



    toggleVerificationModal = (entity) => {

        let selectedEntityId = entity?.EntityID ? Number(entity?.EntityID) : "";
        const { isVerifyEntityModalOpen } = this.state;

        if (entity?.EntityType === 'Personal' && entity?.EntityController === 'Self') {
            this.props.history.push({
                pathname: '/app/VerifyDwolla',
            });
        }
        else if (entity?.EntityType === 'Personal' && entity?.EntityController === 'FA') {
            this.props.history.push({
                pathname: '/app/VerifyDwolla',
                state: {
                    EntityID: entity?.EntityID
                }
            });
            // this.props.history.push({
            // 	pathname: '/app/createNewUserEntity',
            // 	state: {
            // 		RegistrationStep: 1
            // 	}
            // });
        }

        else {
            this.setState({ isVerifyEntityModalOpen: !isVerifyEntityModalOpen, selectedEntityId: selectedEntityId });
        }
    };



    onUpdateEntity = async (e) => {
        e.preventDefault();
        const formTemp = [];
        formTemp.push(`Address=${encodeURIComponent(this.state.editEntity.ADD)}`);
        formTemp.push(`City=${encodeURIComponent(this.state.editEntity.CI)}`);
        formTemp.push(`State=${encodeURIComponent(this.state.editEntity.ST)}`);
        formTemp.push(`ZipCode=${encodeURIComponent(this.state.editEntity.ZP)}`);
        formTemp.push(`Country=${encodeURIComponent(this.state.editEntity.CO)}`);
        formTemp.push(`Phone=${encodeURIComponent(this.state.editEntity.PH)}`);
        formTemp.push(`default_gp_lp_election=${this.state.default_gp_lp_election}`);
        // formTemp.push(`ContactPrefSMS=${this.state.ContactPrefSMS ? 1 : 0}`);
        const formBody = formTemp.join('&');

        try {
            const impersonateID = await getImpersonateID();
            const { data: status } = await RequestService.put({
                url: `/api/updateInvestorEntity/${this.state.editEntity.EntityID}?impersonateID=${impersonateID}`,
                data: formBody,
            });

            if (status.code === 200) {
                swal('Entity Updated successfully', '', 'success');
                this.getInvestorProfile();
                this.getInvestorEntities();
            }
            else {
                swal('Error', '', 'error');
            }
        }
        catch (err) {

            console.log(err);
        }


    };



    onSelectGP = () => {
        const check = document.getElementById('confirm_GP').checked;

        if (check) {
            this.setState({
                default_gp_lp_election: 1,
                showGPModal: false,
            });
        }
        else {
            swal('Please Accept the Terms and conditions.', '', 'error');
        }
    };



    onSelectLP = () => {
        const check = document.getElementById('confirm_LP').checked;

        if (check) {
            this.setState({
                default_gp_lp_election: 2,
                showLPModal: false,
            });
        }
        else {
            swal('Please Accept the Terms and conditions.', '', 'error');
        }
    };



    showGPLPModal = (type) => {
        if (this.refs.pro.value !== '0') {
            if (type === 'showGPModal') {
                this.setState({
                    showGPModal: true,
                });
            }
            else if (type === 'showLPModal') {
                this.setState({
                    showLPModal: true,
                });
            }
        }
        else {
            swal('Please Select an Entity First.', '', 'warning');
        }
    };



    onSelectEntity = async (EntityID) => {

        for (let i = 0; i < this.state.Entities.length; i++) {
            if (this.state.Entities[i].EntityID === Number(EntityID)) {


                await this.setState({
                    EntityID: EntityID,
                    default_gp_lp_election: this.state.Entities[i].default_gp_lp_election,
                    selectedBankStatus: this.state.Entities[i].status,
                    selectedEntityBOVStatus: this.state.Entities[i].DwollaBOVerificationStatus,
                    selectedEntityStatus: this.state.Entities[i].EntityStatus,
                    selectedEntityW9Status: this.state.Entities[i].w8n9formVerify,
                    selectedEntityId: Number(EntityID),
                    selectedEntityType: this.state.Entities[i].EntityType,
                    selectedEntityFilesUploaded: this.state.Entities[i].filesUploaded,
                    selectedEntityController: this.state.Entities[i].EntityController,
                    ContactPrefSMS: this.state.Entities[i].ContactPrefSMS,
                    entityDetails: {
                        ...this.state.entityDetails, entityType: this.state.Entities[i].EntityType,
                        entityName: this.state.Entities[i].EntityName, legalFirstName: this.state.Entities[i].LegalFirstName,
                        legalLastName: this.state.Entities[i].LegalLastName, address: this.state.Entities[i].Address,
                        city: this.state.Entities[i].City, state: this.state.Entities[i].State,
                        zipCode: this.state.Entities[i].ZipCode, phone: this.state.Entities[i].Phone,
                        country: this.state.Entities[i].Country,
                    }

                });
                this.signW9();
                break;
            }
            // Selected 'nothing'... Reset values to default.
            else {
                await this.setState({
                    EntityID: '',
                    default_gp_lp_election: 2,
                    selectedEntityType: '',
                    selectedBankStatus: 'verified',
                    selectedEntityBOVStatus: 'verified',
                    selectedEntityStatus: 'verified',
                    selectedEntityW9Status: 1,
                    selectedEntityId: '',
                    selectedEntityFilesUploaded: true,
                    isEntityVerificationModalOpen: false,
                    ContactPrefSMS: false,
                },

                );
            }
        }
    };



    togglePWChangePanel = () => {
        this.setState({ PWChangePanel: !this.state.PWChangePanel });
        this.refs.oldpass.value = '';
        this.refs.newpass.clear();
        this.refs.cnewpass.value = '';
    };



    toggleMFAChangePanel = (e) => {
        e.preventDefault();
        this.setState({ MFAChangePanel: !this.state.MFAChangePanel });
    };



    toggleGoogleAuthMFA = async () => {
        const formTemp = [];
        formTemp.push(`enableGoogleMFA=${(this.refs.GoogleAuthMFA.checked ? 1 : 0)}`);
        const formBody = formTemp.join('&');

        try {
            const { data: status } = await RequestService.post({
                url: '/api/toggleGoogleAuthMFA',
                data: formBody,
            });

            if (status.code === 200 && status.secret !== '') {
                const user = status.email;
                const service = 'EnergyFunders';
                const otpauth = authenticator.keyuri(user, service, status.secret);

                qrcode.toDataURL(otpauth, (err, imageUrl) => {
                    if (err) {
                        console.log('Error with QR');
                        return;
                    }

                    this.setState({ QRCode: imageUrl, mfaPass: status.secret });
                });
            }
            else {
                this.setState({ QRCode: '', mfaPass: '' });
            }
        }
        catch (e) {

        }
    };



    testGAMFA = async () => {
        if (this.refs.gamfaTest.value.trim() !== '') {
            const formTemp = [];
            let gamfa = this.refs.gamfaTest.value.replace(/\s/g, '');
            formTemp.push(`gamfaToken=${gamfa}`);
            const formBody = formTemp.join('&');

            try {
                const { data: status } = await RequestService.post({
                    url: '/api/testGAMFA',
                    data: formBody,
                });

                if (status.code === 200) {
                    this.refs.testGAMFAResults.style.color = 'green';
                    this.refs.testGAMFAResults.innerHTML = 'Correct!';
                }
                else {
                    this.refs.testGAMFAResults.style.color = 'red';
                    this.refs.testGAMFAResults.innerHTML = 'Incorrect.';
                }
            }
            catch (e) {

            }
        }
    };



    onTypingPassword = ({ score, password, isValid }) => {
        if (isValid && checkValidPWChars(password)) {
            document.getElementById('error_password').innerHTML = '';
        }else if(password?.length > 0){
            document.getElementById('error_password').innerHTML = 'Please increase your password strength by using letters, numbers and special symbols (excluding & [ ] < > " \').';
        }
        else {
            document.getElementById('error_password').innerHTML = '';
        }

        this.setState({
            Password: password,
            IsValid: isValid,
        });
    };



    changePassword = async (e) => {
        e.preventDefault();

        if (!this.state.IsValid || !checkValidPWChars(document.getElementById('newpass').value)) {
            document.getElementById('error_password').innerHTML = 'Please increase your password strength by using letters, numbers and special symbols (excluding & [ ] < > " \').';
        }
        else if (document.getElementById('newpass').value !== this.refs.cnewpass.value) {
            document.getElementById('error_cnewpass').innerHTML = 'Passwords do not match.';
        }
        else {
            e.preventDefault();
            document.getElementById('error_password').innerHTML = '';
            const formTemp = [];

            formTemp.push(`OldPassword=${encodeURIComponent(this.refs.oldpass.value)}`);
            formTemp.push(`NewPassword=${encodeURIComponent(document.getElementById('newpass').value)}`);
            const formBody = formTemp.join('&');

            try {
                const { data: status } = await RequestService.post({
                    url: '/api/updatePassword',
                    data: formBody,
                });

                if (status.code === 200) {
                    swal('Password Changed successfully', '', 'success');

                    await sessionService.saveUser({
                        id: this.state.id,
                        marketing: status.hash,
                    });

                    this.togglePWChangePanel();
                }
                else if (status.code === 400) {
                    swal('Old Password is incorrect.', '', 'error');
                }
                else {
                    alert('Could not change password. Please try again later.');
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    };



    updateNameEmail = async (e) => {
        e.preventDefault();

        const formTemp = [];

        formTemp.push(`FirstName=${encodeURIComponent(this.state.FirstName)}`);
        formTemp.push(`LastName=${encodeURIComponent(this.state.LastName)}`);
        formTemp.push(`Email=${encodeURIComponent(this.state.Email)}`);
        formTemp.push(`OldEmail=${encodeURIComponent(this.state.User.Email)}`);
        const formBody = formTemp.join('&');
        try {
            const { data: status } = await RequestService.post({
                url: '/api/updateInvestorProfileNameEmail',
                data: formBody,
            });

            if (status.code === 200) {
                swal('Update Successful', '', 'success');
                this.getInvestorProfile();
            }
            else if (status.code === 201) {
                swal('Email Already Exists.', '', 'error');
            }
            else {
                alert('Could not update due to unknown error. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };



    getInvestorProfile = async () => {
        try {
            const impersonateID = await getImpersonateID();
            const { data } = await RequestService.get(`/api/getInvestorProfile?impersonateID=${impersonateID}`);

            if (data.code === 200) {
                this.setState({
                    User: data.rows[0],
                    legalFN: data.rows[0].LegalFirstName,
                    legalLN: data.rows[0].LegalLastName,
                    FirstName: data.rows[0].FirstName,
                    LastName: data.rows[0].LastName,
                    Email: data.rows[0].Email,
                });

                this.refs.FirstName.value = data.rows[0].FirstName;
                this.refs.LastName.value = data.rows[0].LastName;
                this.refs.Email.value = data.rows[0].Email;
                this.refs.GoogleAuthMFA.checked = (data.rows[0].mfaType === 1);

                if (data.rows[0].mfaType === 1) {
                    const user = data.rows[0].Email;
                    const service = 'EnergyFunders';
                    const otpauth = authenticator.keyuri(user, service, data.rows[0].mfaPass);

                    qrcode.toDataURL(otpauth, (err, imageUrl) => {
                        if (err) {
                            console.log('Error with QR');
                            return;
                        }

                        this.setState({ QRCode: imageUrl });
                    });
                }
            }
            else {
                alert('Could not retrieve your profile. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };


    getInvestorEntities = async () => {
        try {
            const impersonateID = await getImpersonateID();
            const { data } = await RequestService.get(`/api/investor/profile/entities/${impersonateID}`);

            if (data.code === 200) {
                let numEntities = 0;
                data.rows.map(entity => {
                    if (entity.EntityID > 0 && entity.EntityController === 'Self')
                        numEntities++;
                });

                const fundingSources = await this.getInvestorFundingSource(data?.rows);
                this.setState({
                   //Both entity and finding source has status column. They are merged later on...
                   //status will be bank status, EntityStatus will be the tru status of entity
                   //Code defaults verified for non existing banks elsewhere
                    Entities: data.rows.map(r => ({...r, status: "verified", EntityStatus: r.status})),
                    isAccredited: data.isAccredited,
                    numEntities: numEntities,
                    fundingSources: fundingSources
                });
            }
            else {
                alert('Could not get list of entities. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };


    async getInvestorDwollaBankAccounts() {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/getInvestorDwollaBankAccounts/${impersonateID}`);
        if (data.code === HTTP_SUCCESS) {
            const fundingSources = await this.getInvestorFundingSource(data?.data);
            this.setState({
                Banks: data.data,
                fundingSources: fundingSources
            });
        }
    }
    async getInvestorFundingSource(entities) {
        const fundingSources = [];
        const EntityIDs = entities.map(entity => entity.EntityID);

        for (const entityId of EntityIDs) {
            const source = await this.performFundingSource(entityId);
            if (source?.length > 0) {
                let fundingSource = source?.map(item => ({ ...item, EntityID: entityId }))
                fundingSources.push(...fundingSource);

            }

        }

        return fundingSources;

    };


    async performFundingSource(entityId) {

        try {
            const impersonateID = await getImpersonateID();
            const { data: BankData } = await RequestService.post({
                url: `/api/getInvestorFundingSourceData/${impersonateID}`,
                data: {
                    entityId,
                },
            });
            return BankData;
        } catch (e) {

            console.log(e)
        }

    }
    async removeFundingSource(account) {
        const willDelete = await swal({
            title: 'Are you sure you want to remove this bank account?',
            icon: 'warning',
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        });

        if (willDelete) {
            try {
                // const { selectedEntityId } = this.state;

                await RequestService.post({
                    url: `/api/removeInvestorFundingSource/${this.state.id}/${this.props.session.marketing}`,
                    data: {
                        fundingSourceId: account.fsId,
                        isDefault: account.isDefault,
                    },
                });

                NotificationService.success();
                this.getInvestorDwollaBankAccounts();
                // this.onhandleChange({ target: { value: selectedEntityId } });
            } catch (e) {
                console.log(e);
                NotificationService.error('Error Removing your Bank Account', 10000);
            }
        }
    };


    async setDefault({ fsId, EntityID }) {
        const { selectedEntityId } = this.state;
        const url = `/api/investor/bank-account/${fsId}/default`;
        const notificationText = `
                For this entity, the default bank account is used as the main account
                to receive Distributions and Returns of Capital.
            `;

        try {
            const { value: setAsDefault } = await NotificationService.custom({
                title: 'Do you want this account to be the default?',
                text: notificationText,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#5bb462',
                cancelButtonColor: '#d6d6d6',
                confirmButtonText: 'Set as Default',
            });

            if (setAsDefault) {
                await RequestService.put({
                    url,
                    data: {
                        EntityID,
                    },
                });
                this.getInvestorDwollaBankAccounts();
                // this.onhandleChange({ target: { value: selectedEntityId } });
            }
        }
        catch (e) {
            console.log(e);
            NotificationService.error(
                'Error Setting the bank account as default',
                10000,
            );
        }

    };
    signW9 = async () => {
        const { history } = this.props;

        if (this.state.selectedEntityStatus === 'verified') {
            if (this.state.selectedEntityType === 'Personal' && this.state.selectedEntityController === 'Self') {
                await HelloSignW9({ call: 'signW9Personal', EntityID: this.state.EntityID });
            }
            if (this.state.selectedEntityType === 'Personal' && this.state.selectedEntityController === 'FA') {
                await HelloSignW9({ call: 'signW9OtherPersonal', EntityID: this.state.EntityID });
            }
            else {
                await HelloSignW9({ call: 'signW9Entity', EntityID: this.state.EntityID });
            }
        }
        else {
            swal({
                type: 'warning',
                title: 'You will need to complete Investor Identity Verification first.',
                showConfirmButton: true
            })
            // .then(history.push({ pathname: '/app/VerifyDwolla' }));

        }
    };



    toggleEntityVerificationModal = () => {
        const { isEntityVerificationModalOpen } = this.state;

        this.setState({ isEntityVerificationModalOpen: !isEntityVerificationModalOpen });
    };



    goToVerifyDwolla = () => {
        const { history } = this.props;

        this.toggleEntityVerificationModal();
        history.push({ pathname: '/app/VerifyDwolla' });
    };



    getDwollaFileVerificationData = async () => {
        const { selectedEntityId } = this.state;
        const url = `/api/investor/entity/${selectedEntityId}/missing-verification-files`;

        try {
            const { data } = await RequestService.get(url);

            this.openDwollaFileVerificationModal(data);
        }
        catch (error) {
            console.log('Error verifying missing documents', error);
            NotificationService.error(
                'Error verifying missing documents',
                10000,
            );
        }
    };



    openDwollaFileVerificationModal = (requiredDocs) => {
        let verifyController = false;
        let verifyBusiness = false;

        requiredDocs.forEach((key) => {
            if (key === ENTITY_VERIFY_BUSINESS_WITH_DOC) {
                verifyBusiness = true;
            }
            if (key === ENTITY_VERIFY_CONTROLLER_WITH_DOC) {
                verifyController = true;
            }
            if (key === ENTITY_VERIFY_BUSINESS_AND_WITH_DOC) {
                verifyController = true;
                verifyBusiness = true;
            }
        });

        this.setState({
            verifyBusiness,
            verifyController,
            isFileVerificationModalOpen: true,
        });
    };



    closeDwollaFileVerificationModal = () => {
        this.setState({
            verifyBusiness: false,
            verifyController: false,
            isFileVerificationModalOpen: false,
        });
    };



    setContactPref = (contactType, value) => {
        if (contactType === 'ContactPrefSMS') {
            this.setState({
                ContactPrefSMS: value
            });

        }
        else if (contactType === 'ContactPrefEmail') {
            this.setState({
                ContactPrefEmail: value
            });

        }
    };

    updateCommunication = async (e) => {
        e.preventDefault();
        const entity = this.state.Entities.find(entity => entity.EntityType.toLowerCase() === "personal");
        const formTemp = [];

        formTemp.push(`ContactPrefSMS=${encodeURIComponent(this.state.ContactPrefSMS ? 1 : 0)}`);
        formTemp.push(`ContactPrefEmail=${encodeURIComponent(this.state.ContactPrefEmail ? 1 : 0)}`);
        formTemp.push(`EntityID=${encodeURIComponent(entity?.EntityID)}`);
        const formBody = formTemp.join('&');

        try {
            const { data: status } = await RequestService.post({
                url: '/api/updateCommunication',
                data: formBody,
            });

            if (status.code === 200) {
                swal('Update Successful', '', 'success');

            }

            else {
                alert('Could not update due to unknown error. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };



    myProfileInputchange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        });

    }
    onEditEntity = (entity) => {
        if (entity) {
            this.setState({
                editEntity: {
                    ...this.state.editEntity, EN: entity?.EntityName, ENType: entity?.EntityType,
                    legalFN: entity?.LegalFirstName, legalLN: entity?.LegalLastName, ADD: entity?.Address,
                    CI: entity?.City, ST: entity?.State, ZP: entity?.ZipCode, CO: entity?.Country,
                    PH: entity?.Phone, EntityID: entity.EntityID
                },
                showEntityModal: !this.state.showEntityModal,
            });


        }

    }

    onEditInputchange = (event) => {

        this.setState({
            editEntity: { ...this.state.editEntity, [event.target.name]: event.target.value }
        });

    }


    // startLoginWithParallelMarket = async () => {
    //     const { data } = await RequestService.get({
    //         url: '/api/getLoginWithParallelMarketStatus'
    //     })

    //     console.log("Data Start Acc: ", data);

    // }

    startAccreditation = async (e) => {
        // start loading the parallel library with the given configuration information
        // TODO Put Client ID in .env File ?
        //   parallel = loadParallel({ client_id: 'BitkTlXeQnPv037uDLSXm', environment: 'demo' })

        // we may render before the loginStatus is available
        if (!this.state.loginStatus) return null

        return (
            <>
                <h2>Status: {this.state.loginStatus.status}</h2>
                {/* Only show the login button if the user hasn't logged in yet */}
                {this.state.loginStatus.status !== 'connected' ? (
                    <PassportButton />
                ) : (
                    <button onClick={this.state.parallel.logout}>Log Out</button>
                )}
            </>
        )
    }

    // getURL = async () => {
    //     const clientID = process.env.REACT_APP_PARALLEL_MICROPAYMENT_CLIENT_ID;
    //     const clientSecretID = 'pFuM-T3LwcjxGGkesV0xQluob-Zn9q6WipB45Shi9YvNO';
    //     const redirectURL = 'http://localhost:8000/app/Profile';
    //     const scope = 'profile accreditation_status identity';
    //     // const state = process.env.OAUTH_STATE_PARALLELMARKET ? process.env.OAUTH_STATE_PARALLELMARKET : await generateState();
    //     const state = "WyrycwdjquNDKpxb34fo";
    //     console.log("State: ", state);
    //     const path = `https://api.parallelmarkets.com/v1/oauth/authorize?scope=${scope}&state=${state}&redirect_uri=${redirectURL}&client_id=${clientID}&response_type=code`;

    //     console.log("Path: ", path);

    //     return path;
    // }

    // getAccStatus = async (e) => {
    //     console.log("GetAccreditationStatus Function Called")
    //     console.log("FIND-ME-Entity: ", e)
    //     console.log("UserID: ", e["UserID"]);
    //     const userId = e["UserID"];
        // return true;

        // const { data } = await RequestService.get(`/api/getInvestorDwollaBankAccounts/${impersonateID}`);

        // const checkEntity = await RequestService.post({
        //     url:'/api/checkEntity',
        //     data: formBody
        //   })
        // let url = `/api/GetAccreditationStatus/${userId}`;
        // let formTemp = [];
        // formTemp.push(`userID=+$(encodeURIComponent(userId))`);
        // const formBody = formTemp.join('&');
        // const { data } = await RequestService.get({
        //     url:'/api/GetAccreditationStatus',
        //     data: formBody
        // });

        // const { data } = await RequestService.get(url);

        // pmAccreditationStatus = 0 => Not Verified
        // pmAccreditationStatus = 1 => In Progress
        // pmAccreditationStatus = 2 => Verified

    //     console.log("Get Acc API Status: ", data.data[0]["pmAccreditationStatus"]);
    //     const accreditationStatus = data.data[0]["pmAccreditationStatus"];
    //     return accreditationStatus;
    // }

    // getAccreditationStatus = async (e) => {
    //     const status = await this.getAccStatus(e);
    //     return status;
    // }

    // routeChange = async() => {
    //     let navigate = useNavigate();
    //     const clientID = 'qkcmDYm0OE_CgO7nIYMJN';
    //     const clientSecretID = '8rBTx-D_IcKpLDXLL9_-QGk10gVRg68Hrq2XSb6AhnjFe';
    //     const redirectURL = 'http://localhost:8000/app/Profile';
    //     const scope = 'profile accreditation_status identity';
    //     // const state = process.env.OAUTH_STATE_PARALLELMARKET ? process.env.OAUTH_STATE_PARALLELMARKET : await generateState();
    //     const state = "WyrycwdjquNDKpxb34fo";
    //     console.log("State: ", state);
    //     const path = `https://api.parallelmarkets.com/v1/oauth/authorize?
    // 	scope=${scope}&
    // 	state=${state}
    // 	redirect_uri=${redirectURL}&
    // 	client_id=${clientID}&
    // 	response_type=code`;

    //     navigate(path);
    // }

    render() {
        const {
            isNewEntityModalOpen, selectedEntityStatus, isEntityVerificationModalOpen, isFileVerificationModalOpen,
            isVerifyEntityModalOpen, selectedEntityId, selectedEntityType, verifyBusiness,
            verifyController, selectedEntityW9Status, fundingSources, Entities, /* selectedEntityFilesUploaded */
            isAccredited, FirstName, LastName, Email
        } = this.state;

        const showAddVerificationToEntity = (selectedEntityStatus === 'verified' || selectedEntityStatus === 'document');
        // const showAddVerificationFileToEntity = !(!selectedEntityFilesUploaded && selectedEntityStatus === 'document');
        const showAddVerificationFileToEntity = (selectedEntityStatus === 'document');    // May need to upload documents multiple times until 'verified' -- commented out above line.

        const addbankAccountToEntities = Entities.map((item, i) => Object.assign({}, item, fundingSources.find(f => f.EntityID === item.EntityID) ?? {}));
        const bankImg = "/img/bank-img.png"
        let mergedFundingSourceWithEntities = fundingSources?.length > 0 && fundingSources?.map(source => {

            let entity = Entities?.length > 0 ? Entities?.find(entity => entity?.EntityID === source?.EntityID) : undefined;
            if (entity)
                return { ...entity, ...source }
            else
                return {...source}
        });

const expiryDays = (date)=> {
    let date_string = date?.toString();
    var expiration = moment(date_string).format("YYYY-MM-DD");
    var current_date = moment().format("YYYY-MM-DD");
    var days = moment(expiration).diff(current_date, 'days');
    console.log("remaining days",days);
    return days;
  }





        return (
            <div className="container body manage-accounts" style={{ overflowX: 'overlay' }}>

                <div className="right_col">
                    <div className="col-xs-12 col-md-12">
                        <h2 className="pull-left">Account Settings</h2>
                        <div className="my-account-tab py-5">
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        <p>Account Summary </p>
                                    </Tab>
                                    <Tab>
                                        <p>Profile </p>
                                    </Tab>
                                    <Tab>
                                        <p>Bank Accounts </p>
                                    </Tab>
                                    <Tab>
                                        <p>W-9 </p>
                                    </Tab>
                                    <Tab>
                                        <p>Security </p>
                                    </Tab>
                                    <Tab>
                                        <p>Entities</p>
                                    </Tab>

                                    <Tab>
                                        <p>Communications</p>
                                    </Tab>
                                    <Tab>
                                        <p>Bitcoin Wallet</p>
                                    </Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Account Summary</h5>
                                            <div className="ml-auto add-entity">
                                                {this.state.numEntities < 4 && (

                                                    <div className="pull-right">
                                                        <button
                                                            className="px-5 py-4 m-0 btn btn-default upper-case-text btn-green text-white"
                                                            onClick={this.verifyPersonalEntity}
                                                            type="button"
                                                        > Add Entity
                                                        </button>
                                                    </div>

                                                )}

                                            </div>
                                        </div>
                                        <div className="content">
                                            {addbankAccountToEntities.map((entity) => (
                                                <div className='table-wrapper'>
                                                    <table className="table" style={{width:'100%'}}>
                                                        <tr style={{width:'100%'}}>
                                                            <td colSpan={4}>
                                                                <h3 className="m-0">{entity.EntityName}</h3>
                                                            </td>
                                                        </tr>
                                                        <tr style={{width:'100%'}}><td style={{verticalAlign:'baseline', width:'30%', minWidth:'110px'}}>Bank Name</td>
                                                        <td style={{verticalAlign:'baseline', width:'20%', minWidth:'110px'}}>W9 Filled</td>
                                                        <td style={{verticalAlign:'baseline', width:'20%', minWidth:'110px'}}>Entity Verified</td>
                                                        <td  style={{verticalAlign:'baseline', width:'30%', minWidth:'110px'}}>Accreditation Status {" "}
                                                        <OverlayTrigger
																placement="bottom"
																overlay={
																	<Tooltip id={"infoboot"}>
																		<div style={{
																			padding: 5,
																			fontSize: '1.2em'
																		}}>Verify your accreditation status through our partner, Parallel Markets</div>
																	</Tooltip>}>
																<a><Icon iconName="info-circle" extraClasses="infoboot"  /></a>
															</OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width:'30%', minWidth:'110px'}} >
                                                                <span>
                                                                    {entity?.bankName?.length > 1 ?
                                                                        entity.bankName
                                                                        : (<>{entity?.microdeposit_last_failed && moment(new Date().toISOString()).diff(moment(entity?.microdeposit_last_failed?.toString()), 'hours') <= 48 ?
                                                                            <div>Your bank account has not yet been properly verified. Please wait 48 hours and try again, or contact customer support at 713-863-3297 or <a href="mailto:info@energyfunders.com" className="mailto">info@energyfunders.com</a> for assistance</div> :
                                                                            <Link to="/app/setupprofile3" className='cursor-pointer font-bold'>
                                                                                Add New Bank Account
                                                                            </Link>}
                                                                        </>
                                                                        )}</span>
                                                                {entity.status?.toLowerCase() !== 'verified' &&
                                                                    <Link to="/app/verifymicrodeposits" className='cursor-pointer font-bold'>
                                                                        Verify Micro Deposits
                                                                    </Link>
                                                                }

                                                            </td>

                                                            <td style={{ width:'20%', minWidth:'110px'}} ><span>
                                                                {entity.w8n9formVerify === 0 ? (
                                                                    <button className="btn btn-default btn-link font-bold" onClick={() => this.onSelectEntity(entity.EntityID)}>
                                                                        Fill W9 Now
                                                                    </button>
                                                                ) : (
                                                                    "Yes"
                                                                )} </span>
                                                            </td>
                                                            <td style={{ width:'20%', minWidth:'110px'}} ><span>
                                                                {entity.EntityStatus?.toString().toLowerCase() === "verified" ? "Yes" : entity.EntityStatus?.toString().toLowerCase() === "document" ? (<button
                                                                    className="btn btn-default btn-link font-bold"
                                                                    onClick={() => window.location = '/app/UploadDwollaDocument'}
                                                                    type="button"
                                                                >
                                                                    Upload Entity Verification File(s) (Required)
                                                                </button>) : (<button onClick={() =>
                                                                    this.toggleVerificationModal(entity)
                                                                } className="btn btn-default btn-link font-bold">
                                                                    Verify this Entity
                                                                </button>)}</span></td>
                                                            {/* accreditation */}

                                                                <td style={{textAlign:'center',width:'30%', minWidth:'110px'}}>
                                                            {entity?.thirdPartyExpiry && expiryDays( entity?.thirdPartyExpiry ) >= 0 ? <div className="font-bold"> Yes </div> :
                                                            <> 
                                                            {/* <div className=""> Get Accredited through our partner Parallel Markets</div> */}
                                                            <span className="font-normal">Please click the Parallel Passport button below to begin the accreditation verification process. </span>
                                                            <div className="btn btn-link pt-1"><PassportButton showCancelButton={true} /></div> </>  }
                                                                    
                                                                    </td>
                                                            {/* // <div className={expiryDays( entity?.thirdPartyExpiry ) <= 7 ? 'text-danger font-bold' : 'text-green font-bold' } style={{textDecoration:'none'}}>Accreditation expires at {moment(entity?.thirdPartyExpiry).format("YYYY-MM-DD")} </div> 
                                                            // : entity?.thirdPartyExpiry && expiryDays( entity?.thirdPartyExpiry ) <= 0 ? <div className="btn-link font-bold"> Accreditation expired on {moment(entity?.thirdPartyExpiry).format("YYYY-MM-DD")} </div> :  */}

                                                            {/* accreditation */}
                                                        </tr>
                                                    </table>
                                                </div>
                                            ))}</div>
                                    </div>
                                </TabPanel>
                                <TabPanel   >
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Profile</h5>
                                        </div>
                                        <table style={{ marginTop: 10 }}>
                                            <div className="content box">
                                                <form className="mt-3">
                                                    <div className="col-12 col-md-12 col-sm-12" style={{ width: '100%' }}>
                                                        <div className="form-group mb-4 pb-2">
                                                            {/* <label htmlFor="FirstName">First Name *</label> */}
                                                            <input
                                                                type="text"
                                                                id="FirstName"
                                                                name="FirstName"
                                                                ref="FirstName"
                                                                value={FirstName}
                                                                onChange={this.myProfileInputchange}
                                                                placeholder="First Name *"
                                                                className="profile-form-control form-control"
                                                            />
                                                            <span id="error_FirstName" className="text-danger" />
                                                        </div>
                                                        <div className="form-group mb-4 pb-2">
                                                            {/* <label htmlFor="LastName">Last Name *</label> */}
                                                            <input
                                                                type="text"
                                                                id="LastName"
                                                                name="LastName"
                                                                ref="LastName"
                                                                value={LastName}
                                                                onChange={this.myProfileInputchange}
                                                                placeholder="Last Name *"
                                                                className="profile-form-control form-control"
                                                            />
                                                            <span id="error_LastName" className="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-sm-12" style={{ width: '100%' }}>
                                                        <div className="form-group mb-4 pb-2">
                                                            {/* <label htmlFor="Email">Email *</label> */}
                                                            <input
                                                                type="text"
                                                                id="Email"
                                                                name="Email"
                                                                ref="Email"
                                                                value={Email}
                                                                onChange={this.myProfileInputchange}
                                                                placeholder="Email *"
                                                                className="profile-form-control form-control"
                                                                title=""
                                                            />
                                                            <span id="error_Email" className="text-danger" />
                                                        </div>
                                                    </div>
                                                </form>
                                                <div>&nbsp;&nbsp;
                                                    {' '}
                                                    <button
                                                        onClick={this.updateNameEmail}
                                                        className="btn btn-primary border-0 py-3 px-5 btn-green"
                                                        type="button"

                                                    >Save
                                                    </button>
                                                </div>
                                            </div>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Bank Accounts</h5>
                                            <div className="ml-auto">
                                                <Link to="/app/setupprofile3" className='cursor-pointer font-bold'>
                                                    Add New Bank Account
                                                </Link></div>
                                        </div>
                                        <div className="content box p20" style={{ marginTop: 10 }}>
                                            {mergedFundingSourceWithEntities.length > 0 ? mergedFundingSourceWithEntities?.filter(entity => entity?.bankName)?.map((entity) => (
                                                <div className='table-wrapper'>
                                                    <h3>{entity.EntityName}</h3>
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td rowspan={3} className="img-wrapper"><img src={bankImg} /></td>
                                                                <td colspan={4}><h3>{entity.name}</h3></td>
                                                                <td rowspan={4} className="text-center">
                                                                    <span><Switch onChange={() => this.setDefault(entity)} checked={entity?.isDefault} disabled={entity.status?.toLowerCase() === 'unverified'} />
                                                                        <h4 className="mb-2 mt-0 font-bold">Default Bank</h4></span>
                                                                    <span className="cursor-pointer text-grey" onClick={() => this.removeFundingSource(entity)}>Remove Bank</span>
                                                                    {console.log("entity.status?.toLowerCase()", entity.status?.toLowerCase(), "entity", entity)}
                                                                </td>
                                                            </tr>
                                                                <tr>
                                                                    <td style={{verticalAlign:'baseline'}}>Entity Name</td>
                                                                    <td style={{verticalAlign:'baseline'}}>Bank Name</td>
                                                                    <td style={{verticalAlign:'baseline'}}>Account Number</td>
                                                                    <td style={{verticalAlign:'baseline'}}>Account Type</td></tr>
                                                                    <tr>
                                                                <td><span>{entity.EntityName}</span>
                                                                </td>
                                                                <td><span>{entity.bankName}</span>

                                                                </td>
                                                                <td ><span>{numberMaskify(entity?.AccountNumber)}</span>
                                                                    <HideComponent hide={entity.status?.toLowerCase() === 'verified'}>
                                                                        <Link to="/app/verifymicrodeposits" className='cursor-pointer font-bold'>
                                                                            Verify Micro Deposits
                                                                        </Link>
                                                                    </HideComponent>
                                                                </td>
                                                                <td><span>{entity.bankAccountType}</span>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            )) : <Link to="/app/setupprofile3" className='cursor-pointer font-bold'>
                                                Add New Bank Account
                                            </Link>}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">W-9</h5>
                                        </div>
                                        <div className="content" style={{ marginTop: 10 }}>
                                            <table className="table m-0">
                                                <tr>
                                                    <td><span className="text-grey">Entity</span></td>
                                                    <td><span className="text-grey">Status</span></td>
                                                </tr>
                                                {addbankAccountToEntities.map((entity) => (
                                                    <tr>
                                                        <td>{entity.EntityName}
                                                        </td>
                                                        <td>
                                                            {entity.w8n9formVerify === 0 ? (
                                                                <button className="btn btn-default btn-link font-bold text-red" onClick={() => this.onSelectEntity(entity.EntityID)}>
                                                                    Fill Out W9 Now
                                                                </button>
                                                            ) : (
                                                                <span className="text-grey">W9 Complete</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>


                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Security</h5>
                                        </div>
                                        <div className="content box" style={{ marginTop: 10 }}>
                                            <div className="col-xs-12 mt-3">
                                                <div className="form-group mb-4 pb-2">
                                                    <input
                                                        type="password"
                                                        id="oldpass"
                                                        name="oldpass"
                                                        ref="oldpass"
                                                        placeholder="Old Password *"
                                                        className="profile-form-control form-control"
                                                    />
                                                    <span id="error_oldpass" className="text-danger" />
                                                </div>
                                                <div className="form-group mb-4 pb-2">
                                                    <ReactPasswordStrength
                                                        className="signup-form-control form-group mb-0"
                                                        minLength={8}
                                                        minScore={1}
                                                        scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                                        tooShortWord="short"
                                                        changeCallback={this.onTypingPassword}
                                                        ref="newpass"
                                                        inputProps={{
                                                            name: 'newpass',
                                                            id: 'newpass',
                                                            autoComplete: 'off',
                                                            className: 'profile-form-control form-control',
                                                            placeholder: "New Password *"
                                                        }}
                                                        style={{ borderRadius: 0 }}
                                                    />
                                                    <span id="error_password" style={{ color: '#a94442' }} />
                                                </div>
                                                <div className="form-group mb-4 pb-2">
                                                    <input
                                                        type="password"
                                                        id="cnewpass"
                                                        name="cnewpass"
                                                        ref="cnewpass"
                                                        placeholder="Confirm New Password *"
                                                        className="profile-form-control form-control"
                                                    />
                                                    <span id="error_cnewpass" className="text-danger" />
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={this.changePassword}
                                                        className="btn btn-primary border-0 py-3 px-5 btn-green"
                                                        type="button"

                                                    >Save New Password
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-5">

                                                <h4 className="font-bold">Change MFA Settings</h4>

                                                <div className="col-xs-12" style={{ padding: 0 }}>
                                                    <div className="form-group">
                                                        <div className="check_tc" style={{ marginTop: 10 }}>
                                                            <label className="custom-container">Enable Google Authenticator {this.state.QRCode ? '(Enabled)' : '(Disabled)'}
                                                                <input type="checkbox" ref="GoogleAuthMFA" name="GoogleAuthMFA" id="GoogleAuthMFA"
                                                                    onClick={this.toggleGoogleAuthMFA}
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 form-group pb-3">
                                                        Please be sure you have the Microsoft Authenticator App (or Google Authenticator, Duo, or other similar <a href="https://en.wikipedia.org/wiki/Time-based_One-time_Password_algorithm" target="_blank" rel="noopener noreferrer">TOTP</a> App) before you enable MFA. If you need to get the app:
                                                    </div>

                                                    <div className="col-xs-12 form-group d-flex ">
                                                        <div className='p-2 pl-0'><a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US" target="_blank" rel="noopener noreferrer"><img src="/img/google-play.png" /></a></div>
                                                        <div className='p-2'>  <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer"><img src="/img/app-store.png" /></a></div>
                                                    </div>

                                                    {this.state.QRCode &&
                                                        <React.Fragment>
                                                            <img src={`${this.state.QRCode}`} />
                                                            <div className="col-xs-12 form-group">
                                                                Scan this QR Code with your selected Authenticator App.
                                                            </div>
                                                            {this.state.mfaPass && <div className="col-xs-12 form-group">
                                                                Manual Input Code (shown once only): {this.state.mfaPass}
                                                            </div>}

                                                            <div className="col-xs-12 col-sm-7">
                                                                Test Your Authenticator Code:
                                                                <input type="text" name="gamfaTest" id="gamfaTest" ref="gamfaTest"
                                                                    className="profile-form-control form-control"
                                                                />
                                                            </div>
                                                            <div className="col-xs-6 col-sm-2">
                                                                <br />
                                                                <button
                                                                    onClick={this.testGAMFA}
                                                                    className="btn btn-primary"
                                                                    type="button"
                                                                    style={{ backgroundColor: "#5bb462" }}
                                                                >Test
                                                                </button>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-3">
                                                                <br />
                                                                <br />
                                                                <span id="testGAMFAResults" ref="testGAMFAResults" />
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>


                                                {/* <Panel id="panel-pw-change" expanded={this.state.MFAChangePanel}>
										<Panel.Heading>
											<Panel.Title onClick={(e) => this.toggleMFAChangePanel(e)}>
												<strong>
												<span id="default_gp_lp_election_span text-size-20px">
													Change MFA Settings
												</span>
												</strong>
												<Panel.Toggle className="pull-right">
													<Icon iconName={this.state.MFAChangePanel ? 'chevron-up' : 'chevron-down'} />
												</Panel.Toggle>
											</Panel.Title>
											<Panel.Collapse>
												<Panel.Body style={{padding: 0}}>
													<div className="col-xs-12" style={{padding: 0}}>
														<div className="form-group">
															<div className="check_tc" style={{marginTop: 10}}>
																<label className="custom-container">Enable Google Authenticator {this.state.QRCode ? '(Enabled)' : '(Disabled)'}
																	<input type="checkbox" ref="GoogleAuthMFA" name="GoogleAuthMFA" id="GoogleAuthMFA"
																		   onClick={this.toggleGoogleAuthMFA}
																	/>
																	<span className="checkmark" />
																</label>
															</div>
														</div>
														<div className="col-xs-12 form-group">
															Please be sure you have the Microsoft Authenticator App (or Google Authenticator, Duo, or other similar <a href="https://en.wikipedia.org/wiki/Time-based_One-time_Password_algorithm" target="_blank" rel="noopener noreferrer">TOTP</a> App) before you enable MFA. If you need to get the app:
														</div>

														<div className="col-xs-12 form-group">
															<a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US" target="_blank" rel="noopener noreferrer"><img src="https://www.energyfunders.com/images/googleplay.png" width="153" style={{margin: 10, height: 'auto !important',
																maxWidth: '100% !important'}} /></a>
														</div>
														<div className="col-xs-12 form-group">
															<a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer"><img src="https://www.energyfunders.com/images/applestore.png" width="160" style={{margin: 10}} /></a>
														</div>
														{this.state.QRCode &&
															<React.Fragment>
																<img src={`${this.state.QRCode}`} />
																<div className="col-xs-12 form-group">
																	Scan this QR Code with your selected Authenticator App.
																</div>
																{this.state.mfaPass && <div className="col-xs-12 form-group">
																	Manual Input Code (shown once only): {this.state.mfaPass}
																</div>}

																<div className="col-xs-12 col-sm-7">
																	Test Your Authenticator Code:
																	<input type="text" name="gamfaTest" id="gamfaTest" ref="gamfaTest"
																		   className="profile-form-control form-control"
																	/>
																</div>
																<div className="col-xs-6 col-sm-2">
																	<br/>
																	<button
																		onClick={this.testGAMFA}
																		className="btn btn-primary"
																		type="button"
                                                                        style={{backgroundColor: "#5bb462"}}
																	>Test
																	</button>
																</div>
																<div className="col-xs-6 col-sm-3">
																	<br/>
																	<br/>
																	<span id="testGAMFAResults" ref="testGAMFAResults" />
																</div>
															</React.Fragment>
														}
													</div>
												</Panel.Body>
											</Panel.Collapse>
										</Panel.Heading>
									</Panel> */}
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="panel-content tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Entities</h5>
                                            <div className="ml-auto add-entity">
                                                {this.state.numEntities < 4 && (

                                                    <div className="pull-right">
                                                        <button
                                                            className="px-5 py-4 m-0 btn btn-default upper-case-text btn-green text-white"
                                                            onClick={this.verifyPersonalEntity}
                                                            type="button"
                                                        > Add Entity
                                                        </button>
                                                    </div>

                                                )}

                                            </div>
                                        </div>
                                        <div className="content box p20" style={{ marginTop: 10 }}>

                                        <table className="table m-0">
                                        <tr><td style={{verticalAlign:'baseline'}}>Entity Name</td>
                                                        <td style={{verticalAlign:'baseline'}}>Verified</td>
                                                        <td style={{verticalAlign:'baseline'}}></td>
                                                        </tr>
                                            {
                                                this.state.Entities.map((entity) => (
                                                    
                                                        <tr>
                                                            <td key={entity.EntityID}> {entity.EntityLabel}</td>
                                                            <td><span>
                                                                {entity.EntityStatus?.toString().toLowerCase() === "verified" ? "Yes" : entity.EntityStatus?.toString().toLowerCase() === "document" ? (<button
                                                                    className="btn btn-default btn-link font-bold"
                                                                    onClick={() => window.location = '/app/UploadDwollaDocument'}
                                                                    type="button"
                                                                >
                                                                    Upload Entity Verification File(s) (Required)
                                                                </button>) : (<button onClick={() =>
                                                                    this.toggleVerificationModal(entity)
                                                                } className="btn btn-default btn-link font-bold">
                                                                    Verify this Entity
                                                                </button>)}</span></td>                                                            
                                                            <td>
                                                                <button
                                                                    className="btn btn-default btn-link font-bold text-green"
                                                                    onClick={() => this.onEditEntity(entity)}
                                                                >
                                                                    Edit
                                                                </button>
                                                            </td>
                                                        </tr>
                                                ))
                                            }
                                                    </table>
                                            <Modal
                                                show={this.state.showEntityModal}
                                                style={{ width: '90%', margin: '0 auto' }}
                                            >
                                                <Modal.Header>
                                                    <Modal.Title>Investor General Partner (GP) Interests</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <form>
                                                        <div className="col-md-6 col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor="entityName">Entity Name *</label>
                                                                <input
                                                                    type="text"
                                                                    id="entityName"
                                                                    name="entityName"
                                                                    ref="EN"
                                                                    className="profile-form-control form-control"
                                                                    value={this.state.editEntity.EN}
                                                                    onChange={(e) => this.onEditInputchange(e)}

                                                                    disabled
                                                                />
                                                                <span id="error_entityName" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="ENType">Entity Type *</label>
                                                                <input
                                                                    type="text"
                                                                    id="ENType"
                                                                    name="ENType"
                                                                    ref="ENType"
                                                                    value={this.state.editEntity.ENType}
                                                                    className="profile-form-control form-control"
                                                                    disabled
                                                                />
                                                                <span id="error_ENType" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="legalFN">Legal First Name *</label>
                                                                <input
                                                                    type="text"
                                                                    id="legalFN"
                                                                    name="legalFN"
                                                                    ref="legalFN"
                                                                    value={this.state.editEntity.legalFN}
                                                                    className="profile-form-control form-control"
                                                                    disabled
                                                                />
                                                                <span id="error_legalFN" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="legalLN">Legal Last Name *</label>
                                                                <input
                                                                    type="text"
                                                                    id="legalLN"
                                                                    name="legalLN"
                                                                    ref="legalLN"
                                                                    value={this.state.editEntity.legalLN}
                                                                    className="profile-form-control form-control"
                                                                    disabled
                                                                />
                                                                <span id="error_legalLN" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="mobilePhone">Mobile Phone Number *</label>
                                                                <input
                                                                    type="text"
                                                                    id="mobilePhone"
                                                                    name="mobilePhone"
                                                                    ref="PH"
                                                                    value={this.state.editEntity.PH}
                                                                    className="profile-form-control form-control"
                                                                />
                                                                <span id="error_mobilePhone" className="text-danger" />
                                                            </div>

                                                            <br />
                                                        </div>

                                                        <div className="col-md-6 col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor="ADD">
                                                                    Physical Street Address (Sorry, no PO Box allowed) *
                                                                </label>
                                                                <input
                                                                    id="ADD"
                                                                    name="ADD"
                                                                    className="profile-form-control form-control"
                                                                    type="text"
                                                                    ref="ADD"
                                                                    value={this.state.editEntity.ADD}
                                                                    onChange={(e) => this.onEditInputchange(e)}
                                                                />
                                                                <span id="error_nwells" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="CI">City *</label>
                                                                <input
                                                                    id="CI"
                                                                    name="CI"
                                                                    className="profile-form-control form-control"
                                                                    type="text"
                                                                    ref="CI"
                                                                    value={this.state.editEntity.CI}
                                                                    onChange={(e) => this.onEditInputchange(e)}
                                                                />
                                                                <span id="error_avgnr" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="ST">State *</label>
                                                                <input
                                                                    type="text"
                                                                    id="ST"
                                                                    name="ST"
                                                                    ref="ST"
                                                                    value={this.state.editEntity.ST}
                                                                    onChange={(e) => this.onEditInputchange(e)}
                                                                    className="profile-form-control form-control"
                                                                />
                                                                <span id="error_wi" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="ZP">ZipCode *</label>
                                                                <input
                                                                    id="ZP"
                                                                    name="ZP"
                                                                    className="profile-form-control form-control"
                                                                    ref="ZP"
                                                                    type="text"
                                                                    value={this.state.editEntity.ZP}
                                                                    onChange={(e) => this.onEditInputchange(e)}
                                                                />
                                                                <span id="error_totalpp" className="text-danger" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="avgnr">Country *</label>
                                                                <input
                                                                    id="CO"
                                                                    name="CO"
                                                                    className="profile-form-control form-control"
                                                                    type="text"
                                                                    ref="CO"
                                                                    value={this.state.editEntity.CO}
                                                                    onChange={(e) => this.onEditInputchange(e)}
                                                                />
                                                                <span id="error_avgnr" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </form>

                                                    {/* <div className="form-group">
                                    <label className="col-md-3 control-label">Default Partnership Election</label>
                                    <div className="col-md-9">
                                        <div className="input-group">
                                            <div>
                                                This is the default partnership election used for investing in projects.
                                                <br/>
                                                Your current default election is as a:
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                        fontWeight: 'bold',
                                                    }}
                                                    id="GP_LP_Div"
                                                >
                                                    {(this.state.default_gp_lp_election === 2) ? ' Limited Partner' : ' General Partner'}
                                                </div>
                                                <br/>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ margin: '0 10px 10px 0',backgroundColor: "#5bb462" }}
                                                    onClick={() => this.showGPLPModal('showGPModal')}
                                                >Click here to learn more about GP
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ margin: '0 10px 10px 0',backgroundColor: "#5bb462" }}
                                                    onClick={() => this.showGPLPModal('showLPModal')}
                                                >Click here to learn more about LP
                                                </button>
                                                <br/>

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                                    <div className="form-group col-md-2 col-sm-2">
                                                        <div className="upload-btn-wrapper">
                                                            <button
                                                                type="Submit"
                                                                id="submit"
                                                                onClick={this.onUpdateEntity}
                                                                className="btn btn-primary"
                                                                style={{ margin: '30px 0px 0px', backgroundColor: "#5bb462" }}
                                                            >Save Changes
                                                            </button>
                                                            <br />
                                                        </div>

                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <div
                                                        id="closeText_GP"
                                                        style={{
                                                            float: 'left',
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Click "Close" to close this window without making a selection.
                                                    </div>
                                                    <Button onClick={() => this.setState({ showEntityModal: false })}>Close</Button>

                                                </Modal.Footer>
                                            </Modal>


                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="panel-content tabWidth">

                                        <div className="header">
                                            <h5 className="pull-left m-0">Communication</h5>
                                        </div>
                                        <div className="content box col-10" style={{ marginTop: 10 }}>
                                            <div className="col-xs-12 mt-3">
                                                <form className="mt-3">
                                                    <div className="form-group">
                                                        <CheckboxInputComponent
                                                            checkTitle=" SMS  Yes, please add me to your text messaging list. (No more than 5 text messages per month; msg rates may apply; opt-out at any time)"
                                                            onClick={() => this.setContactPref('ContactPrefSMS', !this.state.ContactPrefSMS)}
                                                            checked={this.state.ContactPrefSMS}
                                                            name="ContactPrefSMS"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <CheckboxInputComponent
                                                            checkTitle=" Email Yes, please add me to your email newsletter."
                                                            onClick={() => this.setContactPref('ContactPrefEmail', !this.state.ContactPrefEmail)}
                                                            checked={this.state.ContactPrefEmail}
                                                            name="ContactPrefEmail"
                                                        />
                                                    </div>
                                                </form>
                                                <div>

                                                    <button
                                                        onClick={this.updateCommunication}
                                                        className="btn btn-primary border-0 py-3 px-5 mt-3 btn-green"
                                                        type="button"

                                                    >Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="panel-content bitcoin-wallet tabWidth">
                                        <div className="header">
                                            <h5 className="pull-left m-0">Bitcoin Wallet</h5>
                                        </div>
                                        <div className="content">
                                            <BitcoinWallet addbankAccountToEntities={addbankAccountToEntities} />

                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>


                    </div>
                    <div className="col-xs-12 col-md-12">





                    </div>

                    {/* <div className="col-md-12">
                      
                        <div className="panel panel-primary" style={{ margin: 20 }}>
                            <div className="panel-heading usernavmenu">
                                <h3 className="panel-title">Edit your Profile</h3>
                            </div>
                            <div className="panel-body">
                                <form>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="FirstName">First Name *</label>
                                            <input
                                                type="text"
                                                id="FirstName"
                                                name="FirstName"
                                                ref="FirstName"
                                                className="profile-form-control form-control"
                                            />
                                            <span id="error_FirstName" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="LastName">Last Name *</label>
                                            <input
                                                type="text"
                                                id="LastName"
                                                name="LastName"
                                                ref="LastName"
                                                className="profile-form-control form-control"
                                            />
                                            <span id="error_LastName" className="text-danger"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="Email">Email *</label>
                                            <input
                                                type="text"
                                                id="Email"
                                                name="Email"
                                                ref="Email"
                                                className="profile-form-control form-control"
                                                title=""
                                            />
                                            <span id="error_Email" className="text-danger"/>
                                        </div>
                                    </div>
                                </form>
                                <div>&nbsp;&nbsp;
                                    {' '}
                                    <button
                                        onClick={this.updateNameEmail}
                                        className="btn btn-primary"
                                        type="button"
                                        style={{backgroundColor: "#5bb462"}}
                                    >Save Name/Email Changes
                                    </button>
                                </div>
                                <br/>

								<div className="col-md-6 col-sm-6 col-xs-12">
									<Panel id="panel-pw-change" expanded={this.state.PWChangePanel}>
									<Panel.Heading>
										<Panel.Title onClick={this.togglePWChangePanel}>
											<strong>
												<span id="default_gp_lp_election_span text-size-20px">
													Change Password
												</span>
											</strong>
											<Panel.Toggle className="pull-right">
												<Icon iconName={this.state.open ? 'chevron-up' : 'chevron-down'} />
											</Panel.Toggle>
										</Panel.Title>
										<Panel.Collapse>
											<Panel.Body>
												<div className="col-xs-12">
													<div className="form-group">
														<label htmlFor="oldpass">Old Password *</label>
														<input
															type="password"
															id="oldpass"
															name="oldpass"
															ref="oldpass"
															className="profile-form-control form-control"
														/>
														<span id="error_oldpass" className="text-danger"/>
													</div>
													<div className="form-group">
														<label htmlFor="password">New Password *</label>
														<ReactPasswordStrength
															className="signup-form-control form-group"
															minLength={8}
															minScore={1}
															scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
															tooShortWord="short"
															changeCallback={this.onTypingPassword}
															ref="newpass"
															inputProps={{
																name: 'newpass',
																id: 'newpass',
																autoComplete: 'off',
																className: 'profile-form-control form-control',
															}}
															style={{ borderRadius: 0 }}
														/>
														<span id="error_password" style={{ color: '#a94442' }}/>
													</div>
													<div className="form-group">
														<label htmlFor="cnewpass">Confirm New Password *</label>
														<input
															type="password"
															id="cnewpass"
															name="cnewpass"
															ref="cnewpass"
															className="profile-form-control form-control"
														/>
														<span id="error_cnewpass" className="text-danger"/>
													</div>
													<div>
														<button
															onClick={this.changePassword}
															className="btn btn-primary"
															type="button"
                                                            style={{backgroundColor: "#5bb462"}}
														>Save New Password
														</button>
													</div>
												</div>
											</Panel.Body>
										</Panel.Collapse>
									</Panel.Heading>
								</Panel>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<Panel id="panel-pw-change" expanded={this.state.MFAChangePanel}>
										<Panel.Heading>
											<Panel.Title onClick={this.toggleMFAChangePanel}>
												<strong>
												<span id="default_gp_lp_election_span text-size-20px">
													Change MFA Settings
												</span>
												</strong>
												<Panel.Toggle className="pull-right">
													<Icon iconName={this.state.MFAChangePanel ? 'chevron-up' : 'chevron-down'} />
												</Panel.Toggle>
											</Panel.Title>
											<Panel.Collapse>
												<Panel.Body style={{padding: 0}}>
													<div className="col-xs-12" style={{padding: 0}}>
														<div className="form-group">
															<div className="check_tc" style={{marginTop: 10}}>
																<label className="custom-container">Enable Google Authenticator {this.state.QRCode ? '(Enabled)' : '(Disabled)'}
																	<input type="checkbox" ref="GoogleAuthMFA" name="GoogleAuthMFA" id="GoogleAuthMFA"
																		   onClick={this.toggleGoogleAuthMFA}
																	/>
																	<span className="checkmark" />
																</label>
															</div>
														</div>
														<div className="col-xs-12 form-group">
															Please be sure you have the Microsoft Authenticator App (or Google Authenticator, Duo, or other similar <a href="https://en.wikipedia.org/wiki/Time-based_One-time_Password_algorithm" target="_blank" rel="noopener noreferrer">TOTP</a> App) before you enable MFA. If you need to get the app:
														</div>

														<div className="col-xs-12 form-group">
															<a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US" target="_blank" rel="noopener noreferrer"><img src="https://www.energyfunders.com/images/googleplay.png" width="153" style={{margin: 10, height: 'auto !important',
																maxWidth: '100% !important'}} /></a>
														</div>
														<div className="col-xs-12 form-group">
															<a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer"><img src="https://www.energyfunders.com/images/applestore.png" width="160" style={{margin: 10}} /></a>
														</div>
														{this.state.QRCode &&
															<React.Fragment>
																<img src={`${this.state.QRCode}`} />
																<div className="col-xs-12 form-group">
																	Scan this QR Code with your selected Authenticator App.
																</div>
																{this.state.mfaPass && <div className="col-xs-12 form-group">
																	Manual Input Code (shown once only): {this.state.mfaPass}
																</div>}

																<div className="col-xs-12 col-sm-7">
																	Test Your Authenticator Code:
																	<input type="text" name="gamfaTest" id="gamfaTest" ref="gamfaTest"
																		   className="profile-form-control form-control"
																	/>
																</div>
																<div className="col-xs-6 col-sm-2">
																	<br/>
																	<button
																		onClick={this.testGAMFA}
																		className="btn btn-primary"
																		type="button"
                                                                        style={{backgroundColor: "#5bb462"}}
																	>Test
																	</button>
																</div>
																<div className="col-xs-6 col-sm-3">
																	<br/>
																	<br/>
																	<span id="testGAMFAResults" ref="testGAMFAResults" />
																</div>
															</React.Fragment>
														}
													</div>
												</Panel.Body>
											</Panel.Collapse>
										</Panel.Heading>
									</Panel>
								</div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-md-12">
                        <div className="panel panel-primary" style={{ margin: 20 }}>

                            <div className="panel-heading usernavmenu">
                                <h3 className="panel-title">Edit Your Entities</h3>
                            </div>

                            <div className="panel-body">

                                {this.state.numEntities < 4 && ( 
                                    <div className="col-xs-12">
                                        <div className="pull-right">
                                            <button
                                                className="btn btn-default upper-case-text"
                                                onClick={this.verifyPersonalEntity}
                                                type="button"
                                            >
                                                <Icon iconName="plus"/>
                                                {' '}
                                                Create New Entity
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <label htmlFor="status">Select An Entity to Edit</label>
                                <select
                                    className="profile-form-control form-group"
                                    id="status"
                                    ref="pro"
                                    onChange={()=>this.onSelectEntity(this.refs.pro.value)}
                                    name="status"
                                >
                                    <option value={0}>Select An Entity to Edit</option>
                                    {
                                        this.state.Entities.map(entity => (
                                            <option
                                                key={entity.EntityID}
                                                value={entity.EntityID}
												disabled={(entity.IsDisabled ? entity.IsDisabled : '')}
                                            >
                                                {entity.EntityLabel}
                                            </option>
                                        ))
                                    }
                                </select>
                                <HideComponent hide={showAddVerificationToEntity || !isAccredited}>
                                    <div className="col-xs-12">
                                        <div className="pull-left">
                                            <button
                                                className="btn btn-success"
                                                onClick={() => this.toggleVerificationModal(selectedEntityType)}
                                                type="button"
                                            >
                                                Verify This Entity (Required)
                                            </button>
                                        </div>
                                    </div>
                                </HideComponent>
                                <HideComponent hide={selectedEntityW9Status}>
                                    <div className="col-xs-12">
                                        <div className="pull-left">
                                            <button
                                                className="btn btn-success"
                                                onClick={this.signW9}
                                                type="button"
                                            >
                                                Complete W9 (Required)
                                            </button>
                                        </div>
                                    </div>
                                </HideComponent>

                               

                                <HideComponent hide={!showAddVerificationFileToEntity}>
                                    <div className="col-xs-12">
                                        <div className="pull-left">
                                            <button
                                                className="btn btn-primary capitalize-text"
                                                style={{backgroundColor: "#5bb462"}}
                                                // onClick={this.getDwollaFileVerificationData}
                                                onClick={() => window.location = '/app/UploadDwollaDocument'}
                                                type="button"
                                            >
                                                Upload Entity Verification File(s) (Required)
                                            </button>
                                        </div>
                                    </div>
                                </HideComponent>

                                <div className="col-xs-12">
                                    <hr/>
                                </div>

                                <form>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="entityName">Entity Name *</label>
                                            <input
                                                type="text"
                                                id="entityName"
                                                name="entityName"
                                                ref="EN"
                                                className="profile-form-control form-control"
                                                disabled
                                            />
                                            <span id="error_entityName" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="ENType">Entity Type *</label>
                                            <input
                                                type="text"
                                                id="ENType"
                                                name="ENType"
                                                ref="ENType"
                                                className="profile-form-control form-control"
                                                disabled
                                            />
                                            <span id="error_ENType" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="legalFN">Legal First Name *</label>
                                            <input
                                                type="text"
                                                id="legalFN"
                                                name="legalFN"
                                                ref="legalFN"
                                                className="profile-form-control form-control"
                                                disabled
                                            />
                                            <span id="error_legalFN" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="legalLN">Legal Last Name *</label>
                                            <input
                                                type="text"
                                                id="legalLN"
                                                name="legalLN"
                                                ref="legalLN"
                                                className="profile-form-control form-control"
                                                disabled
                                            />
                                            <span id="error_legalLN" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="mobilePhone">Mobile Phone Number *</label>
                                            <input
                                                type="text"
                                                id="mobilePhone"
                                                name="mobilePhone"
                                                ref="PH"
                                                className="profile-form-control form-control"
                                            />
                                            <span id="error_mobilePhone" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <CheckboxInputComponent
                                                checkTitle=" I want to receive SMS Messages (optional)"
                                                onClick={() => this.setContactPref('ContactPrefSMS', !this.state.ContactPrefSMS)}
                                                checked={this.state.ContactPrefSMS}
                                                name="ContactPrefSMS"
                                            />
                                        </div>
                                        <br/>
                                    </div>

                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nwells">
                                                Physical Street Address (Sorry, no PO Box allowed) *
                                            </label>
                                            <input
                                                id="nwells"
                                                name="nwells"
                                                className="profile-form-control form-control"
                                                type="text"
                                                ref="ADD"
                                            />
                                            <span id="error_nwells" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avgnr">City *</label>
                                            <input
                                                id="avgnr"
                                                name="avgnr"
                                                className="profile-form-control form-control"
                                                type="text"
                                                ref="CI"
                                            />
                                            <span id="error_avgnr" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="wi">State *</label>
                                            <input
                                                type="text"
                                                id="wi"
                                                name="wi"
                                                ref="ST"
                                                className="profile-form-control form-control"
                                            />
                                            <span id="error_wi" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="totalpp">ZipCode *</label>
                                            <input
                                                id="totalpp"
                                                name="totalpp"
                                                className="profile-form-control form-control"
                                                ref="ZP"
                                                type="text"
                                            />
                                            <span id="error_totalpp" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avgnr">Country *</label>
                                            <input
                                                id="avgnr"
                                                name="avgnr"
                                                className="profile-form-control form-control"
                                                type="text"
                                                ref="CO"
                                            />
                                            <span id="error_avgnr" className="text-danger"/>
                                        </div>
                                    </div>
                                </form>

                                <div className="form-group">
                                    <label className="col-md-3 control-label">Default Partnership Election</label>
                                    <div className="col-md-9">
                                        <div className="input-group">
                                            <div>
                                                This is the default partnership election used for investing in projects.
                                                <br/>
                                                Your current default election is as a:
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                        fontWeight: 'bold',
                                                    }}
                                                    id="GP_LP_Div"
                                                >
                                                    {(this.state.default_gp_lp_election === 2) ? ' Limited Partner' : ' General Partner'}
                                                </div>
                                                <br/>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ margin: '0 10px 10px 0',backgroundColor: "#5bb462" }}
                                                    onClick={() => this.showGPLPModal('showGPModal')}
                                                >Click here to learn more about GP
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ margin: '0 10px 10px 0',backgroundColor: "#5bb462" }}
                                                    onClick={() => this.showGPLPModal('showLPModal')}
                                                >Click here to learn more about LP
                                                </button>
                                                <br/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-2 col-sm-2">
                                    <div className="upload-btn-wrapper">
                                        <button
                                            type="Submit"
                                            id="submit"
                                            onClick={this.onUpdateEntity}
                                            className="btn btn-primary"
                                            style={{ margin: '10px 0',backgroundColor: "#5bb462" }}
                                        >Save Changes
                                        </button>
                                        <br/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
                <NewEntityContainerComponent
                    isModalOpen={isNewEntityModalOpen}
                    onCloseModal={this.toggleNewEntityModal}
                    parentUpdateMethod={this.getInvestorEntities}
                    legalFN={this.state.legalFN}
                    legalLN={this.state.legalLN}
                />

                <NewEntityContainerComponent
                    stepNumber={3}
                    entityType={selectedEntityType}
                    createdEntityId={selectedEntityId}
                    isModalOpen={isVerifyEntityModalOpen}
                    onCloseModal={() => this.toggleVerificationModal(null)}
                    parentUpdateMethod={this.getInvestorEntities}
                    isVerification
                    dwollaStatus={selectedEntityStatus}
                    legalFN={this.state.legalFN}
                    legalLN={this.state.legalLN}
                    entityDetails={this.state?.entityDetails}
                />

                <NewEntityContainerComponent
                    stepNumber={4}
                    entityType={selectedEntityType}
                    verifyBusiness={verifyBusiness}
                    createdEntityId={selectedEntityId}
                    verifyController={verifyController}
                    isModalOpen={isFileVerificationModalOpen}
                    parentUpdateMethod={this.getInvestorEntities}
                    onCloseModal={this.closeDwollaFileVerificationModal}
                    isVerification
                />






                <Modal
                    show={isEntityVerificationModalOpen}
                    onHide={this.toggleEntityVerificationModal}
                    style={{ width: '90%', margin: '0 auto' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome to EnergyFunders</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <p>Before you can create a new entity, you need to complete the following steps:</p>
                                <br />
                                <div className="col-md-2 nopadding imghide" style={{ paddingLeft: 0 }} />
                                <div
                                    className="col-md-4"
                                    style={{
                                        padding: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    <p>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            <span className="badge" style={{ fontSize: 40 }}>1</span>
                                        </a>
                                    </p>
                                    <p style={{ fontSize: 24 }}>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            Verify Identity
                                        </a>
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        This information helps us satisfy "Know Your Customer" (KYC) requirements.
                                    </p>
                                </div>
                                <div
                                    className="col-md-4"
                                    style={{
                                        padding: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    <p>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            <span className="badge" style={{ fontSize: 40 }}>2</span>
                                        </a>
                                    </p>
                                    <p style={{ fontSize: 24 }}>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            Link Bank Account
                                        </a>
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Linking your bank account allows you to use ACH to fund your investments and
                                        receive any distributions on the EnergyFunders platform.
                                    </p>
                                </div>
                                <div className="col-md-2 nopadding imghide" style={{ paddingLeft: 0 }} />
                            </div>
                            <hr />
                            <div style={{ textAlign: 'center' }}>
                                <input
                                    type="submit"
                                    className="btn btn-info"
                                    defaultValue="Set Up Account"
                                    value="Set Up Account"
                                    style={{
                                        width: 300,
                                        backgroundColor: '#5bb462',
                                    }}
                                    onClick={this.goToVerifyDwolla}
                                />
                                <br />
                                <br />
                                <a
                                    href="javascript:"
                                    onClick={() => this.toggleEntityVerificationModal()}
                                >
                                    Finish Later
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showGPModal}
                    style={{ width: '90%', margin: '0 auto' }}
                >
                    <Modal.Header>
                        <Modal.Title>Investor General Partner (GP) Interests</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <p>
                                <strong><em>• Tax Consequences.</em></strong>
                                {' '}
                                Generally, if you invest as an
                                {' '}
                                <strong>Investor General Partner (GP)</strong>
                                , then your share of the partnership's deduction for
                                {' '}
                                <a
                                    href="javascript:void(0);"
                                    className="popovers"
                                    data-trigger="hover"
                                    data-placement="bottom"
                                    data-original-title="Intangible Drilling Costs"
                                    data-content="Definition: 'Intangible drilling costs', generally, means those costs of drilling and completing a well that are currently deductible, as compared to lease costs, which must be recovered through the depletion allowance, and costs for equipment in the well, which must be recovered through depreciation deductions.  For example, intangible drilling costs include all expenditures made for any well before production in commercial quantities for wages, fuel, repairs, hauling, supplies and other costs and expenses incident to and necessary for drilling the well and preparing the well for production of oil or natural gas.  Intangible drilling costs also include the expense of plugging and abandoning any well before a completion attempt, and the costs (other than non-deductible equipment costs and lease acquisition costs) to re-enter and deepen an existing well, complete the well to deeper reservoirs, or plug and abandon the well if it is non-productive from the targeted deeper reservoirs.  See the materials provided for more information."
                                >
                                    intangible
                                    drilling costs
                                </a>
                                {' '}
                                will not be subject to the passive activity limitations on losses. You may
                                claim a deduction in an amount equal to not less than the percentage of your
                                net subscription amount used to pay for intangible drilling costs for all of
                                the wells to be drilled by the partnership in that taxable year. Please
                                consult your tax professional to determine how this applies to your
                                particular tax situation.
                            </p>
                            <p>
                                <strong><em>• Unlimited Liability.</em></strong>
                                {' '}
                                If you invest as an Investor General Partner, you will have unlimited
                                liability regarding the partnership's activities. This means that if (i) the
                                partnership's insurance proceeds from any source, (ii) the Managing GP's
                                indemnification of the Investor General Partners, and (iii) the
                                partnership's assets were, collectively, not sufficient to satisfy a
                                partnership liability for which the Investor General Partners were also
                                liable solely because of your status as general partners of the partnership,
                                then the Managing GP would require the Investor General Partners to make
                                additional capital contributions to the partnership to satisfy the
                                liability. In addition, the Investor General Partners will have joint and
                                several liability, which means, generally, that a person with a claim
                                against the partnership and/or an Investor General Partner may sue all or
                                any one or more of the partnership's general partners, including you, for
                                the entire amount of the liability.
                            </p>
                            <p>
                                <strong><em>• Assessability.</em></strong>
                                {' '}
                                You will be able to determine if your Interests are subject to
                                {' '}
                                <a
                                    href="javascript:void(0);"
                                    className="popovers"
                                    data-trigger="hover"
                                    data-placement="bottom"
                                    data-original-title="Assessability"
                                    data-content="Definition: 'Assessability', generally, means that levies may be imposed on the partnership interest held to contribute more funds.  Thus, an assessable partnership interest can be required to contribute more funds, while a non-assessable partnership interest cannot be required to contribute more funds.  The Investor General Partner Interests are assessable but become non-assessable once they convert to Investor Limited Partner Interests.  However, if any obligations or liabilities were assessed on the Investor General Partner Interests before the effective date of conversion (or such obligations or liabilities were incurred but not assessed before conversion), such assessments or obligations will remain."
                                >
                                    assessability
                                </a>
                                {' '}
                                based on whether you buy Investor General Partner Interests, which are
                                assessable, or Limited Partner Interests, which are non-assessable.
                            </p>
                            <p>
                                <strong><em>• Conversion Timeline.</em></strong>
                                {' '}
                                Your Investor General Partner Interests will be automatically converted by
                                the Managing GP to Limited Partner Interests upon the occurrence of the
                                earlier of (i) the drilling and completion of all of the partnership's
                                wells, as determined by the Managing GP or its professionals, or (ii) the
                                date that no additional currently deductible intangible drilling costs will
                                be realized by the partnership's Investor General Partners, as determined by
                                the Managing GP. In this regard, a well is deemed to be completed when
                                production equipment is installed on a well, even though the well may not
                                yet be connected to a pipeline for production of oil or natural gas. The
                                timeline for such conversion depends on the timing and amount of the sale of
                                the Interests as well as the availability of appropriate Projects being
                                sourced by the partnership's operators. The partnership will generally
                                invest in Projects at the time leases are acquired through the completion of
                                the wells. Once all of the wells within all of the partnership's Projects
                                are completed, the Investor General Partner Interests will then be converted
                                to Limited Partner Interests. If the offering raises the maximum offering
                                amount, the partnership will be able to drill more wells and the larger
                                number of wells would be expected to take longer to drill. If the offering
                                raises less than the maximum offering amount, the number of wells that may
                                be drilled will be less and, therefore, drilling would be expected to be
                                completed sooner. The conversion is not expected to create any tax liability
                                to the investors. We are not tax advisors, please consult your tax advisor
                                regarding your particular tax situation.
                            </p>
                            <p>
                                <strong><em>• Effect of Conversion on Liability.</em></strong>
                                {' '}
                                Once your Interests are converted, you will have the limited liability of a
                                limited partner under Texas law for partnership obligations and liabilities
                                arising after the conversion. However, you will continue to have the
                                responsibilities of a general partner for partnership liabilities and
                                obligations incurred before the effective date of the conversion. For
                                example, you might become liable for partnership liabilities in excess of
                                your subscription amount during the time the partnership is engaged in
                                drilling activities and for environmental claims that arose during drilling
                                activities, but were not discovered until after the conversion.
                            </p>
                            <br />
                            <div style={{
                                display: 'inline-block',
                                fontWeight: 'bold',
                            }}
                            >
                                <input type="checkbox" name="confirm_GP" id="confirm_GP" defaultValue={1} />
                                {' '}
                                <label htmlFor="confirm_GP">
                                    I certify that I understand 'Investor General
                                    Partner (GP) Interests'.
                                </label>
                            </div>
                            <br />
                            <br />
                            <div>
                                <button type="button" className="btn btn-primary" onClick={this.onSelectGP}
                                    style={{ backgroundColor: "#5bb462" }}>
                                    Set
                                    Default to General Partner
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            id="closeText_GP"
                            style={{
                                float: 'left',
                                marginTop: 8,
                            }}
                        >
                            Click "Close" to close this window without making a selection.
                        </div>
                        <Button onClick={() => this.setState({ showGPModal: false })}>Close</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showLPModal}
                    style={{ width: '90%', margin: '0 auto' }}
                >
                    <Modal.Header>
                        <Modal.Title>Investor Limited Partner (LP) Interests</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>
                                <strong><em>• Tax Consequences.</em></strong>
                                {' '}
                                If you invest as an
                                {' '}
                                <strong>Investor Limited Partner (LP)</strong>
                                , then your use of your share of the partnership's deduction for intangible
                                drilling costs will be limited to offsetting your net passive income from
                                {' '}
                                <a
                                    href="javascript:void(0);"
                                    className="popovers"
                                    data-trigger="hover"
                                    data-placement="bottom"
                                    data-original-title="'Passive Trade or Business Activities'"
                                    data-content="Definition: 'Passive trade or business activities' generally include the partnership and other limited partner investments, but passive income does not include salaries, dividends or interest.  This means that you will not be able to deduct your share of the partnership's intangible drilling costs in the year in which you invest, unless you have net passive income.  However, any portion of your share of the partnership's deduction for intangible drilling costs that you cannot use in the year in which you invest, because you do not have sufficient net passive income in that year, may becarried forward indefinitely until you can use it to offset your net passive income from the partnership or your other passive activities, if any, in subsequent tax years. See the materials provided for more information."
                                >
                                    "passive" trade or business activities
                                </a>
                                .
                                Please consult your tax professional to determine how this applies to your
                                particular tax situation.
                            </p>
                            <p>
                                <strong><em>• Limited Liability.</em></strong>
                                {' '}
                                If you invest as a Limited Partner, then you will have limited liability for
                                the partnership's liabilities and obligations. This means that you will not
                                be liable for any partnership liabilities or obligations beyond the amount
                                of your subscription amount in the partnership and your share of the
                                partnership's undistributed net profits, subject to certain exceptions set
                                forth in the materials provided.
                            </p>
                            <br />
                            <div style={{
                                display: 'inline-block',
                                fontWeight: 'bold',
                            }}
                            >
                                <input type="checkbox" name="confirm_LP" id="confirm_LP" defaultValue={1} />
                                {' '}
                                <label htmlFor="confirm_LP">
                                    I certify that I understand 'Investor Limited
                                    Partner (LP) Interests'.
                                </label>
                            </div>
                            <div
                                id="confirm_LP_error"
                                className="alert-danger"
                                style={{ display: 'none' }}
                            />
                            <br />
                            <br />
                            <div>
                                <button type="button" className="btn btn-primary" onClick={this.onSelectLP}
                                    style={{ backgroundColor: "#5bb462" }}>
                                    Set
                                    Default to Limited Partner
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            id="closeText_GP"
                            style={{
                                float: 'left',
                                marginTop: 8,
                            }}
                        >
                            Click "Close" to close this window without making a selection.
                        </div>
                        <Button onClick={() => this.setState({ showLPModal: false })}>Close</Button>

                    </Modal.Footer>
                </Modal>

            </div>
        );
    };
}


Profile.propTypes = {
    history: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
};

Profile.defaultProps = {
    history: { push: () => null },
    session: {},
};

const mapState = ({ session }) => ({
    session: session.user,
});

export default withRouter(withParallelMarketsHOC(connect(mapState)(Profile)));
