import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Icon from '../IconComponent/IconComponent';
import FileDetailsEditorComponent from '../FileDetailsEditorComponent/FileDetailsEditorComponent';
import {
  PROJECT_REG_CF,
  PROJECT_REG_D,
} from '../../../utils/ApplicationConstants';
import InvestorDetailsViewerModal from '../../admin/InvestorContainer/InvestorDetailsModalComponent/InvestorDetailsViewerModal';
import { getUUID } from '../../../utils/General';
import Documents from './Documents';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ContractDocuments from './ContractDocuments';
import moment from 'moment';
class InvestorFileViewerComponent extends React.Component {
  ITEMS_PER_PAGE = 5;

  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      fileToEdit: {},
      userId: 0,
      userDetailsModal: null,
      activePage: 1,
      pageItems: [],
      width: window.innerWidth,
      height: window.innerHeight,
      showCompletedDoc: false,
      contractFilesList: [],
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    const { setResetMethod, contractFilesList } = this.props;
    const { activePage } = this.state;

    this.onPageChange(activePage);
    setResetMethod(this.onPageChange);
    window.addEventListener('resize', this.updateDimensions);
    this.setState({ contractFilesList: contractFilesList });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    const { activePage } = this.state;
    const { projectFileList, contractFilesList } = this.props;
    const strProjectFileList = JSON.stringify(projectFileList);
    const _projectFileList = JSON.stringify(prevProps.projectFileList);
    const strcontractFilesList = JSON.stringify(contractFilesList);
    const _contractFilesList = JSON.stringify(prevProps.contractFilesList);

    if (strcontractFilesList !== _contractFilesList) {
      this.setState({ contractFilesList: contractFilesList });
    }

    if (strProjectFileList !== _projectFileList) {
      this.onPageChange(activePage);
    }
  }

  onPageChange = (activePage) => {
    const { projectFileList } = this.props;
    const start = (activePage - 1) * this.ITEMS_PER_PAGE;
    const end = start + this.ITEMS_PER_PAGE;
    //Filter by Tax Documents
    const slice = projectFileList
      .filter((doc) => doc?.docTypeId == 1)
      ?.slice(start, end);
    this.setState({
      pageItems: slice,
      activePage,
    });
  };

  onUpdateStoreFiles = (fileToEdit, userId) => {
    this.setState({
      userId,
      fileToEdit,
      isEditing: true,
    });
  };

  onCancelStoredFilesUpdate = () => {
    this.setState({
      isEditing: false,
      fileToEdit: {},
    });
  };
  contractFilterOnChange = (e) => {
    if (!this.state.showCompletedDoc) {
      const expiryDays = (date) => {
        let date_string = date?.toString();
        var expiration = moment(date_string).format('YYYY-MM-DD');
        var current_date = moment().format('YYYY-MM-DD');
        var days = moment(expiration).diff(current_date, 'days');
        console.log('remaining days', days);
        return days;
      };
      let data = this.state.contractFilesList.filter(
        (f) =>
          f.is_complete != 1 &&
          f.IsThirdPartyAccredited == 1 &&
          f?.tpAccreditationExpiresAt &&
          expiryDays(f?.tpAccreditationExpiresAt) >= 0
      );
      this.setState({ contractFilesList: data });
    } else {
      const { contractFilesList } = this.props;
      this.setState({ contractFilesList: contractFilesList });
    }
    this.setState({ showCompletedDoc: !this.state.showCompletedDoc });
    console.log('onCancelStoredFilesUpdate', e.target);
    console.log('onCancelStoredFilesUpdate', e.target.value);
    console.log('onCancelStoredFilesUpdate', this.refs.contractCheck?.value);
    console.log('onCancelStoredFilesUpdate', this.refs.contractCheck);
    console.log('Show completed Doc', this.state.showCompletedDoc);
  };

  onSaveUpdate = (dataToSave, newFile) => {
    const { updateInvestorFile } = this.props;
    const { userId } = this.state;
    const { docId } = this.state.fileToEdit; // eslint-disable-line

    const formData = new FormData();

    formData.append('body', JSON.stringify(dataToSave));
    if (newFile) {
      formData.append('newInvestorFile', newFile);
    }

    updateInvestorFile(docId, formData, userId);
    this.onCancelStoredFilesUpdate();
  };

  // RENDER MANAGER
  renderUpdateStoredFiles = () => {
    let projectType = '';

    const { isEditing, fileToEdit } = this.state;
    const { fileTypeList, allProjects } = this.props;
    const { regCfProjectList, regDProjectList } = allProjects;

    if (fileToEdit.regDId !== 0) {
      projectType = PROJECT_REG_D;
    } else if (fileToEdit.regCfId !== 0) {
      projectType = PROJECT_REG_CF;
    }

    const projectData = {
      selectedProjectId: fileToEdit.projectId,
      fileType: fileToEdit.docTypeId,
      docDescription: fileToEdit.docDesc,
      docMonth: fileToEdit.docMonth,
      docYear: fileToEdit.docYear,
      displayNotes: fileToEdit.displayNotes,
      privateNotes: fileToEdit.privateNotes,
      projectType,
    };

    return (
      <Modal
        className="no-margin translucid-dark-bg"
        show={isEditing}
        onHide={this.onCancelStoredFilesUpdate}
      >
        <Modal.Header closeButton>Header Edit Stored file</Modal.Header>
        <Modal.Body>
          <div className="container">
            <FileDetailsEditorComponent
              fileId={fileToEdit.docId}
              fileToEdit={projectData}
              investorFileTypes={fileTypeList}
              onCancelFileEdit={this.onCancelStoredFilesUpdate}
              onSaveChanges={this.onSaveUpdate}
              regCfProjectList={regCfProjectList}
              regDProjectList={regDProjectList}
              selectedFileName={fileToEdit.docName}
              editStoredFiles
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  renderProjectFilesTable = () => {
    const { projectFileList, isAdmin, showInvestorData, pageItems } =
      this.props;

    if (!projectFileList || !projectFileList.length) return null;

    return (
      <>
        {this.state.width > 767 ? (
          <div className="table-responsive">
            <table className=" table table-striped jambo_table">
              <thead>
                <tr>
                  {showInvestorData && (
                    <React.Fragment>
                      <th>Inv ID</th>
                      <th>Investor</th>
                    </React.Fragment>
                  )}
                  <th>Form Type</th>
                  <th>Download Files</th>
                </tr>
              </thead>
              <tbody>{this.renderFilesTableElements()}</tbody>
            </table>
          </div>
        ) : (
          <div>
            {
              // showInvestorData &&
              this.renderFilesTableElements()
            }
          </div>
        )}
      </>
    );
  };

  renderFilesTableElements = () => {
    const { fileToEdit, pageItems } = this.state;
    const { onFileDownload, onDeleteFile, isAdmin, showInvestorData } =
      this.props;

    return pageItems.map((file) => {
      const {
        docId,
        docName,
        docDesc,
        docType,
        docYear,
        userId,
        investorName,
        investorEmail,
        docMonth,
        displayNotes,
        privateNotes,
        projectName,
      } = file;

      const isSelected = fileToEdit.docId === file.docId;
      const selectedClass = isSelected ? 'selected-table-row' : '';

      return (
        <>
          {this.state.width > 767 ? (
            <tr key={docId} className={selectedClass}>
              {showInvestorData && (
                <React.Fragment>
                  <td>
                    <a
                      onClick={() => this.renderUserInformationModal(userId)}
                      style={{
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                    >
                      {userId}
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => this.renderUserInformationModal(userId)}
                      style={{
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                    >
                      {investorName}
                      <br />
                      {investorEmail}
                    </a>
                  </td>
                </React.Fragment>
              )}
              <td>
                <b>{docYear}</b>&nbsp;{docType} {projectName}
              </td>
              <td>
                {isAdmin ? (
                  <div className="dropdown">
                    <button
                      className="btn btn-primary btn-sm no-margin dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                    >
                      <Icon iconName="chevron-down" extraClasses="white-text" />{' '}
                      Download Files
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="capitalize-text"
                          onClick={() => onFileDownload(docId, userId)}
                        >
                          download
                        </a>
                      </li>

                      <React.Fragment>
                        <li>
                          <a
                            className="capitalize-text"
                            onClick={() =>
                              this.onUpdateStoreFiles(file, userId)
                            }
                          >
                            update
                          </a>
                        </li>
                        <li>
                          <a
                            className="capitalize-text"
                            onClick={() => onDeleteFile(docId, userId)}
                          >
                            delete
                          </a>
                        </li>
                      </React.Fragment>
                    </ul>
                  </div>
                ) : (
                  <a
                    className="capitalize-text cursor-pointer"
                    onClick={() => onFileDownload(docId, userId)}
                  >
                    download
                  </a>
                )}
              </td>
            </tr>
          ) : (
            <div className="files-card p-4 mb-4">
              <ul className="list-unstyled">
                <li>
                  <b>{docYear}</b>&nbsp;{docType}
                </li>
                <li>{projectName}</li>
                <li>
                  <a
                    className="capitalize-text"
                    onClick={() => onFileDownload(docId, userId)}
                  >
                    download
                  </a>
                </li>
              </ul>
            </div>
          )}
        </>
      );
    });
  };

  renderUserInformationModal = (userId) => {
    const userDetailsModal = (
      <InvestorDetailsViewerModal
        key={getUUID()}
        handleCloseProfileModal={this.onCloseModal}
        UserID={userId}
      />
    );

    this.setState({ userDetailsModal });
  };

  render = () => {
    const { userDetailsModal, activePage } = this.state;
    const {
      projectFileList,
      allProjectFiles,
      setResetMethod,
      showInvestorData,
      getAllContractFiles,
      onTabChange,
    } = this.props;
    const { contractFilesList } = this.state;
    const projectListSize = projectFileList.length;
    console.log('Show completed Doc', this.state.showCompletedDoc);

    return (
      <>
        <div className="my-account-tab document-tab col-xs-12 px-0">
          <Tabs>
            <TabList style={{ overflowX: 'overlay', overflowY: 'clip' }}>
              <span className="title">Your Documents </span>
              <Tab key={'taxTab'} onClick={() => onTabChange('taxTab')}>
                <p>Tax Documents </p>
              </Tab>
              <Tab key={'fundTab'} onClick={() => onTabChange('fundTab')}>
                <p>Fund Documents </p>
              </Tab>
              <Tab
                key={'contractTab'}
                onClick={() => onTabChange('contractTab')}
              >
                <p>Contract Documents </p>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="panel-content p-3 px-md-5">
                <div className="content">
                  <h2>Tax Documents</h2>
                  <div>
                    {' '}
                    {this.renderProjectFilesTable()}
                    <div className="text-right">
                      <Pagination
                        firstPageText={
                          <i className="glyphicon glyphicon-chevron-left" />
                        }
                        lastPageText={
                          <i className="glyphicon glyphicon-chevron-right" />
                        }
                        prevPageText={
                          <i className="glyphicon glyphicon-menu-left" />
                        }
                        nextPageText={
                          <i className="glyphicon glyphicon-menu-right" />
                        }
                        activePage={activePage}
                        itemsCountPerPage={this.ITEMS_PER_PAGE}
                        totalItemsCount={projectListSize}
                        onChange={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content p-3 px-md-5">
                <h2>Fund Documents</h2>
                <Documents
                  projectFileList={allProjectFiles}
                  setResetMethod={setResetMethod}
                  ITEMS_PER_PAGE={this.ITEMS_PER_PAGE}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content p-3 px-md-5">
                <div>
                  {' '}
                  <h2>Contract Documents</h2>
                  {showInvestorData && (
                    <div className="checkbox pl-2" style={{ padding: 0 }}>
                      <input
                        id={'contractCheck'}
                        name={'contractCheck'}
                        type="checkbox"
                        value={this.state.showCompletedDoc}
                        checked={this.state.showCompletedDoc}
                        onChange={(e) => this.contractFilterOnChange(e)}
                        className="offerings-header-checkbox"
                      />
                      <label
                        htmlFor={'contractCheck'}
                        className="no-padding fontSize14"
                      >
                        {'Ready to Sign'}
                      </label>
                    </div>
                  )}
                </div>

                <ContractDocuments
                  contractFileList={contractFilesList}
                  showInvestorData={showInvestorData}
                  setResetMethod={setResetMethod}
                  getAllContractFiles={getAllContractFiles}
                  ITEMS_PER_PAGE={15}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  };
}

InvestorFileViewerComponent.propTypes = {
  allProjects: PropTypes.object.isRequired,
  fileTypeList: PropTypes.array.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  setResetMethod: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  projectFileList: PropTypes.array.isRequired,
  contractFilesList: PropTypes.array.isRequired,
  updateInvestorFile: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  showInvestorData: PropTypes.bool.isRequired,
};

InvestorFileViewerComponent.defaultProps = {
  allProjects: {},
  fileTypeList: [],
  onDeleteFile: () => null,
  setResetMethod: () => null,
  onFileDownload: () => null,
  projectFileList: [],
  contractFilesList: [],
  updateInvestorFile: () => null,
  isAdmin: false,
  showInvestorData: false,
};

export default InvestorFileViewerComponent;
