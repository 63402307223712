import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import RequestService from '../../../../api/RequestService';
import InvestorEntityViewerContainer from './component/InvestorEntityViewerContainerComponent';

import ProfileInfoComponent from './component/ProfileInfoComponent';
import EarningsTableComponent from '../../../shared/EarningsTableComponent/EarningsTableComponent';
import InvestmentsComponent from '../../../shared/investmentsComponent/InvestmentsComponent';
import InvestorFilesContainerComponent from '../../../shared/InvestorFilesContainerComponent/InvestorFilesContainerComponent';
import CollapsiblePanelComponent from '../../../shared/CollapsiblePanelComponent/CollapsiblePanelComponent';
import EmailHistoryContainerComponent from '../../../shared/EmailHistoryContainerComponent/EmailHistoryContainerComponent';
import MembershipTableComponent from '../../../shared/MembershipTableComponent/MembershipTableComponent';
import sweetAlert from 'sweetalert2';
import swal from 'sweetalert';
import moment from 'moment';

class InvestorDetailsViewerModal extends Component {
  ITEMS_PER_PAGE = 5;

  constructor(props) {
    super(props);

    this.state = {
      showProfileModal: false,

      investorProfile: [],
      investorInvestments: [],
      investorEarnings: [],

      totalInvestmentRegD: 0,
      totalInvestmentRegCF: 0,
      totalPaymentsRegD: 0,
      totalPaymentsRegCF: 0,
      memberships: [],
      showMembershipFeeModal: false,
      showMembershipUpdatesNoteTableModal: false,
      munotes: [],
      selectedMembershipItemsList: {},
      selectedMembershipItemInitialFeeValue: 0,
      noteError: false,
    };
  }

  componentDidMount() {
    const { UserID } = this.props;

    window.scrollTo(0, 0);
    this.getAllInvestorInfo(UserID);
  }

  handleCloseProfileModal = () => {
    const { handleCloseProfileModal } = this.props;

    this.setState({ showProfileModal: false });
    handleCloseProfileModal();
  };

  getAllInvestorInfo = (UserID) => {
    const { showProfileModal } = this.state;

    this.getInvestorProfile(UserID);
    this.getInvestorInvestments(UserID);
    this.getInvestorPayments(UserID);
    this.getInvestormemberships(UserID);
    this.setState({
      showProfileModal: !showProfileModal,
    });
  };

  getInvestorProfile = async (UserID) => {
    try {
      const { data } = await RequestService.get(
        `/api/getInvestorProfile/${UserID}`
      );

      if (data.code === 200) {
        this.setState({ investorProfile: data.investorProfile });
      } else {
        alert('Could not get Investor Profile.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  getInvestorInvestments = async (UserID) => {
    try {
      const { data } = await RequestService.get(
        `/api/getInvestorInvestments/${UserID}`
      );

      if (data.code === 200) {
        this.setState({
          investorInvestments: data.investorInvestments,
          totalInvestmentRegD: data.totalInvestmentRegD,
          totalInvestmentRegCF: data.totalInvestmentRegCF,
          TotalReturnAmountRegD: data.TotalReturnAmountRegD,
        });
      } else {
        this.setState({
          investorInvestments: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getInvestorPayments = async (UserID) => {
    try {
      const { data } = await RequestService.get(
        `/api/getInvestorPayments/${UserID}`
      );

      if (data.code === 200) {
        this.setState({
          investorEarnings: data.investorPayments,
          totalPaymentsRegD: data.totalPaymentsRegD,
          totalPaymentsRegCF: data.totalPaymentsRegCF,
        });
      } else {
        this.setState({
          investorEarnings: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getInvestormemberships = async (UserID) => {
    try {
      const { data } = await RequestService.get(
        `/api/getInvestorMemperships/${UserID}`
      );

      this.setState({
        memberships: data?.sort(
          (a, b) =>
            moment(new Date(b.InvestmentDate)) -
            moment(new Date(a.InvestmentDate))
        ),
      });
    } catch (error) {
      console.log('Mempership', error);
    }
  };

  handleMembershipFeeModalInputChange = (event) => {
    const { selectedMembershipItemsList } = this.state;
    let membershipUnits =
      (selectedMembershipItemsList?.InvestmentAmount - event.target.value) / 10;
    this.setState({
      selectedMembershipItemsList: {
        ...this.state.selectedMembershipItemsList,
        FeeAmount: event.target?.value,
        MembershipUnits: membershipUnits,
      },
    });
  };
  handleMembershipFeeModalNoteChange = (event) => {
    this.setState({
      selectedMembershipItemsList: {
        ...this.state.selectedMembershipItemsList,
        Notes: event.target?.value,
      },
    });
  };
  formatAmount = (num) => {
    return (num = Number(num).toFixed(2));
  };

  editMembershipFeeTable = (selectedItem) => {
    this.setState({
      ...this.state,
      selectedMembershipItemsList: selectedItem,
      selectedMembershipItemInitialFeeValue: selectedItem?.FeeAmount,
      showMembershipFeeModal: true,
    });
  };
  showMembershipNotesTable = async (selectedItem) => {
    const { data: dd } = await RequestService.get(
      `/api/getAdminUpdateNotes/membershipunits/${selectedItem?.UserInvestmentID}`
    );
    console.log('membershipunits ddd', dd);
    this.setState({ ...this.state, munotes: dd.reverse() });
    this.setState({
      ...this.state,
      selectedMembershipItemsList: selectedItem,
      showMembershipUpdatesNoteTableModal: true,
    });
  };

  updateMembershipFee = async () => {
    const {
      selectedMembershipItemsList,
      selectedMembershipItemInitialFeeValue,
    } = this.state;
    let formBody = [];
    if (!!selectedMembershipItemsList?.Notes) {
      swal({
        title: 'Are you sure you want to Change Orgination Fee?',
        text: `This will transfer ${
          selectedMembershipItemInitialFeeValue >
          selectedMembershipItemsList?.FeeAmount
            ? `$${
                selectedMembershipItemInitialFeeValue -
                selectedMembershipItemsList?.FeeAmount
              } from Fee account to Fund Account`
            : `$ ${
                selectedMembershipItemsList?.FeeAmount -
                selectedMembershipItemInitialFeeValue
              } from Fund Account to Fee Account`
        }.`,
        className: 'primary-button-color',
        icon: 'warning',
        buttons: ['Cancel', 'Yes'],
        // dangerMode: false,
      }).then(async (resp) => {
        if (resp) {
          swal({
            title: 'Do you want to send Updated Hellosign Document?',
            className: 'primary-button-color',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            // dangerMode: false,
          }).then(async (resp2) => {
            if (resp2) {
              formBody.push(
                'OldFeeAmount=' +
                  encodeURIComponent(
                    this.formatAmount(selectedMembershipItemInitialFeeValue)
                  )
              );
              formBody.push(
                'FeeAmount=' +
                  encodeURIComponent(
                    this.formatAmount(selectedMembershipItemsList?.FeeAmount)
                  )
              );
              formBody.push('createHelloSignForm=' + encodeURIComponent(true));
              formBody.push(
                'MembershipUnits=' +
                  encodeURIComponent(
                    this.formatAmount(
                      selectedMembershipItemsList?.MembershipUnits
                    )
                  )
              );
              formBody.push(
                'UserInvestmentID=' +
                  encodeURIComponent(
                    selectedMembershipItemsList?.UserInvestmentID
                  )
              );
              formBody.push(
                'notes=' +
                  encodeURIComponent(selectedMembershipItemsList?.Notes)
              );
              formBody = formBody.join('&');
              const { data } = await RequestService.post({
                url: `/api/updateMembershipFee`,
                data: formBody,
              });

              if (data.code === 200) {
                sweetAlert('Origination Fee Updated', '', 'success');
                this.setState({ noteError: false });
                this.setState({ showMembershipFeeModal: false });
                this.getInvestormemberships(this.props.UserID);
              }
            } else {
              formBody.push(
                'OldFeeAmount=' +
                  encodeURIComponent(
                    this.formatAmount(selectedMembershipItemInitialFeeValue)
                  )
              );
              formBody.push(
                'FeeAmount=' +
                  encodeURIComponent(
                    this.formatAmount(selectedMembershipItemsList?.FeeAmount)
                  )
              );
              formBody.push(
                'MembershipUnits=' +
                  encodeURIComponent(
                    this.formatAmount(
                      selectedMembershipItemsList?.MembershipUnits
                    )
                  )
              );
              formBody.push(
                'UserInvestmentID=' +
                  encodeURIComponent(
                    selectedMembershipItemsList?.UserInvestmentID
                  )
              );
              formBody.push(
                'notes=' +
                  encodeURIComponent(selectedMembershipItemsList?.Notes)
              );
              formBody = formBody.join('&');
              const { data } = await RequestService.post({
                url: `/api/updateMembershipFee`,
                data: formBody,
              });

              if (data.code === 200) {
                sweetAlert('Origination Fee Updated', '', 'success');
                this.setState({ noteError: false });
                this.setState({ showMembershipFeeModal: false });
                this.getInvestormemberships(this.props.UserID);
              }
            }
          });
        }
      });
    } else {
      this.setState({ noteError: true });
    }
  };

  render() {
    const {
      memberships,
      showMembershipFeeModal,
      showMembershipUpdatesNoteTableModal,
    } = this.state;
    console.log('memberships', memberships);
    const {
      investorProfile,
      showProfileModal,
      investorEarnings,
      totalPaymentsRegCF,
      totalPaymentsRegD,
      investorInvestments,
      totalInvestmentRegD,
      totalInvestmentRegCF,
      TotalReturnAmountRegD,
      selectedMembershipItemsList,
      noteError,
    } = this.state;
    const { Email } = investorProfile;
    const { session, UserID } = this.props;
    console.log('nibinbbn', selectedMembershipItemsList?.Notes, noteError);

    return (
      <>
        <Modal
          show={showProfileModal}
          onHide={this.handleCloseProfileModal}
          className="no-padding"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="panel-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <ProfileInfoComponent profile={investorProfile} />
                    </div>

                    <div className="col-xs col-sm-12 col-md-6">
                      <InvestorEntityViewerContainer
                        session={session}
                        UserID={UserID}
                        Email={Email}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <InvestmentsComponent
                        investmentList={investorInvestments}
                        totalInvestmentRegD={totalInvestmentRegD}
                        totalInvestmentRegCF={totalInvestmentRegCF}
                        TotalReturnAmountRegD={TotalReturnAmountRegD}
                        itemsPerPage={this.ITEMS_PER_PAGE}
                        role="Admin"
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <EarningsTableComponent
                        investorEarningsList={investorEarnings}
                        totalPaymentsRegCf={totalPaymentsRegCF}
                        totalPaymentsRegD={totalPaymentsRegD}
                        itemsPerPage={this.ITEMS_PER_PAGE}
                        role="Admin"
                      />
                    </div>
                  </div>
                  <CollapsiblePanelComponent
                    panelTitle={
                      <h3 className="heading-text">Membership Units</h3>
                    }
                    isExpanded
                  >
                    <MembershipTableComponent
                      editMembershipFeeTable={this.editMembershipFeeTable}
                      showMembershipNotesTable={this.showMembershipNotesTable}
                      memberships={memberships}
                      totalPaymentsRegCf={totalPaymentsRegCF}
                      totalPaymentsRegD={totalPaymentsRegD}
                      itemsPerPage={this.ITEMS_PER_PAGE}
                      role="Admin"
                    />
                  </CollapsiblePanelComponent>
                  <CollapsiblePanelComponent
                    panelTitle={
                      <h3 className="heading-text">Investor Files</h3>
                    }
                    isExpanded
                  >
                    <InvestorFilesContainerComponent
                      selectedInvestorId={UserID}
                    />
                  </CollapsiblePanelComponent>

                  <CollapsiblePanelComponent
                    panelTitle={<h3 className="heading-text">Email History</h3>}
                    isExpanded
                  >
                    <EmailHistoryContainerComponent
                      investorId={UserID}
                      isAdmin
                    />
                  </CollapsiblePanelComponent>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-info left"
              type="button"
              onClick={this.handleCloseProfileModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showMembershipFeeModal}
          style={{ width: '90%', margin: '0 auto' }}
          className="gplp-voting-modal"
        >
          <Modal.Header>
            <Modal.Title>Update Origination Fee</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="input-label">Origination Fee</label>
                  <input
                    type="text"
                    className="form-control input"
                    value={selectedMembershipItemsList?.FeeAmount}
                    name="FeeAmount"
                    onChange={this.handleMembershipFeeModalInputChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="input-label">Membership Units</label>
                  <input
                    type="text"
                    className="form-control input"
                    readOnly
                    value={selectedMembershipItemsList?.MembershipUnits}
                    name="MembershipUnits"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="input-label">
                    Notes<span className="text-danger">*</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control input"
                    name="Notes"
                    onChange={this.handleMembershipFeeModalNoteChange}
                  />
                </div>
              </div>
            </form>

            {!selectedMembershipItemsList?.Notes && noteError && (
              <p className="text-danger pt-4 mb-0 pl-4">Please Enter Note</p>
            )}
            <div className="form-group col-md-2 col-sm-2">
              <div className="">
                <button
                  type="Submit"
                  id="submit"
                  onClick={this.updateMembershipFee}
                  className="btn btn-primary btn-green px-5 py-3 ml-3"
                  style={{ margin: '30px 0px 0px' }}
                >
                  Update Fee
                </button>
                <br />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() =>
                this.setState({
                  showMembershipFeeModal: false,
                  noteError: false,
                })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showMembershipUpdatesNoteTableModal}
          style={{ width: '90%', margin: '0 auto' }}
          className="gplp-voting-modal"
        >
          <Modal.Header>
            <Modal.Title>Membership Units Update History</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="col-xs-12">
              <table className="table table-striped jambo_table">
                <thead>
                  <tr>
                    <th>Note</th>
                    <th>Dwolla Transaction Id</th>
                    <th>Edited By</th>
                    <th>Edited On</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state?.munotes?.map((i) => (
                    <tr>
                      <td>{i?.notes}</td>
                      <td>{i?.dwollaTransactionId}</td>
                      <td>
                        {i?.firstName} {i?.lastName}
                      </td>
                      <td>{moment(i?.createdAt).format('MM-DD-YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() =>
                this.setState({ showMembershipUpdatesNoteTableModal: false })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

InvestorDetailsViewerModal.propTypes = {
  session: PropTypes.object.isRequired,
  UserID: PropTypes.any,
  handleCloseProfileModal: PropTypes.func.isRequired,
};

const mapState = ({ session }) => ({
  session: session.user,
  handleCloseProfileModal: () => null,
});

export default withRouter(connect(mapState)(InvestorDetailsViewerModal));
