import React from 'react';
import HelloSign from 'hellosign-embedded';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import * as Actions from './actions/actions';
import InvestmentLogBase from './utils/InvestmentLogBase';
import HideComponent from '../../shared/HideComponent/HideComponent';
import {
  No_Fee_Project,
  Half_Fee_Project,
  Yield_Fund_2_ID,
  America_First_Energy_Fund_1,
  Innovation_Fund_1,
  Opportunity_Fund_1_ID,
  Wildcat_Project_ID,
  Bitcoin_Project_ID,
} from '../../../utils/General';

window.HelloSign = HelloSign;

class Invest2 extends InvestmentLogBase {
  constructor(props) {
    super(props);
    console.log('invest2 props', props);
    this.state = {
      id: props.session.id,
      URL: '',
      ProjectType: props.location.state.ProjectType,
      ProjectID: props.location.state.ProjectID,
      EntityID: props.location.state.EntityID,
      InvestmentAmount: Number(props.location.state.InvestmentAmount),
      GpLp: props.location.state.GpLp,
      SignatureId: '',
      SignatureRequestId: '',
      isLoaded: false,
      errorOnLoad: false,
      continueButton: 'Continue',
      hideContinueButton: false,
      bankAccount: props.location.state.bankAccount,
      skipHelloSign: props.location.state.skipHelloSign,
      priorInvestments: props.location.state.priorInvestments,
      investedProjectsCount: props.location.state.investedProjectsCount,
      classAPercent: props.location.state.classAPercent,
      classBPercent: props.location.state.classBPercent,
    };

    this.hellosign = this.hellosign.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  async componentDidMount() {
    const { id: userId, ProjectType, ProjectID } = this.state;

    window.scrollTo(0, 0);

    if (this.state.skipHelloSign === 0) {
      this.hellosign();
    }

    await this.init();
    await this.setProjectIDs(ProjectID, ProjectType);
    await this.setLogData({
      userId,
      stepNumber: 2,
      eventName: 'next',
    });
    console.log(
      'invested Projects count on inv2',
      this.state.investedProjectsCount
    );
    console.log(
      'invested Projects count on inv2 class a',
      this.state.classAPercent,
      'classb valuve',
      this.state.classBPercent
    );
    if (this.state.skipHelloSign === 1) {
      document
        .getElementById('invest2next')
        .setAttribute('disabled', 'disabled');
    }
  }

  clickOrigSubAgmt() {
    if (document.getElementById('origSubAgmt').checked) {
      document.getElementById('invest2next').removeAttribute('disabled');
    } else {
      document
        .getElementById('invest2next')
        .setAttribute('disabled', 'disabled');
    }
  }

  onNext() {
    if (this.state.SignatureRequestId !== '') {
      document
        .getElementById('invest2next')
        .setAttribute('disabled', 'disabled');
      this.setState({ continueButton: 'Please Wait ... ' });
      fetch(
        '/api/check_sign/' +
          this.state.SignatureRequestId +
          '/' +
          this.state.EntityID +
          '/' +
          this.state.id +
          '/' +
          this.props.session.marketing,
        {
          method: 'get',
        }
      )
        .then((response) => {
          return response.json();
        })
        .then(async (status) => {
          if (!!status.data) {
            //status.data.is_complete
            const redirectObj = {
              pathname: '/app/invest4',
              state: {
                ProjectType: this.state.ProjectType,
                ProjectID: this.state.ProjectID,
                SignatureId: this.state.SignatureId,
                EntityID: this.state.EntityID,
                InvestmentAmount: Number(this.state.InvestmentAmount),
                history: this.props.location.state,
                GpLp: this.state.GpLp,
                HelloSignURL: status.data.HelloSignURL,
                bankAccount: this.state.bankAccount,
                priorInvestments: this.state.priorInvestments,
                investedProjectsCount: this.state.investedProjectsCount,
                classAPercent: this.state.classAPercent,
                classBPercent: this.state.classBPercent,
              },
            };

            await this.setLogData({
              stepData: JSON.stringify({ ...redirectObj.state }),
            });
            await this.performStepLog();

            this.props.history.push(redirectObj);
          } else {
            alert('Please fill the form completely');
            this.hellosign();
          }
        })
        .catch(async (err) => {
          // this.setState({
          //     err: err,
          //     errorOnLoad: true,
          // });
          //Proceed, even if signing fails.
          console.log('Signing failed with error', err);
          const redirectObj = {
            pathname: '/app/invest4',
            state: {
              ProjectType: this.state.ProjectType,
              ProjectID: this.state.ProjectID,
              SignatureId: this.state.SignatureId,
              EntityID: this.state.EntityID,
              InvestmentAmount: Number(this.state.InvestmentAmount),
              history: this.props.location.state,
              GpLp: this.state.GpLp,
              HelloSignURL: 'ERRORED:' + err.toString(),
              bankAccount: this.state.bankAccount,
              priorInvestments: this.state.priorInvestments,
              investedProjectsCount: this.state.investedProjectsCount,
              classAPercent: this.state.classAPercent,
              classBPercent: this.state.classBPercent,
            },
          };

          await this.setLogData({
            stepData: JSON.stringify({ ...redirectObj.state }),
          });
          await this.performStepLog();

          this.props.history.push(redirectObj);
        });
    } else if (
      this.state.skipHelloSign > 0 ||
      window.location.hostname === 'local.energyfunders.com' ||
      window.location.hostname === 'devapp.energyfunders.com'
    ) {
      const redirectObj = {
        pathname: '/app/invest4',
        state: {
          ProjectType: this.state.ProjectType,
          ProjectID: this.state.ProjectID,
          SignatureId: this.state.SignatureId,
          EntityID: this.state.EntityID,
          InvestmentAmount: Number(this.state.InvestmentAmount),
          history: this.props.location.state,
          GpLp: this.state.GpLp,
          HelloSignURL: 'SKIPPED',
          bankAccount: this.state.bankAccount,
          priorInvestments: this.state.priorInvestments,
          investedProjectsCount: this.state.investedProjectsCount,
          classAPercent: this.state.classAPercent,
          classBPercent: this.state.classBPercent,
        },
      };

      this.setLogData({ stepData: JSON.stringify({ ...redirectObj.state }) });
      this.performStepLog();

      this.props.history.push(redirectObj);
    } else {
      alert('Please wait for the document to finish loading.');
    }
  }

  onCancelInvestment = async () => {
    const stepData = {
      ProjectType: this.state.ProjectType,
      ProjectID: this.state.ProjectID,
      SignatureId: this.state.SignatureId,
      EntityID: this.state.EntityID,
      InvestmentAmount: Number(this.state.InvestmentAmount),
      history: this.props.location.state,
      GpLp: this.state.GpLp,
      classAPercent: this.state.classAPercent,
      classBPercent: this.state.classBPercent,
      HelloSignURL: 'none',
    };

    await this.setLogData({
      eventName: 'cancel investment',
      stepData: JSON.stringify(stepData),
    });
    await this.performStepLog();

    this.props.history.push('/app/UserNotifications');
  };

  onPrevious() {
    HelloSign.close();
    this.props.history.push({
      pathname: '/app/invest',
      state: {
        ProjectType: this.state.ProjectType,
        ProjectID: this.state.ProjectID,
      },
    });
  }

  hellosign() {
    let InvestmentAmount = Number(this.state.InvestmentAmount);
    // let priorInvestments = this.state.priorInvestments.yf1TotalInvestment;
    let daysLeft = moment('2022-10-31').diff(moment(new Date()), 'days');
    let FeeAmount = 0;
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };

    let priorInvestments = Number(priorInv.TotalInvestment);

    if (this.state?.ProjectID == Wildcat_Project_ID) {
      FeeAmount = InvestmentAmount * 0.02;
    } else if (
      this.state.ProjectID == No_Fee_Project ||
      Number(this.state?.ProjectID) == Opportunity_Fund_1_ID
    ) {
      FeeAmount = 0;
    } else {
      if (
        this.state?.ProjectID == Yield_Fund_2_ID ||
        this.state.ProjectID == America_First_Energy_Fund_1
      ) {
        if (daysLeft >= 0 && this.state.investedProjectsCount > 0) {
          console.log('setting 0 by daysleft');
          FeeAmount = 0;
        } else {
          if (InvestmentAmount + priorInvestments < 100000) {
            FeeAmount = InvestmentAmount * 0.05;
          } else if (
            InvestmentAmount + priorInvestments >= 100000 &&
            InvestmentAmount + priorInvestments <= 249999
          ) {
            FeeAmount = InvestmentAmount * 0.0325;
          } else if (
            InvestmentAmount + priorInvestments >= 250000 &&
            InvestmentAmount + priorInvestments <= 499999
          ) {
            FeeAmount = InvestmentAmount * 0.025;
          } else if (
            InvestmentAmount + priorInvestments >= 500000 &&
            InvestmentAmount + priorInvestments <= 999999
          ) {
            FeeAmount = InvestmentAmount * 0.0175;
          } else if (InvestmentAmount + priorInvestments >= 1000000) {
            FeeAmount = InvestmentAmount * 0.01;
          }
        }
      } else {
        if (InvestmentAmount < 100000) {
          FeeAmount = InvestmentAmount * 0.03;
        } else if (
          InvestmentAmount + priorInvestments >= 100000 &&
          InvestmentAmount + priorInvestments <= 249999
        ) {
          FeeAmount = InvestmentAmount * 0.025;
        } else if (
          InvestmentAmount + priorInvestments >= 250000 &&
          InvestmentAmount + priorInvestments <= 499999
        ) {
          FeeAmount = InvestmentAmount * 0.0175;
        } else if (InvestmentAmount + priorInvestments >= 500000) {
          FeeAmount = InvestmentAmount * 0.01;
        }

        if (this.state.ProjectID == Half_Fee_Project) {
          FeeAmount = FeeAmount / 2;
        }
      }
    }

    let formBody = [];
    formBody.push('ProjectID=' + this.state.ProjectID);
    formBody.push('ProjectType=' + this.state.ProjectType);
    formBody.push('EntityID=' + this.state.EntityID);
    formBody.push(
      'Amount=' + (Number(this.state.InvestmentAmount) + Number(FeeAmount))
    );
    formBody.push('GpLp=' + this.state.GpLp);
    formBody.push('TaxBasis=' + this.props.location.state.taxBasis);
    formBody.push('FeeAmount=' + FeeAmount);
    if (
      this.state.ProjectID == Wildcat_Project_ID ||
      this.state.ProjectID == Yield_Fund_2_ID ||
      this.state.ProjectID == America_First_Energy_Fund_1 ||
      this.state.ProjectID == Opportunity_Fund_1_ID
    ) {
      formBody.push(
        'ClassAShares=' +
          (this.state.InvestmentAmount * this.state.classAPercent) / 100 / 10
      );
      formBody.push(
        'ClassBShares=' +
          (this.state.InvestmentAmount * this.state.classBPercent) / 100 / 10
      );
    }
    formBody = formBody.join('&');

    fetch('/api/sign/' + this.state.id + '/' + this.props.session.marketing, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => {
        console.log('hellosign');
        return response.json();
      })
      .then((status) => {
        this.setState({
          URL: status.URL,
          SignatureId: status.signature_id,
          SignatureRequestId: status.signature_request_id,
          isLoaded: true,
          err: {},
          errorOnLoad: false,
        });
        HelloSign.init(status.ClientID);
        HelloSign.open({
          url: this.state.URL,
          skipDomainVerification: status.skipDomainVerification,
          allowCancel: false,
          uxVersion: 2,
        });

        window.addEventListener('message', this.onHelloSignCloseEventListener);
      })
      .catch((err) => {
        this.setState({
          err: err,
          errorOnLoad: true,
        });
      });
  }

  onHelloSignCloseEventListener = (event) => {
    if (event.data === 'close') {
      this.setState({ hideContinueButton: true });
    }
  };

  render() {
    return (
      <div className="container body">
        <div className="main_container">
          <div className="right_col" role="main">
            <div className="Mainstep">
              <div className="container">
                <div className="row">
                  <ul className="breadcrumb">
                    <li className="completed1">
                      <a href="javascript:void(0);">Details</a>
                    </li>
                    <li className="completed1">
                      <a href="javascript:void(0);">Signature</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Payment</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Confirmation</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <h3 className="text-center">
              {' '}
              {this.state.ProjectID == America_First_Energy_Fund_1
                ? 'America First Energy Fund I'
                : this.state.ProjectID == Yield_Fund_2_ID
                ? 'Yield Fund II'
                : this.state.ProjectID == Bitcoin_Project_ID
                ? 'Bitcoin Discovery Fund'
                : this.state.ProjectID == Wildcat_Project_ID
                ? 'EnergyFunders Preferred Equity Drilling Fund I LLC'
                : this.state.ProjectID == Innovation_Fund_1
                ? 'Innovation Fund I'
                : this.state.ProjectID == Opportunity_Fund_1_ID
                ? 'Opportunity Fund I'
                : ''}{' '}
            </h3>
            <br />

            <div className="stepContainer">
              <div id="step-1" className="content" style={{ display: 'block' }}>
                <HideComponent hide={this.state.skipHelloSign === 1}>
                  <form className="form-horizontal form-label-left">
                    <span
                      style={{
                        'font-size': 18,
                        'font-weight': 'bold',
                        color: 'black',
                      }}
                    >
                      <strong>
                        Investor Agreement: Please fully review and sign the
                        document.
                      </strong>
                    </span>
                    <br />
                    <span
                      style={{
                        'font-size': 18,
                        'font-weight': 'bold',
                        color: 'black',
                      }}
                    >
                      Click "Continue" when you are done.
                    </span>
                    <br />
                  </form>
                  <div id="hellosign"></div>
                  <hr />

                  {!this.state.hideContinueButton && (
                    <button
                      className="btn btn-success"
                      id="invest2next"
                      onClick={this.onNext}
                      style={{ backgroundColor: '#5bb462' }}
                    >
                      {this.state.continueButton}
                    </button>
                  )}
                </HideComponent>

                <HideComponent hide={this.state.skipHelloSign === 0}>
                  <div className="check_tc" style={{ clear: 'both' }}>
                    <label className="custom-container text-size-15px">
                      <span style={{ color: 'red' }}>*</span> I affirm that this
                      investment being made is still subject to the original
                      disclosure memorandum, subscription agreement, and
                      partnership agreement.
                      <input
                        type="checkbox"
                        required
                        ref="origSubAgmt"
                        name="origSubAgmt"
                        id="origSubAgmt"
                        onClick={this.clickOrigSubAgmt}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <button
                    className="btn btn-success"
                    id="invest2next"
                    ref="invest2next"
                    onClick={this.onNext}
                    style={{ backgroundColor: '#5bb462' }}
                  >
                    {this.state.continueButton}
                  </button>
                </HideComponent>

                <br />
                <br />
                <a href="javascript:;" onClick={this.onCancelInvestment}>
                  Cancel Investment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { object, bool } = PropTypes;

Invest2.propTypes = {
  ...InvestmentLogBase.propTypes,
  session: object.isRequired,
  authenticated: bool.isRequired,
  checked: bool.isRequired,
};

const mapState = ({ session }) => ({
  session: session.user,
  checked: session.checked,
  authenticated: session.authenticated,
});

const mapDispatchToProps = (_dispatch) => ({
  ...bindActionCreators(Actions, _dispatch),
});

export default withRouter(connect(mapState, mapDispatchToProps)(Invest2));
