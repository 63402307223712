import React, { Component, useCallback } from 'react';
import RequestService from '../../../api/RequestService';
import { getUUID } from '../../../utils/General';
import { getImpersonateID } from '../../../utils/General';
import moment from 'moment';
import { Button, Modal, Panel } from 'react-bootstrap';
import swal from 'sweetalert2';

class InvestorMessageSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailHistory: [],
      selectedEmail: [],
      searchEmailText: '',
      showMessageModal: false,
      sendMessage: {
        EmailSubject: '',
        EmailBody: '',
      },
      filterBy: 'inbox',
      defaultMailListSelected: [],
    };
  }
  handleSearch = (event) => {
    this.setState({ ...this.state, searchEmailText: event.target.value });
  };

  emailClickHandler(item) {
    this.setState({
      selectedEmail: [item],
      defaultMailListSelected: [],
    });
  }
  async getInvestorEmails() {
    const impersonateID = await getImpersonateID();
    const url = `/api/investor/email-history/all/${impersonateID}`;
    try {
      const { data } = await RequestService.get(url);
      if (data) {
        this.setState({
          ...this.state,
          emailHistory: data,
        });
        this.selectFirstMail();
      }
    } catch (error) {
      console.log('email-history', error);
    }
  }

  selectFirstMail() {
    const { emailHistory } = this.state;

    const selectFirstMail = emailHistory
      ?.filter(
        (item) =>
          item.reason.toString()?.toLowerCase()?.replace(/\s/g, '') !==
          'sendmessage'
      )
      ?.slice(0, 1);
    this.setState({ defaultMailListSelected: selectFirstMail });
  }

  handleFilterByMessage(event) {
    this.setState(
      { filterBy: event.target.value, selectedEmail: [] },
      this.mailDetail,
      this.selectFirstMail()
    );
  }
  componentDidMount() {
    this.getInvestorEmails();
  }

  onMsgInputchange = (event) => {
    this.setState({
      sendMessage: {
        ...this.state.sendMessage,
        [event.target.name]: event.target.value,
      },
    });
  };

  validateMessageInputs = () => {
    const { sendMessage } = this.state;
    const requiredKey = ['EmailSubject', 'EmailBody'];

    return requiredKey
      .map(
        (key) =>
          sendMessage[key] === '' ||
          sendMessage[key] === null ||
          sendMessage[key] === undefined
      )
      .some((item) => item);
  };

  onSendMessage = async (e) => {
    e.preventDefault();

    const formTemp = [];

    formTemp.push(
      `EmailSubject=${encodeURIComponent(this.state.sendMessage.EmailSubject)}`
    );
    formTemp.push(
      `EmailBody=${encodeURIComponent(this.state.sendMessage.EmailBody)}`
    );
    formTemp.push(`UserID=${encodeURIComponent(this.props.userID)}`);
    formTemp.push(`UserName=${encodeURIComponent(this.props.userName)}`);

    const formBody = formTemp.join('&');

    try {
      const { data: status } = await RequestService.post({
        url: '/api/sendMessage',
        data: formBody,
      });

      if (status.code === 200) {
        swal('Message  Successful', '', 'success');
        console.log('Message Successful', formBody);
        this.getInvestorEmails();
        this.setState({
          showMessageModal: false,
          sendMessage: {
            EmailSubject: '',
            EmailBody: '',
          },
        });
      } else {
        alert('Could not update due to unknown error. Please try again later.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  mailDetail = () => {
    const { filterBy, selectedEmail, emailHistory } = this.state;
    let mailDetail;
    if (filterBy === 'inbox') {
      mailDetail =
        selectedEmail.length > 0
          ? selectedEmail
          : emailHistory
              ?.filter(
                (item) =>
                  item.reason.toString()?.toLowerCase()?.replace(/\s/g, '') !==
                  'sendmessage'
              )
              ?.slice(0, 1);
    } else {
      mailDetail = selectedEmail?.filter(
        (item) =>
          item.reason.toString()?.toLowerCase().replace(/\s/g, '') ===
          'sendmessage'
      );
    }
    return (
      <>
        {mailDetail?.length > 0 && (
          <div key={getUUID()}>
            {' '}
            <div className="title">
              <h3>{mailDetail[0].subject}</h3>
              <p>{moment(mailDetail[0].sentDate).format('MMM DD, YYYY')}</p>
            </div>
            <div className="mt-4 mailh">
              <p
                style={{
                  paddingTop: '10px',
                }}
                dangerouslySetInnerHTML={{
                  __html: mailDetail[0].body,
                }}
              ></p>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const messageIcon = '/img/message-h.svg';
    const ticketIcon = '/img/support.svg';
    const {
      emailHistory,
      selectedEmail,
      searchEmailText,
      filterBy,
      defaultMailListSelected,
    } = this.state;

    return (
      <div>
        <div className="title-wrap">
          <div className="d-flex align-items-center">
            <h2 className="pr-2 m-0">Messages</h2>
            <select
              value={filterBy}
              onChange={(event) => this.handleFilterByMessage(event)}
            >
              <option value="inbox">Inbox</option>
              <option value="sent">Sent</option>
            </select>
          </div>
          <div className="send-msg ml-auto d-flex">
            <a
              href="mailto:info@energyfunders.com"
              className="btn btn-primary border-0 py-4 px-5 d-flex align-items-center btn-green mr-3 no-underline"
            >
              <img src={ticketIcon} />
              <span className="text-white">Submit Support Ticket</span>
            </a>
            <a
              href="mailto:info@energyfunders.com"
              className="btn btn-primary border-0 py-4 px-5 d-flex align-items-center btn-green no-underline"
            >
              <img src={messageIcon} />
              <span className="text-white">Send Message</span>
            </a>
          </div>
        </div>

        <div className="col-xs-12 col-md-12 white-bg mail-list p-0 mb-5">
          <div className="col-sm-6 col-md-6 mb-4">
            <div className=" white-bg py-3 px-0  message-wrap">
              <div className="">
                {' '}
                <div className="form-group m-0 has-feedback has-search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Messages"
                    onChange={(event) => this.handleSearch(event)}
                  />
                  <span className="glyphicon glyphicon-search form-control-feedback"></span>
                </div>
              </div>
            </div>
            <ul>
              <li>
                {' '}
                <div className="row m-0">
                  <div className="col-xs-4 col-md-4 font-bold">
                    <p>Date</p>
                  </div>
                  <div className="col-xs-8 col-md-8 font-bold">
                    <p>Subject</p>
                  </div>
                </div>
              </li>
              {emailHistory?.length > 0
                ? emailHistory
                    .filter((item) => item.subject !== 'SendMessage')
                    ?.filter((item) => {
                      if (searchEmailText) {
                        return (
                          item?.subject
                            ?.toString()
                            ?.toLowerCase()
                            .indexOf(searchEmailText?.toLowerCase()) !== -1
                        );
                      }
                      return true;
                    })
                    ?.filter((item) => {
                      if (filterBy.toString().toLowerCase() === 'inbox') {
                        return (
                          item.reason
                            .toString()
                            ?.toLowerCase()
                            ?.replace(/\s/g, '') !== 'sendmessage'
                        );
                      }
                      return (
                        item.reason
                          .toString()
                          ?.toLowerCase()
                          ?.replace(/\s/g, '') === 'sendmessage'
                      );
                    })
                    ?.map((item) => {
                      return (
                        <li
                          className={
                            selectedEmail[0]?.historyId?.toString() ===
                            item?.historyId?.toString()
                              ? 'active-list'
                              : defaultMailListSelected[0]?.historyId?.toString() ===
                                item?.historyId?.toString()
                              ? 'active-list'
                              : ''
                          }
                          onClick={() => this.emailClickHandler(item)}
                          key={getUUID()}
                        >
                          <div className="row m-0">
                            <div className="col-xs-4 col-md-4">
                              <p>
                                {moment(item.sentDate).format('MMM DD, YYYY')}
                              </p>
                            </div>
                            <div className="col-xs-8 col-md-8">
                              <h3>{item?.subject}</h3>
                            </div>
                          </div>
                        </li>
                      );
                    })
                : null}
            </ul>
          </div>
          <div className="col-sm-6 col-md-6 r-sec mb-4">
            {emailHistory?.length > 0 && this.mailDetail()}
          </div>
        </div>
        <Modal
          show={this.state.showMessageModal}
          className="message-modal"
          style={{ width: '50%', margin: '0 auto' }}
        >
          <Modal.Header>
            <Modal.Title>
              Send Message
              <Button
                style={{ float: 'right' }}
                onClick={() => this.setState({ showMessageModal: false })}
              >
                Close
              </Button>
            </Modal.Title>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}
export default InvestorMessageSearchComponent;
