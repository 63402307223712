import swal from 'sweetalert';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import qrcode from 'qrcode';
import moment from 'moment';

import * as swal2 from 'sweetalert2';
import * as Actions from './actions/actions';
import InvestmentLogBase from './utils/InvestmentLogBase';
import HideComponent from '../../shared/HideComponent/HideComponent';
import {
  formatMoney,
  formatMoneyShowZero,
  No_Fee_Project,
  Half_Fee_Project,
  Yield_Fund_2_ID,
  America_First_Energy_Fund_1,
  Innovation_Fund_1,
  Opportunity_Fund_1_ID,
  Wildcat_Project_ID,
  Bitcoin_Project_ID,
} from '../../../utils/General';
import RequestService from '../../../api/RequestService';
let ACH_LIMIT = 500000;

class Invest4 extends InvestmentLogBase {
  constructor(props) {
    super(props);

    this.state = {
      id: props.session.id,
      ProjectID: props.location.state.ProjectID
        ? props.location.state.ProjectID
        : props.locaton.state.history.ProjectID,
      EntityID: props.location.state.EntityID
        ? props.location.state.EntityID
        : props.location.state.history.EntityID,
      ProjectType: props.location.state.ProjectType
        ? props.location.state.ProjectType
        : props.location.state.history.ProjectType,
      InvestmentAmount: Number(
        props.location.state.InvestmentAmount
          ? props.location.state.InvestmentAmount
          : props.location.state.history.InvestmentAmount
      ),
      Incomes:
        props.location.state.ProjectType === 'RegCF'
          ? props.location.state.Incomes
            ? props.location.state.Incomes
            : props.location.state.history.Incomes
          : '',
      SignatureId: props.location.state.SignatureId
        ? props.location.state.SignatureId
        : props.location.state.history.SignatureId,
      GpLp: props.location.state.GpLp
        ? props.location.state.GpLp
        : props.location.state.history.GpLp,
      btcAddress: props.location.state.history.btcAddress,
      ethAddress: props.location.state.history.ethAddress,
      taxBasis: props.location.state.history.taxBasis,
      hasFee: props.location.state.history.hasFee || 0, // Hardcoded $10 per share in line 541.
      FeeAmount: 0,
      priorInvestments: props.location.state.priorInvestments,
      investedProjectsCount: props.location.state.investedProjectsCount,
      classAPercent: props.location.state.classAPercent,
      classBPercent: props.location.state.classBPercent,
      TransactionCode: '',
      Entity: [],
      QRCode: '',
      ProjectInvestment: [],
      isLoaded: false,
      errorOnLoad: false,
      email: '',
      showAccreditedCheckBox: false,
      AccreditedCheckBoxValue: false,
    };

    this.getEntity = this.getEntity.bind(this);
    this.getInvestment = this.getInvestment.bind(this);
    this.onProcessPayment = this.onProcessPayment.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.getInvestorProfile = this.getInvestorProfile.bind(this);
    // this.generateTransactionCode = this.generateTransactionCode.bind(this);
  }

  async componentDidMount() {
    const { id: userId, ProjectID, ProjectType } = this.state;

    window.scrollTo(0, 0);
    this.getEntity();
    this.getInvestment();
    this.generateTransactionCode();
    this.getInvestorProfile();
    await this.init();
    await this.setProjectIDs(ProjectID, ProjectType);
    await this.setLogData({
      userId,
      eventName: 'finalize investment',
      stepNumber: 3,
    });

    let FeeAmount = 0;
    const priorInv = this.state.priorInvestments.find(
      (pi) => pi.ProjectID === this.state.ProjectID
    ) ?? {
      ProjectID: 0,
      TotalInvestment: 0,
      FirstInvestmentDate: '',
      LastInvestmentDate: '',
    };

    let priorInvestments = Number(priorInv.TotalInvestment);
    let daysLeft = moment('2022-10-31').diff(moment(new Date()), 'days');
    console.log(
      'invested Projects count on inv4',
      this.state.investedProjectsCount
    );
    console.log(
      'invested Projects count on inv4',
      this.state.InvestmentAmount,
      ' class a',
      this.state.classAPercent,
      'classb valuve',
      this.state.classBPercent
    );

    if (this.state.hasFee) {
      if (this.state?.ProjectID == Wildcat_Project_ID) {
        FeeAmount = this.state.InvestmentAmount * 0.02;
      } else if (
        this.state?.ProjectID == Yield_Fund_2_ID ||
        this.state.ProjectID == America_First_Energy_Fund_1
      ) {
        if (daysLeft >= 0 && this.state.investedProjectsCount > 0) {
          console.log('setting 0 by daysleft and already invested ');
          FeeAmount = 0;
        } else {
          if (this.state.InvestmentAmount + priorInvestments < 100000) {
            FeeAmount = this.state.InvestmentAmount * 0.05;
          } else if (
            this.state.InvestmentAmount + priorInvestments >= 100000 &&
            this.state.InvestmentAmount + priorInvestments <= 249999
          ) {
            FeeAmount = this.state.InvestmentAmount * 0.0325;
          } else if (
            this.state.InvestmentAmount + priorInvestments >= 250000 &&
            this.state.InvestmentAmount + priorInvestments <= 499999
          ) {
            FeeAmount = this.state.InvestmentAmount * 0.025;
          } else if (
            this.state.InvestmentAmount + priorInvestments >= 500000 &&
            this.state.InvestmentAmount + priorInvestments <= 999999
          ) {
            FeeAmount = this.state.InvestmentAmount * 0.0175;
          } else if (
            this.state.InvestmentAmount + priorInvestments >=
            1000000
          ) {
            FeeAmount = this.state.InvestmentAmount * 0.01;
          }
        }
      } else {
        // if (this.state.investedProjectsCount > 0) {
        // 	FeeAmount = 0;
        // }
        // else
        if (this.state.InvestmentAmount + priorInvestments < 100000) {
          FeeAmount = this.state.InvestmentAmount * 0.03;
        } else if (
          this.state.InvestmentAmount + priorInvestments >= 100000 &&
          this.state.InvestmentAmount + priorInvestments <= 249999
        ) {
          FeeAmount = this.state.InvestmentAmount * 0.025;
        } else if (
          this.state.InvestmentAmount + priorInvestments >= 250000 &&
          this.state.InvestmentAmount + priorInvestments <= 499999
        ) {
          FeeAmount = this.state.InvestmentAmount * 0.0175;
        } else if (this.state.InvestmentAmount + priorInvestments >= 500000) {
          FeeAmount = this.state.InvestmentAmount * 0.01;
        }
      }
    }
    if (
      this.state.ProjectID === No_Fee_Project ||
      Number(this.state?.ProjectID) === Opportunity_Fund_1_ID
    ) {
      this.setState({ FeeAmount: 0 });
    } else if (this.state.ProjectID === Half_Fee_Project) {
      this.setState({ FeeAmount: FeeAmount / 2 });
    } else {
      this.setState({ FeeAmount: FeeAmount });
    }
    if (this.props.location.state.bankAccount === -10) {
      qrcode.toDataURL(`bitcoin:${this.state.btcAddress}`, (err, imageUrl) => {
        if (err) {
          console.log('Error with QR');
          return;
        }

        this.setState({ QRCode: imageUrl });
      });
    } else if (this.props.location.state.bankAccount === -20) {
      qrcode.toDataURL(`ethereum:${this.state.ethAddress}`, (err, imageUrl) => {
        if (err) {
          console.log('Error with QR');
          return;
        }

        this.setState({ QRCode: imageUrl });
      });
    }
    await this.GetAccreditationDetails();
  }

  async GetAccreditationDetails() {
    var formBody = [];
    formBody.push('UserID=' + encodeURIComponent(this.state.id));
    formBody.push('ProjectID=' + encodeURIComponent(this.state.ProjectID));
    formBody.push('EntityID=' + encodeURIComponent(this.state.EntityID));
    formBody = formBody.join('&');
    const { data } = await RequestService.post({
      url: '/api/userAccreditedAndInvested',
      data: formBody,
    });
    let accreditationDetails = await data?.result?.Accreditation;

    let value =
      (await data?.result?.AlreadyInvested) &&
      accreditationDetails?.IsThirdPartyAccredited == 1 &&
      accreditationDetails?.tpAccreditationExpiresAt &&
      moment(accreditationDetails?.tpAccreditationExpiresAt).diff(
        moment(new Date()),
        'days'
      ) < 0;

    // console.log("GetAccreditationDetails result",data.result);
    // console.log("GetAccreditationDetails invested",data?.result?.AlreadyInvested);
    // console.log("GetAccreditationDetails entitiey details",data?.result?.Accreditation);
    // console.log("GetAccreditationDetails value",value);
    // console.log("GetAccreditationDetails moment",moment(accreditationDetails?.tpAccreditationExpiresAt).diff(moment(new Date()), 'days'));
    if (value) {
      document
        .getElementById('submitAndProcess')
        .setAttribute('disabled', 'disabled');
    }

    this.setState({ showAccreditedCheckBox: value });
  }
  updateIsThirdPartyAccredited = async (value) => {
    console.log('value', value);
    if (!this.state.AccreditedCheckBoxValue) {
      const result = await swal2({
        title: 'Are you still accredited?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      });
      if (result.value) {
        this.setState({
          AccreditedCheckBoxValue: !this.state.AccreditedCheckBoxValue,
        });
        document.getElementById('submitAndProcess').removeAttribute('disabled');
      }
    } else {
      this.setState({
        AccreditedCheckBoxValue: !this.state.AccreditedCheckBoxValue,
      });
      document
        .getElementById('submitAndProcess')
        .setAttribute('disabled', 'disabled');
    }
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async generateTransactionCode() {
    const res = await fetch(
      `/api/generateTransactionCode/${this.state.id}/${this.props.session.marketing}`
    );
    const data = await res.json(res);

    this.setStateAsync({ TransactionCode: await data.transactionCode });
  }

  getEntity() {
    fetch(
      `/api/getentitybyid/${this.state.EntityID}/${this.state.id}/${this.props.session.marketing}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('console log of getentitybyid = ', JSON.stringify(data));
        this.setState({
          Entity: data[0],
          isLoaded: true,
          err: {},
          errorOnLoad: false,
        });
        console.log(data, 'entities');
      })
      .catch((err) => {
        this.setState({
          err,
          errorOnLoad: true,
        });
      });
  }

  async onProcessPayment() {
    document
      .getElementById('submitAndProcess')
      .setAttribute('disabled', 'disabled');
    let formBody = [];
    let UTM_Codes = sessionStorage.getItem('utm_codes');
    UTM_Codes = JSON.parse(UTM_Codes);
    console.log('UTM_Codes', UTM_Codes);

    if (this.state.ProjectType === 'RegD') {
      formBody.push(`ProjectID_RegD=${this.state.ProjectID}`);
      formBody.push(`ProjectID_RegCF=${0}`);
      formBody.push('regcf_annual_income=');
      formBody.push('regcf_networth=');
      formBody.push('regcf_preinvested_interal=');
      formBody.push('regcf_preinvested_external=');
      formBody.push(`GpLp=${this.state.GpLp}`);
    } else {
      // Reg CF
      formBody.push(`regcf_annual_income=${this.state.Incomes[0]}`);
      formBody.push(`regcf_networth=${this.state.Incomes[1]}`);
      formBody.push(`regcf_preinvested_interal=${this.state.Incomes[3]}`);
      formBody.push(`regcf_preinvested_external=${this.state.Incomes[2]}`);
      formBody.push(`ProjectID_RegD=${0}`);
      formBody.push(`ProjectID_RegCF=${this.state.ProjectID}`);
      formBody.push(`GpLp=${2}`);
    }
    if (
      this.state.ProjectID == Wildcat_Project_ID ||
      this.state.ProjectID == Yield_Fund_2_ID ||
      this.state.ProjectID == America_First_Energy_Fund_1 ||
      this.state.ProjectID == Opportunity_Fund_1_ID
    ) {
      formBody.push(`ClassA=${this.state.classAPercent}`);
      formBody.push(`ClassB=${this.state.classBPercent}`);
    }
    formBody.push(`UserID=${this.state.id}`);
    formBody.push(`IsCashCall=${0}`);
    formBody.push(`State=${this.state.Entity.State}`);
    formBody.push(`Entity=${this.state.Entity.EntityName}`);
    formBody.push(`EntityID=${this.state.EntityID}`);
    formBody.push(`InvestmentAmount=${this.state.InvestmentAmount}`);
    formBody.push(`Signature=${this.state.SignatureId}`);
    formBody.push(`TransactionCode=${this.state.TransactionCode}`);
    formBody.push(`PaymentStatusNotes=${encodeURIComponent('')}`);
    formBody.push(`ContractFilePath=${this.props.location.state.HelloSignURL}`);
    formBody.push(`HelloSignDocumentId=${this.state.SignatureId}`);
    formBody.push(`SubscriptionAgreementEmailed=${0}`);
    formBody.push(`SubscriptionAgreementDownloaded=${0}`);
    formBody.push(`bankAccount=${this.props.location.state.bankAccount}`);
    formBody.push(`taxBasis=${this.state.taxBasis}`);
    formBody.push('Hash=');
    if (this.state.InvestmentAmount > ACH_LIMIT) {
      formBody.push(`paymentType=Wire`);
    }
    formBody.push('utm_source=' + UTM_Codes?.utm_source);
    formBody.push('utm_medium=' + UTM_Codes?.utm_medium);
    formBody.push('utm_campaign=' + UTM_Codes?.utm_campaign);

    formBody.push('utm_term=' + UTM_Codes?.utm_term);
    formBody.push('utm_content=' + UTM_Codes?.utm_content);
    formBody.push(`email=${this.state?.email}`);
    formBody = formBody.join('&');

    await this.setLogData({
      stepData: JSON.stringify({
        formBody,
        state: {
          InvestmentAmount: this.state.InvestmentAmount,
          TransactionCode: this.state.TransactionCode,
          ProjectType: this.state.ProjectType,
          SignatureId: this.state.SignatureId,
          ProjectID: this.state.ProjectID,
          EntityID: this.state.EntityID,
          history: this.props.location.state.history,
          GpLp: this.state.GpLp,
        },
      }),
    });

    await this.performStepLog();

    if (
      this.props.location.state.bankAccount === -10 ||
      this.props.location.state.bankAccount === -20
    ) {
      // Crypto
      // do fetch call
      fetch(
        `/api/investCrypto/${this.state.id}/${this.props.session.marketing}`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        }
      )
        .then((response) => response.json())
        .then((status) => {
          // console.log(JSON.stringify(status))
          if (status.code === 200) {
            sessionStorage.removeItem('utm_codes');
            swal('Investment Successful', '', 'success').then((value) => {
              // const input = document.getElementById('print');

              this.props.history.push({
                pathname: '/app/invest3',
                state: {
                  ProjectType: this.state.ProjectType,
                  ProjectID: this.state.ProjectID,
                  SignatureId: this.state.SignatureId,
                  EntityID: this.state.EntityID,
                  InvestmentAmount: this.state.InvestmentAmount,
                  history: this.props.location.state.history,
                  GpLp: this.state.GpLp,
                  TransactionCode: this.state.TransactionCode,
                  bankAccount: this.props.location.state.bankAccount,
                  taxBasis: this.state.taxBasis,
                  hasFee: this.state.hasFee,
                  priorInvestments: this.state.priorInvestments,
                  investedProjectsCount: this.state.investedProjectsCount,
                  classAPercent: this.state.classAPercent,
                  classBPercent: this.state.classBPercent,
                },
              });
            });
          }
        })
        .catch((err) => {
          swal(
            'There was an error confirming the investment. Please contact Investor Relations',
            // err,
            '',
            'error'
          );
          console.log('error confirming investment', err);
          document
            .getElementById('submitAndProcess')
            .removeAttribute('disabled');
        });
    } else {
      // ACH
      // do fetch call

      if (this.state.InvestmentAmount > ACH_LIMIT) {
        fetch(
          `/api/investCrypto/${this.state.id}/${this.props.session.marketing}`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody,
          }
        )
          .then((response) => response.json())
          .then((status) => {
            // console.log(JSON.stringify(status))
            if (status.code === 200) {
              sessionStorage.removeItem('utm_codes');
              swal('Investment Successful', '', 'success').then((value) => {
                // const input = document.getElementById('print');

                this.props.history.push({
                  pathname: '/app/invest3',
                  state: {
                    ProjectType: this.state.ProjectType,
                    ProjectID: this.state.ProjectID,
                    SignatureId: this.state.SignatureId,
                    EntityID: this.state.EntityID,
                    InvestmentAmount: this.state.InvestmentAmount,
                    history: this.props.location.state.history,
                    GpLp: this.state.GpLp,
                    TransactionCode: this.state.TransactionCode,
                    bankAccount: this.props.location.state.bankAccount,
                    taxBasis: this.state.taxBasis,
                    hasFee: this.state.hasFee,
                    classAPercent: this.state.classAPercent,
                    classBPercent: this.state.classBPercent,
                    investedProjectsCount: this.state.investedProjectsCount,
                    priorInvestments: this.state.priorInvestments,
                  },
                });
              });
            }
          })
          .catch((err) => {
            swal(
              'There was an error confirming the investment. Please contact Investor Relations',
              // err,
              '',
              'error'
            );
            console.log('error confirming investment', err);
            document
              .getElementById('submitAndProcess')
              .removeAttribute('disabled');
          });
      } else {
        fetch(`/api/invest/${this.state.id}/${this.props.session.marketing}`, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((status) => {
            // console.log(JSON.stringify(status))
            if (status.code === 200) {
              sessionStorage.removeItem('utm_codes');
              swal('Investment Successful', '', 'success').then((value) => {
                // const input = document.getElementById('print');

                this.props.history.push({
                  pathname: '/app/invest3',
                  state: {
                    ProjectType: this.state.ProjectType,
                    ProjectID: this.state.ProjectID,
                    SignatureId: this.state.SignatureId,
                    EntityID: this.state.EntityID,
                    InvestmentAmount: this.state.InvestmentAmount,
                    history: this.props.location.state.history,
                    GpLp: this.state.GpLp,
                    TransactionCode: this.state.TransactionCode,
                    bankAccount: this.props.location.state.bankAccount,
                    hasFee: this.state.hasFee,
                    priorInvestments: this.state.priorInvestments,
                    investedProjectsCount: this.state.investedProjectsCount,
                    classAPercent: this.state.classAPercent,
                    classBPercent: this.state.classBPercent,
                  },
                });
              });
            } else {
              swal(
                'There was an error confirming the investment. Please contact Investor Relations',
                // status.failed,
                '',
                'error'
              );
              console.log(
                'error confirming investment status.failed',
                status?.failed
              );
              document
                .getElementById('submitAndProcess')
                .removeAttribute('disabled');
            }
          })
          .catch((err) => {
            swal(
              'There was an error confirming the investment. Please contact Investor Relations',
              // err,
              '',
              'error'
            );
            console.log('error confirming investment', err);
            document
              .getElementById('submitAndProcess')
              .removeAttribute('disabled');
          });
      }
    }
  }

  onCancelInvestment = async () => {
    let formBody = [];

    if (this.state.ProjectType === 'RegD') {
      formBody.push(`ProjectID_RegD=${this.state.ProjectID}`);
      formBody.push(`ProjectID_RegCF=${0}`);
      formBody.push('regcf_annual_income=');
      formBody.push('regcf_networth=');
      formBody.push('regcf_preinvested_interal=');
      formBody.push('regcf_preinvested_external=');
      formBody.push(`GpLp=${this.state.GpLp}`);
    } else {
      // Reg CF
      formBody.push(`regcf_annual_income=${this.state.Incomes[0]}`);
      formBody.push(`regcf_networth=${this.state.Incomes[1]}`);
      formBody.push(`regcf_preinvested_interal=${this.state.Incomes[3]}`);
      formBody.push(`regcf_preinvested_external=${this.state.Incomes[2]}`);
      formBody.push(`ProjectID_RegD=${0}`);
      formBody.push(`ProjectID_RegCF=${this.state.ProjectID}`);
      formBody.push(`GpLp=${2}`);
    }
    if (
      this.state.ProjectID == Wildcat_Project_ID ||
      this.state.ProjectID == Yield_Fund_2_ID ||
      this.state.ProjectID == America_First_Energy_Fund_1 ||
      this.state.ProjectID == Opportunity_Fund_1_ID
    ) {
      formBody.push(`ClassA=${this.state.classAPercent}`);
      formBody.push(`ClassB=${this.state.classBPercent}`);
    }
    formBody.push(`UserID=${this.state.id}`);
    formBody.push(`IsCashCall=${0}`);
    formBody.push(`State=${this.state.Entity.State}`);
    formBody.push(`Entity=${this.state.Entity.EntityName}`);
    formBody.push(`EntityID=${this.state.EntityID}`);
    formBody.push(`InvestmentAmount=${this.state.InvestmentAmount}`);
    formBody.push(`Signature=${this.state.SignatureId}`);
    formBody.push(`TransactionCode=${this.state.TransactionCode}`);
    formBody.push(`PaymentStatusNotes=${encodeURIComponent('')}`);
    formBody.push(`ContractFilePath=${this.props.location.state.HelloSignURL}`);
    formBody.push(`HelloSignDocumentId=${this.state.SignatureId}`);
    formBody.push(`SubscriptionAgreementEmailed=${0}`);
    formBody.push(`SubscriptionAgreementDownloaded=${0}`);
    formBody.push('Hash=');

    formBody = formBody.join('&');

    await this.setLogData({
      eventName: 'cancel investment',
      stepData: JSON.stringify({
        formBody,
        state: {
          InvestmentAmount: this.state.InvestmentAmount,
          TransactionCode: this.state.TransactionCode,
          ProjectType: this.state.ProjectType,
          SignatureId: this.state.SignatureId,
          ProjectID: this.state.ProjectID,
          EntityID: this.state.EntityID,
          history: this.props.location.state.history,
          classAPercent: this.state.classAPercent,
          classBPercent: this.state.classBPercent,
          GpLp: this.state.GpLp,
        },
      }),
    });
    await this.performStepLog();

    this.props.history.push('/app/UserNotifications');
  };

  onPrevious() {
    this.props.history.push({
      pathname: '/app/invest',
      state: {
        SignatureId: this.state.SignatureId,
        Incomes: this.state.Incomes,
        ProjectType: this.state.ProjectType,
        ProjectID: this.state.ProjectID,
        EntityID: this.state.EntityID,
        InvestmentAmount: this.props.location.state.InvestmentAmount,
        classAPercent: this.state.classAPercent,
        classBPercent: this.state.classBPercent,
      },
    });
  }

  getInvestment() {
    if (this.state.ProjectType === 'RegCF') {
      fetch(
        `/api/getprojectinvestmentCF/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          console.log(JSON.stringify(response));
          return response.json();
        })
        .then((data) => {
          this.setState({
            ProjectInvestment: data[0],
            isLoaded: true,
            err: {},
            errorOnLoad: false,
          });
          console.log(data, 'investment');
        })
        .catch((err) => {
          this.setState({
            err,
            errorOnLoad: true,
          });
        });
    } else {
      fetch(
        `/api/getprojectinvestment/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          console.log(JSON.stringify(response));
          return response.json();
        })
        .then((data) => {
          this.setState({
            ProjectInvestment: data[0],
            isLoaded: true,
            err: {},
            errorOnLoad: false,
          });
          console.log(data, 'investment');
        })
        .catch((err) => {
          this.setState({
            err,
            errorOnLoad: true,
          });
        });
    }
  }

  getInvestorProfile = async () => {
    try {
      const { data } = await RequestService.get('/api/getInvestorProfile');

      if (data.code === 200) {
        this.setState({
          email: data.rows[0].Email,
        });
      } else {
        alert('Could not retrieve your profile. Please try again later.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let authorizationText = '';
    if (
      this.state.InvestmentAmount <= ACH_LIMIT &&
      this.props.location.state.bankAccount !== -10 &&
      this.props.location.state.bankAccount !== -20
    ) {
      authorizationText = (
        <>
          <td align="right">Authorization:</td>
          <td>
            By clicking the{' '}
            <strong>“Confirm My Investment and Transfer Funds”</strong> button
            below, you authorize Dwolla to debit the bank account you have
            provided via the EnergyFunders platform.
          </td>
        </>
      );
    }

    if (
      this.state.InvestmentAmount > ACH_LIMIT &&
      this.props.location.state.bankAccount !== -10 &&
      this.props.location.state.bankAccount !== -20
    ) {
      authorizationText = (
        <>
          <td align="right">Authorization:</td>
          <td>
            {' '}
            By clicking the <strong>"Finalize My Investment"</strong> button
            below, you are allowing a representative from EnergyFunders to
            contact you regarding this fund and coordinate next steps.
          </td>
        </>
      );
    }
    let tableHeight =
      this.state.InvestmentAmount > ACH_LIMIT ? 'auto' : ' 160mm';

    return (
      <div className="container body">
        <div className="main_container">
          <div className="right_col" role="main">
            <div className="Mainstep">
              <div className="container">
                <div className="row">
                  <ul className="breadcrumb">
                    <li className="completed1">
                      <a href="javascript:void(0);">Details</a>
                    </li>
                    <li className="completed1">
                      <a href="javascript:void(0);">Signature</a>
                    </li>
                    <li className="completed1">
                      <a href="javascript:void(0);">Payment</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Confirmation</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <h3 className="text-center">
              {' '}
              {this.state.ProjectID == America_First_Energy_Fund_1
                ? 'America First Energy Fund I'
                : this.state.ProjectID == Yield_Fund_2_ID
                ? 'Yield Fund II'
                : this.state.ProjectID == Bitcoin_Project_ID
                ? 'Bitcoin Discovery Fund'
                : this.state.ProjectID == Wildcat_Project_ID
                ? 'EnergyFunders Preferred Equity Drilling Fund I LLC'
                : this.state.ProjectID == Innovation_Fund_1
                ? 'Innovation Fund I'
                : this.state.ProjectID == Opportunity_Fund_1_ID
                ? 'Opportunity Fund I'
                : ''}{' '}
            </h3>
            <br />

            <div
              id="print"
              style={{
                backgroundColor: '#f5f5f5',
                width: '210mm',
                minHeight: { tableHeight },
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="o white-text" colSpan={2}>
                      Investment Summary - Confirm Your Investment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-right" style={{ minWidth: 170 }}>
                      Transaction Date:
                    </td>
                    <td>{new Date().toLocaleDateString()}</td>
                  </tr>
                  <tr style={{ fontWeight: 'bold' }}>
                    <td className="text-right">Investment Amount ($):</td>
                    <td>
                      {(
                        Number(this.props.location.state.InvestmentAmount) +
                        Number(this.state.FeeAmount)
                      ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                  </tr>

                  <HideComponent hide={!this.state.hasFee}>
                    <tr>
                      <td className="text-right">Origination Fee:</td>
                      <td>
                        {Number(this.state.FeeAmount).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </td>
                    </tr>
                  </HideComponent>

                  <tr>
                    <td className="text-right">Transaction Code:</td>
                    <td>{this.state.TransactionCode}</td>
                  </tr>

                  {(() => {
                    if (this.props.location.state.ProjectType === 'RegCF') {
                      return (
                        <tr>
                          <td className="text-right">Project Name:</td>
                          <td>{this.state.ProjectInvestment.InvestmentName}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td className="text-right">Project Name:</td>
                          <td>{this.state.ProjectInvestment.ProjectName}</td>
                        </tr>
                      );
                    }
                  })()}

                  <tr>
                    <td className="text-right">Investor:</td>
                    <td>{this.state.Entity.EntityName}</td>
                  </tr>

                  {(() => {
                    if (this.props.location.state.ProjectType === 'RegCF') {
                      return (
                        <tr>
                          <td className="text-right">Fund:</td>
                          <td>
                            {this.state.ProjectInvestment.LegalIssuerName}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td className="text-right">Fund:</td>
                          <td>{this.state.ProjectInvestment.Entity}</td>
                        </tr>
                      );
                    }
                  })()}

                  {(() => {
                    if (this.props.location.state.ProjectType === 'RegCF') {
                      return (
                        <tr>
                          <td className="text-right">Type of Securities:</td>
                          <td>{this.state.ProjectInvestment.TypeOfSecurity}</td>
                        </tr>
                      );
                    }
                  })()}

                  <tr>
                    <td className="text-right">Price Paid for Investment:</td>
                    <td>
                      {Number(this.state.InvestmentAmount).toLocaleString(
                        'en-US',
                        { style: 'currency', currency: 'USD' }
                      )}
                    </td>
                  </tr>

                  {(() => {
                    if (this.props.location.state.ProjectType === 'RegCF') {
                      return (
                        <tr>
                          <td className="text-right">Number of Securities:</td>
                          <td>
                            {this.state.InvestmentAmount /
                              this.state.ProjectInvestment.IncrementPrice}
                          </td>
                        </tr>
                      );
                    } else {
                      if (
                        this.state.ProjectID == Wildcat_Project_ID ||
                        this.state.ProjectID == Yield_Fund_2_ID ||
                        this.state.ProjectID == America_First_Energy_Fund_1 ||
                        this.state.ProjectID == Opportunity_Fund_1_ID
                      ) {
                        const classAValue =
                          (Number(this.state.InvestmentAmount) *
                            Number(this.state.classAPercent)) /
                          100;
                        const classAFee =
                          (Number(this.state.FeeAmount) *
                            Number(this.state.classAPercent)) /
                          100;
                        const classBValue =
                          (Number(this.state.InvestmentAmount) *
                            Number(this.state.classBPercent)) /
                          100;
                        const classBFee =
                          (Number(this.state.FeeAmount) *
                            Number(this.state.classBPercent)) /
                          100;
                        return (
                          <>
                            <tr>
                              <td className="text-right">
                                {this.state.ProjectID != Opportunity_Fund_1_ID
                                  ? 'Class A Shares:'
                                  : `Class ${
                                      Number(this.state.InvestmentAmount) >=
                                        100000 &&
                                      Number(this.state.InvestmentAmount) <
                                        250000
                                        ? 'A'
                                        : Number(this.state.InvestmentAmount) >=
                                            250000 &&
                                          Number(this.state.InvestmentAmount) <
                                            500000
                                        ? 'B'
                                        : Number(this.state.InvestmentAmount) >=
                                          500000
                                        ? 'C'
                                        : ''
                                    } Units:`}
                              </td>
                              <td>
                                {this.state.hasFee
                                  ? (classAValue / 10).toLocaleString()
                                  : Number(this.state.InvestmentAmount) /
                                    Number(
                                      this.state?.ProjectInvestment
                                        ?.IncrementPrice
                                    )}
                              </td>
                            </tr>
                            {this.state.ProjectID != Opportunity_Fund_1_ID ? (
                              <tr>
                                <td className="text-right">
                                  {'Class B Shares:'}
                                </td>
                                <td>
                                  {this.state.hasFee
                                    ? (classBValue / 10).toLocaleString()
                                    : Number(this.state.InvestmentAmount) /
                                      Number(
                                        this.state.ProjectInvestment
                                          .IncrementPrice
                                      )}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        );
                      } else if (this.state.ProjectID == Innovation_Fund_1) {
                        return (
                          <tr>
                            <td className="text-right">Class A Shares:</td>
                            <td>
                              {this.state.hasFee
                                ? (
                                    Number(this.state.InvestmentAmount) / 10
                                  ).toLocaleString()
                                : Number(this.state.InvestmentAmount) /
                                  Number(
                                    this.state.ProjectInvestment.IncrementPrice
                                  )}
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td className="text-right">
                              Number of Securities:
                            </td>
                            <td>
                              {this.state.hasFee
                                ? (
                                    Number(this.state.InvestmentAmount) / 10
                                  ).toLocaleString()
                                : Number(this.state.InvestmentAmount) /
                                  Number(
                                    this.state.ProjectInvestment.IncrementPrice
                                  )}
                            </td>
                          </tr>
                        );
                      }
                    }
                  })()}

                  {(() => {
                    if (this.props.location.state.ProjectType === 'RegCF') {
                      return (
                        <React.Fragment>
                          {/* <tr>
												<td className="text-right">
													Total Number of Securities
													in Offering:
												</td>
												<td>
													{
														this.state
															.ProjectInvestment
															.TotalNoOfSecurities
													}
												</td>
											</tr> */}
                          <tr>
                            <td className="text-right">
                              Source, Form and Amount of any Renumeration
                              Received by Intermediary:
                            </td>
                            <td>
                              Carried interest of 5% in the entire offering and
                              5% success fee based on total amount raised by
                              issuer.
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <tr>
                          {/* <td className="text-right">
												Total Number of Securities in
												Offering:
											</td>
											<td>
												{
													this.state.ProjectInvestment
														.Units
												}
											</td> */}
                        </tr>
                      );
                    }
                  })()}
                  <tr>
                    <HideComponent
                      hide={
                        this.props.location.state.bankAccount === -10 ||
                        this.props.location.state.bankAccount === -20
                      }
                    >
                      {authorizationText}
                    </HideComponent>
                    <HideComponent
                      hide={this.props.location.state.bankAccount > 0}
                    >
                      <td align="right">Payment Transfer:</td>
                      <td>
                        {' '}
                        Please Transfer $
                        {Number(this.props.location.state.InvestmentAmount) +
                          Number(this.state.FeeAmount)}{' '}
                        to
                        {this.props.location.state.bankAccount === -10 && (
                          <div>
                            BTC Address: {this.state.btcAddress}
                            <br />
                            {this.state.QRCode && (
                              <React.Fragment>
                                <img
                                  src={`${this.state.QRCode}`}
                                  style={{
                                    margin: '5px 0 0 10px',
                                  }}
                                />
                              </React.Fragment>
                            )}
                            <br />
                          </div>
                        )}
                        {this.props.location.state.bankAccount === -20 && (
                          <div>
                            ETH Address: {this.state.ethAddress}
                            <br />
                            {this.state.QRCode && (
                              <React.Fragment>
                                <img
                                  src={`${this.state.QRCode}`}
                                  style={{
                                    margin: '5px 0 0 10px',
                                  }}
                                />
                              </React.Fragment>
                            )}
                            <br />
                          </div>
                        )}
                      </td>
                    </HideComponent>
                  </tr>
                  {this.state.showAccreditedCheckBox && (
                    <tr>
                      {' '}
                      <td colSpan={2}>
                        <div className="pb-2 text-info">
                          {' '}
                          Your Third Party Accreditation Expired{' '}
                        </div>
                        <fieldset>
                          <legend className="mb-0 pb-0 border-0">
                            <span>
                              <input
                                id="IsThirdPartyAccredited"
                                name={'IsThirdPartyAccredited'}
                                type="checkbox"
                                checked={this.state.AccreditedCheckBoxValue}
                                disabled={!this.state.showAccreditedCheckBox}
                                className="offerings-header-checkbox"
                                onChange={(e) =>
                                  this.updateIsThirdPartyAccredited(
                                    e.target.value
                                  )
                                }
                              />
                              <label
                                htmlFor={'IsThirdPartyAccredited'}
                                className="no-padding fontSize14"
                              >
                                {' '}
                              </label>
                            </span>{' '}
                            I am still an accredited investor
                          </legend>
                        </fieldset>
                      </td>{' '}
                    </tr>
                  )}
                  <tr>
                    <td colSpan={2}>
                      {this.props.location.state.bankAccount > 0 && (
                        <div style={{ textAlign: 'center' }}>
                          <button
                            id="submitAndProcess"
                            onClick={this.onProcessPayment}
                            style={{
                              backgroundColor: '#5bb462',
                            }}
                            className="btn btn-success"
                          >
                            {Number(this.state.InvestmentAmount) +
                              Number(this.state.FeeAmount) >
                            ACH_LIMIT
                              ? 'Finalize My Investment'
                              : `Confirm My Investment and Transfer Funds`}
                          </button>
                        </div>
                      )}

                      {(this.props.location.state.bankAccount === -10 ||
                        this.props.location.state.bankAccount === -20) && (
                        <div style={{ textAlign: 'center' }}>
                          <button
                            id="submitAndProcess"
                            onClick={this.onProcessPayment}
                            style={{
                              backgroundColor: '#5bb462',
                            }}
                            className="btn btn-success"
                          >
                            I Confirm I Have Sent Crypto Funds
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>

                  <HideComponent
                    hide={
                      this.props.location.state.bankAccount === -10 ||
                      this.props.location.state.bankAccount === -20 ||
                      Number(this.state.InvestmentAmount) +
                        Number(this.state.FeeAmount) >
                        ACH_LIMIT
                    }
                  >
                    <tr>
                      <td colSpan={2}>
                        In the case of an ACH Transaction being rejected for Non
                        Sufficient Funds (NSF) I understand that Dwolla may at
                        its discretion attempt to process the charge again
                        within 30 days, and agree to an additional $3.00 charge
                        for each attempt returned NSF. I acknowledge that the
                        origination of ACH transactions to my account must
                        comply with the provisions of U.S. law. I certify that I
                        am an authorized user of this bank account and will not
                        dispute this transaction with my bank; so long as the
                        transaction corresponds to the terms indicated in this
                        authorization form. I understand that for any items that
                        are disputed there will be a fee assessed in the amount
                        of $25.00.
                      </td>
                    </tr>
                  </HideComponent>
                </tbody>
              </table>
            </div>
            <div className="done">
              <center>
                <button
                  className="btn btn-link"
                  onClick={this.onCancelInvestment}
                >
                  Cancel This Investment
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { object, bool } = PropTypes;

Invest4.propTypes = {
  ...InvestmentLogBase.propTypes,
  session: object.isRequired,
  authenticated: bool.isRequired,
  checked: bool.isRequired,
};

const mapState = ({ session }) => ({
  ...InvestmentLogBase.defaultProps,
  session: session.user,
  checked: session.checked,
  authenticated: session.authenticated,
});

const mapDispatchToProps = (_dispatch) => ({
  ...bindActionCreators(Actions, _dispatch),
});

export default withRouter(connect(mapState, mapDispatchToProps)(Invest4));
