
import React from 'react';
import jsPDF from 'jspdf';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import qrcode from 'qrcode';
import moment from 'moment';
import InvestmentLogBase from './utils/InvestmentLogBase';
import * as Actions from './actions/actions';
import HideComponent from '../../shared/HideComponent/HideComponent';
import { PassportButton } from "@parallelmarkets/react";
import { formatMoney, formatMoneyShowZero, No_Fee_Project, Half_Fee_Project, Yield_Fund_2_ID, America_First_Energy_Fund_1, Innovation_Fund_1, Opportunity_Fund_1_ID , Wildcat_Project_ID, Bitcoin_Project_ID } from '../../../utils/General';
import { withParallelMarketsHOC } from '../WithParallelMarketsHOC';
import RequestService from '../../../api/RequestService';

let ACH_LIMIT = 500000;



class Invest3 extends InvestmentLogBase {
    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            ProjectID: (props.location.state.ProjectID) ? (props.location.state.ProjectID) : (props.locaton.state.history.ProjectID),
            EntityID: (props.location.state.EntityID) ? (props.location.state.EntityID) : (props.location.state.history.EntityID),
            ProjectType: (props.location.state.ProjectType) ? (props.location.state.ProjectType) : (props.location.state.history.ProjectType),
            InvestmentAmount: Number((props.location.state.InvestmentAmount) ? (props.location.state.InvestmentAmount) : (props.location.state.history.InvestmentAmount)),
            Incomes: (props.location.state.ProjectType === 'RegCF') ? ((props.location.state.Incomes) ? (props.location.state.Incomes) : (props.location.state.history.Incomes)) : (''),
            SignatureId: (props.location.state.SignatureId) ? (props.location.state.SignatureId) : (props.location.state.history.SignatureId),
            GpLp: (props.location.state.GpLp ? props.location.state.GpLp : props.location.state.history.GpLp),
            btcAddress: props.location.state.history.btcAddress,
            ethAddress: props.location.state.history.ethAddress,
            TransactionCode: props.location.state.TransactionCode,
            bankAccount: props.location.state.bankAccount,
            hasFee: props.location.state.hasFee,	// Hardcoded $10 per share fee in Line 401
            FeeAmount: 0,
            priorInvestments: props.location.state.priorInvestments,
            Entity: [],
            ProjectInvestment: [],
			classAPercent: props.location.state.classAPercent,
			classBPercent: props.location.state.classBPercent,
            QRCode: '',
            isLoaded: false,
            errorOnLoad: false,
            investedProjectsCount: props.location.state.investedProjectsCount,
            thirdPartyAccredited: 0,
        };

        this.getEntity = this.getEntity.bind(this);
        this.getInvestment = this.getInvestment.bind(this);
        this.getProject = this.getProject.bind(this);
        this.getProjectCF = this.getProjectCF.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrint = this.onPrint.bind(this);
    }



    async componentDidMount() {
        const { id: userId, ProjectID, ProjectType } = this.state;

        window.scrollTo(0, 0);
        this.getEntity();
        this.getInvestment();
        this.checkVerified();
        if (this.state.ProjectType === 'RegD') {
            this.getProject();
        }
        else {
            this.getProjectCF();
        }

        await this.setProjectIDs(ProjectID, ProjectType);
        await this.setLogData({
            userId,
            stepNumber: 4,
            eventName: 'investment complete',
            stepData: JSON.stringify({}),
        });

        this.performStepLog();
        console.log("invested Projects count on inv3", this.state.investedProjectsCount);

        let FeeAmount = 0;
        const priorInv = this.state.priorInvestments.find(pi => pi.ProjectID === this.state.ProjectID) ?? { ProjectID: 0, TotalInvestment: 0, FirstInvestmentDate: "", LastInvestmentDate: "" };

        let priorInvestments = Number(priorInv.TotalInvestment);
        let daysLeft = moment('2022-10-31').diff(moment(new Date()), 'days');

        if (this.state.hasFee) {
          if (this.state?.ProjectID == Wildcat_Project_ID) {
            FeeAmount = this.state.InvestmentAmount * 0.02;
          } else if (this.state?.ProjectID == Yield_Fund_2_ID || this.state.ProjectID == America_First_Energy_Fund_1) {
                if (daysLeft >= 0 && this.state.investedProjectsCount > 0 ) { 
                    console.log("setting 0 by daysleft")
                    FeeAmount = 0
                }
                else {
                    if ((this.state.InvestmentAmount + priorInvestments) < 100000) {
                        FeeAmount = this.state.InvestmentAmount * 0.05
                    }
                    else if ((this.state.InvestmentAmount + priorInvestments) >= 100000 && (this.state.InvestmentAmount + priorInvestments) <= 249999) {
                        FeeAmount = this.state.InvestmentAmount * 0.0325
                    }
                    else if ((this.state.InvestmentAmount + priorInvestments) >= 250000 && (this.state.InvestmentAmount + priorInvestments) <= 499999) {
                        FeeAmount = this.state.InvestmentAmount * 0.025
                    }
                    else if ((this.state.InvestmentAmount + priorInvestments) >= 500000 && (this.state.InvestmentAmount + priorInvestments) <= 999999) {
                        FeeAmount = this.state.InvestmentAmount * 0.0175
                    }
                    else if ((this.state.InvestmentAmount + priorInvestments) >= 1000000) {
                        FeeAmount = this.state.InvestmentAmount * 0.01
                    }
                }
            } else {
                // if (this.state.investedProjectsCount > 0) {
                //     FeeAmount = 0;
                // }
                // else 
                if ((this.state.InvestmentAmount + priorInvestments) < 100000) {
                    FeeAmount = this.state.InvestmentAmount * 0.03
                }
                else if ((this.state.InvestmentAmount + priorInvestments) >= 100000 && (this.state.InvestmentAmount + priorInvestments) <= 249999) {
                    FeeAmount = this.state.InvestmentAmount * 0.025
                }
                else if ((this.state.InvestmentAmount + priorInvestments) >= 250000 && (this.state.InvestmentAmount + priorInvestments) <= 499999) {
                    FeeAmount = this.state.InvestmentAmount * 0.0175
                }
                else if ((this.state.InvestmentAmount + priorInvestments) >= 500000) {
                    FeeAmount = this.state.InvestmentAmount * 0.01
                }
            }
        }


        if (this.state.ProjectID === No_Fee_Project || Number(this.state?.ProjectID) == Opportunity_Fund_1_ID) {
            this.setState({ FeeAmount: 0 });
        } else if (this.state.ProjectID === Half_Fee_Project) {
            this.setState({ FeeAmount: FeeAmount / 2 })
        } else {
            this.setState({ FeeAmount: FeeAmount });
        }

        if (this.props.location.state.bankAccount === -10) {
            qrcode.toDataURL(`bitcoin:${this.state.btcAddress}`, (err, imageUrl) => {
                if (err) {
                    console.log('Error with QR');
                    return;
                }

                this.setState({ QRCode: imageUrl });
            });
        }
        else if (this.props.location.state.bankAccount === -20) {
            qrcode.toDataURL(`ethereum:${this.state.ethAddress}`, (err, imageUrl) => {
                if (err) {
                    console.log('Error with QR');
                    return;
                }

                this.setState({ QRCode: imageUrl });
            });
        }
    };

async checkVerified(){
    if(this.state.EntityID){
        const {data}  = await RequestService.get(`/api/userEntityW9AndAccreditedCheck/${this.state.EntityID}`);
            let result = await data.result;
            this.setState({thirdPartyAccredited: result?.ThirdpartyAccredited});

    }
}

    onNext() {
        this.props.history.push({
            pathname: '/app/InvestmentOpportunities',
        });
    }



    getInvestment() {
        if (this.state.ProjectType === 'RegCF') {
            fetch(`/api/getprojectinvestmentCF/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`, {
                method: 'GET',
            })
                .then((response) => {
                    console.log(JSON.stringify(response));
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        ProjectInvestment: data[0],
                        isLoaded: true,
                        err: {},
                        errorOnLoad: false,
                    });
                    console.log(data, 'investment');
                })
                .catch((err) => {
                    this.setState({
                        err,
                        errorOnLoad: true,
                    });
                });
        } else {
            fetch(`/api/getprojectinvestment/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`, {
                method: 'GET',
            })
                .then((response) => {
                    console.log(JSON.stringify(response));
                    return response.json();
                })
                .then((data) => {
                    this.setState({
                        ProjectInvestment: data[0],
                        isLoaded: true,
                        err: {},
                        errorOnLoad: false,
                    });
                    console.log(data, 'investment');
                })
                .catch((err) => {
                    this.setState({
                        err,
                        errorOnLoad: true,
                    });
                });
        }
    }



    getProjectCF() {
        fetch(`/api/getProjectdetailsprojects_regcf/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`, {
            method: 'get',
        })
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    Deal: data[0],
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
                console.log(data);
            })
            .catch((err) => {
                this.setState({
                    err,
                    errorOnLoad: true,
                });
            });
    }



    getProject() {
        fetch(`/api/getProjectdetailsprojects_regD/${this.state.ProjectID}/${this.state.id}/${this.props.session.marketing}`, {
            method: 'get',
        })
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    Deal: data[0],
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
                console.log(data);
            })
            .catch((err) => {
                this.setState({
                    err,
                    errorOnLoad: true,
                });
            });
    }



    getEntity() {
        fetch(`/api/getentitybyid/${this.state.EntityID}/${this.state.id}/${this.props.session.marketing}`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then((data) => {
                console.log('console log of getentitybyid = ', JSON.stringify(data));
                this.setState({
                    Entity: data[0],
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
                console.log(data, 'entities');
            })
            .catch((err) => {
                this.setState({
                    err,
                    errorOnLoad: true,
                });
            });
    }



    onPrint() {
        const input = document.getElementById('print');

        html2canvas(input, {scrollY: -window.scrollY})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();

                pdf.addImage(imgData, 'JPEG', 2, 2, 200, 290);
                // pdf.output('dataurlnewwindow');
                pdf.save('Receipt.pdf');
            });
    }



    render() {

        let authorizationText = '';
        if (this.state.InvestmentAmount <= ACH_LIMIT && this.props.location.state.bankAccount !== -10 &&
            this.props.location.state.bankAccount !== -20) {
            authorizationText = <>
                <td align="right">Authorization:</td>
                <td>
                    {' '}
                    You authorized Dwolla to debit the bank account whose information you provided
                    above for a one-time payment. The amount of the payment is exactly the
                    amount that is entered into this WEB Payment Form.<br />By clicking on <strong>"Download Confirmation" </strong>
                    you have the ability to print or save a receipt to your computer.
                </td>

            </>;
        }

        if (this.state.InvestmentAmount > ACH_LIMIT && this.props.location.state.bankAccount !== -10 &&
            this.props.location.state.bankAccount !== -20) {
            authorizationText = <>
                <td align="right">Authorization:</td>
                <td>A representative from EnergyFunders will contact you regarding this investment and to provide funding instructions.</td>
            </>;
        }
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="Mainstep">
                            <div className="container">
                                <div className="row">
                                    <ul className="breadcrumb">
                                        <li className="completed1"><a href="javascript:void(0);">Details</a></li>
                                        <li className="completed1"><a href="javascript:void(0);">Signature</a></li>
                                        <li className="completed1"><a href="javascript:void(0);">Payment</a></li>
                                        <li className="completed1"><a href="javascript:void(0);">Confirmation</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br />
                        <h3 className='text-center'> {this.state.ProjectID == America_First_Energy_Fund_1 ? 'America First Energy Fund I' : this.state.ProjectID == Yield_Fund_2_ID ? 'Yield Fund II' : this.state.ProjectID == Bitcoin_Project_ID ? 'Bitcoin Discovery Fund' : this.state.ProjectID == Wildcat_Project_ID ? 'Drilling Fund I LLC' : this.state.ProjectID == Innovation_Fund_1 ? "Innovation Fund I" : Opportunity_Fund_1_ID ? "Opportunity Fund I" : ''} </h3>
                        <br />
                        <div className="stepContainer">
                            <div id="step-1" className="content" style={{ display: 'block' }}>

                                <span
                                    style={{
                                        'font-size': 18,
                                        'font-weight': 'bold',
                                        color: 'black',
                                    }}
                                >
                                    Confirmation
                                </span>
                                <br />
                                <br />

                                <div
                                    id="print"
                                    style={{
                                        backgroundColor: '#f5f5f5',
                                        maxWidth: '210mm',
                                        minHeight: '220mm',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="o" style={{ color: 'white' }} colSpan={2}>
                                                    Investment Summary -
                                                    Investment Confirmation
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-right" style={{ width: 220 }}>Transaction Date:</td>
                                                <td>{new Date().toLocaleDateString()}</td>

                                            </tr>
                                            <tr style={{ fontWeight: 'bold' }}>

                                                <td className="text-right">Investment Amount ($):</td>
                                                <td>
                                                    {(Number(this.props.location.state.InvestmentAmount) + Number(this.state.FeeAmount)).toLocaleString('en-US', {style: 'currency', currency: 'USD',})}
                                                </td>

                                            </tr>

                                            <HideComponent hide={!this.state.hasFee}>
                                                <tr>
                                                    <td className="text-right">Origination Fee:</td>
                                                    <td>{Number(this.state.FeeAmount).toLocaleString('en-US', {style: 'currency', currency: 'USD',})}</td>
                                                </tr>
                                            </HideComponent>

                                            <tr>
                                                <td className="text-right">Transaction Code:</td>
                                                <td>{this.state.TransactionCode}</td>
                                            </tr>

                                            {(() => {
                                                switch (this.props.location.state.ProjectType === 'RegCF') {
                                                    case true:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">Project Name:</td>
                                                                <td>{this.state.ProjectInvestment.InvestmentName}</td>
                                                            </tr>
                                                        );
                                                    case false:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">Project Name:</td>
                                                                <td>{this.state.ProjectInvestment.ProjectName}</td>
                                                            </tr>
                                                        );
                                                }
                                            })()}


                                            <tr>
                                                <td className="text-right">Investor:</td>
                                                <td>{this.state.Entity.EntityName}</td>
                                            </tr>


                                            {(() => {
                                                switch (this.props.location.state.ProjectType === 'RegCF') {
                                                    case true:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">Fund:</td>
                                                                <td>{this.state.ProjectInvestment.LegalIssuerName}</td>
                                                            </tr>
                                                        );
                                                    case false:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">Fund:</td>
                                                                <td>{this.state.ProjectInvestment.Entity}</td>
                                                            </tr>
                                                        );
                                                }
                                            })()}


                                            {(() => {
                                                if (this.props.location.state.ProjectType === 'RegCF') {
                                                    return (
                                                        <tr>
                                                            <td className="text-right">Type of Securities:</td>
                                                            <td>{this.state.ProjectInvestment.TypeOfSecurity}</td>
                                                        </tr>
                                                    );
                                                }
                                            })()}


                                            <tr>

                                                <td className="text-right">Price Paid for Investment:</td>
                                                <td>
                                                    {(Number(this.state.InvestmentAmount)).toLocaleString('en-US', {style: 'currency', currency: 'USD',})}
                                                </td>
                                            </tr>

                                            {(() => {
                                                switch (this.props.location.state.ProjectType === 'RegCF') {
                                                    case true:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">Number of Securities:</td>
                                                                <td>{this.state.InvestmentAmount / this.state.ProjectInvestment.IncrementPrice}</td>
                                                            </tr>
                                                        );
                                                    case false: {
                                                        if (this.state.ProjectID == Wildcat_Project_ID || this.state.ProjectID == Yield_Fund_2_ID || this.state.ProjectID == America_First_Energy_Fund_1 || this.state.ProjectID == Opportunity_Fund_1_ID) {
                                                            const classAValue = (this.state.InvestmentAmount * this.state.classAPercent) / 100;
                                                            const classAFee = (this.state.FeeAmount * this.state.classAPercent) / 100;
                                                            const classBValue = (this.state.InvestmentAmount * this.state.classBPercent) / 100;
                                                            const classBFee = (this.state.FeeAmount * this.state.classBPercent) / 100;
                                                            return (<>
                                                                <tr>
                                                                    <td className="text-right">{ this.state.ProjectID != Opportunity_Fund_1_ID ? "Class A Shares: " : `Class ${(Number(this.state.InvestmentAmount) >= 100000 && Number(this.state.InvestmentAmount) < 250000) ? 'A' : (Number(this.state.InvestmentAmount) >= 250000 && Number(this.state.InvestmentAmount) < 500000) ? 'B' : Number(this.state.InvestmentAmount) >= 500000 ? 'C'  : ''} Units:`}</td>
                                                                    <td>{this.state.hasFee ? ((classAValue) / 10).toLocaleString() : (Number(this.state.InvestmentAmount) / Number(this.state.ProjectInvestment.IncrementPrice)).toLocaleString()}</td>
                                                                </tr>
                                                                { this.state.ProjectID != Opportunity_Fund_1_ID ? <tr>
                                                                    <td className="text-right">{ "Class B Shares: "}</td>
                                                                    <td>{this.state.hasFee ? ((classBValue) / 10).toLocaleString() : (Number(this.state.InvestmentAmount) / Number(this.state.ProjectInvestment.IncrementPrice)).toLocaleString()}</td>
                                                                </tr> : null}
                                                            </>
                                                            );
                                                        } else if(this.state.ProjectID == Innovation_Fund_1){
                                                            return (
                                                            <tr>
                                                                    <td className="text-right">
                                                                        Class A Shares:
                                                                    </td>
                                                                    <td>
                                                                        {this.state.hasFee ? ((Number(this.state.InvestmentAmount)) / 10).toLocaleString()
                                                                            : Number(this.state.InvestmentAmount) / Number(this.state.ProjectInvestment.IncrementPrice)}
                                                                    </td>
                                                                </tr>);
                                                        } else {
                                                            return (
                                                                <tr>
                                                                    <td className="text-right">Number of Securities:</td>
                                                                    <td>{this.state.hasFee ? ((Number(this.state.InvestmentAmount)) / 10).toLocaleString() : (Number(this.state.InvestmentAmount) / Number(this.state.ProjectInvestment.IncrementPrice)).toLocaleString()}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    }
                                                }
                                            })()}


                                            {(() => {
                                                switch (this.props.location.state.ProjectType === 'RegCF') {
                                                    case true:
                                                        return (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Total Number of Securities in
                                                                        Offering:
                                                                    </td>
                                                                    <td>{this.state.ProjectInvestment.TotalNoOfSecurities}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Source, Form and Amount of
                                                                        any Renumeration Received by Intermediary:
                                                                    </td>
                                                                    <td>
                                                                        Carried interest of 5% in the entire offering and 5%
                                                                        success fee based on total amount raised by issuer.
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    case false:
                                                        return (
                                                            <tr>
                                                                <td className="text-right">
                                                                    Total Number of Securities in
                                                                    Offering:
                                                                </td>
                                                                <td>{this.state.ProjectInvestment.Units}</td>
                                                            </tr>
                                                        );
                                                }
                                            })()}
                                            <tr>
                                                <HideComponent hide={this.props.location.state.bankAccount === -10 || this.props.location.state.bankAccount === -20}>
                                                    {/* {authorizationText} */}
                                                </HideComponent>
                                                <HideComponent hide={this.state.bankAccount > 0}>
                                                    <td align="right">Payment Transfer:</td>
                                                    <td>
                                                        {' '}
                                                        Please Transfer ${this.props.location.state.InvestmentAmount} to
                                                        {this.props.location.state.bankAccount === -10 && <div>
                                                            BTC Address: {this.state.btcAddress}<br />
                                                            {this.state.QRCode && <React.Fragment>
                                                                <img src={`${this.state.QRCode}`} style={{ margin: '5px 0 0 10px' }} />
                                                            </React.Fragment>}<br />
                                                        </div>}

                                                        {this.props.location.state.bankAccount === -20 && <div>
                                                            ETH Address: {this.state.ethAddress}<br />
                                                            {this.state.QRCode && <React.Fragment>
                                                                <img src={`${this.state.QRCode}`} style={{ margin: '5px 0 0 10px' }} />
                                                            </React.Fragment>}<br />
                                                        </div>}
                                                    </td>
                                                </HideComponent>
                                            </tr>
                                            {this.state.thirdPartyAccredited != 1 && <tr>
                                                    <td colSpan={2}>
                                                    In order to remain compliant with SEC rules, you must independently verify your accreditation for yourself and/or your investment entity (Trust, LLC, etc.) through our partner, Parallel Markets. Your investment cannot be finalized until this step is complete.
                                                    Please begin the accreditation verification process from Account Settings
                                                     {/* Please click the Parallel Passport button below to begin the accreditation verification process.  */}
                                                    <div style={{ textAlign: 'center' }}>
                                                    {/* <div className="btn btn-link pt-1 mt-3"><PassportButton showCancelButton={true} /></div> */}
                                                            <div className="btn btn-link pt-1" onClick={() =>this.props.history.push('/app/Profile')}>Go to Account Settings</div>
                                                    </div>
                                                    </td>
                                                </tr>}
                                            <tr>
                                                <td colSpan={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={this.onPrint}
                                                            style={{ backgroundColor: '#5bb462' }}
                                                        >
                                                            Download Confirmation
                                                        </button>
                                                        <br />
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={this.onNext}
                                                            style={{ backgroundColor: '#212529' }}
                                                        >
                                                            {' '}
                                                            Show More Investment Opportunities
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                            <HideComponent hide={this.props.location.state.bankAccount === -10 || this.props.location.state.bankAccount === -20
                                                || this.state.InvestmentAmount > ACH_LIMIT
                                            }>
                                                <tr>
                                                    <td colSpan={2}>
                                                        In the case of an ACH Transaction being rejected for Non Sufficient
                                                        Funds (NSF)
                                                        I understand that Dwolla may at its discretion attempt to process the charge
                                                        again within 30 days,
                                                        and agree to an additional $3.00 charge for each attempt returned NSF.
                                                        I acknowledge that the origination of ACH transactions to my account must comply
                                                        with the provisions of U.S. law.
                                                        I certify that I am an authorized user of this bank account and will not dispute
                                                        this transaction with my bank;
                                                        so long as the transaction corresponds to the terms indicated in this
                                                        authorization form.
                                                        I understand that for any items that are disputed there will be a fee assessed
                                                        in the amount of $25.00.
                                                    </td>
                                                </tr>
                                            </HideComponent>
                                            <HideComponent hide={this.props.location.state.bankAccount > 0}>
                                                <tr>
                                                    <td colSpan={2}>
                                                        I confirmed I have initiated the crypto transaction.
                                                    </td>
                                                </tr>
                                            </HideComponent>

                                        </tbody>
                                    </table>
                                </div>



                                <br />
                            </div>
                            <div className="done" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const { object, bool } = PropTypes;

Invest3.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

export default withRouter(withParallelMarketsHOC(connect(mapState, mapDispatchToProps)(Invest3)));
