import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icon from '../IconComponent/IconComponent';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import * as Actions from './actions/actions';
import HideComponent from '../HideComponent/HideComponent';
import { formatPhoneNumber } from '../../../utils/General';
import RecordBusinessInfoComponent from './components/RecordBusinessInfoComponent';
import DwollaDocUpload from './components/DwollaDocUpload';
import {
    ENTITY_VERIFY_BUSINESS_AND_WITH_DOC, ENTITY_VERIFY_BUSINESS_WITH_DOC,
    ENTITY_VERIFY_CONTROLLER_WITH_DOC,
} from '../../../utils/ApplicationConstants';
import RequestService from '../../../api/RequestService';
import { getImpersonateID  } from "../../../utils/General";

class NewEntityContainerComponent extends React.Component {
    NOT_REQUIRED_FOR_VERIFICATION = ['ein', 'businessCategories', 'businessClassification', 'investorJobTitle'];

    NOT_REQUIRED_FOR_RD_IRA = ['businessCategories', 'businessClassification', 'investorJobTitle'];
    NOT_REQUIRED_FOR_OTHER_IRA = ['businessCategories', 'businessClassification', 'investorJobTitle'];

    INITIAL_STATE = {
        step: 1,
        modalTitle: null,
        verifyBusiness: false,
        allowModalClose: true,
        createdEntityId: null,
        verifyController: false,
        details: {
            entityType: '',
            entityName: '',
            legalFirstName: '',
            legalLastName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            phone: '',
            country: '',
            questionAnswer: '',
        },
        entityVerificationDocs: {
            documentType: '',
            controllerFile: null,
            businessFiles: [],
        },
        entityVerificationInfo: {
            ein: '',
            ssn: '',
            dob: '',
            ownerType: '1',
            investorJobTitle: '',
            businessCategories: '',
            businessClassification: '',
            certify: false,
        },
		isFinancialAdvisor: false
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE,
            step: props.stepNumber,
        };
    }



    componentDidMount() {
        const { getDwollaBusinessCategories } = this.props;

        getDwollaBusinessCategories();
		this.isFinancialAdvisor();
    }



    componentDidUpdate(prevProps) {
        const {
            createdEntityId, entityType,
            verifyController, verifyBusiness,
        } = this.props;
        const _createdEntityId = prevProps.createdEntityId;
        const _entityType = prevProps.entityType;
        const _verifyBusiness = prevProps.verifyBusiness;
        const _verifyController = prevProps.verifyController;

        if (createdEntityId !== _createdEntityId) {
            this.setState({ createdEntityId });
        }

        if (_verifyBusiness !== verifyBusiness) {
            this.setState({ verifyBusiness });
        }

        if (_verifyController !== verifyController) {
            this.setState({ verifyController });
        }

        if (_entityType !== entityType) {
            this.setSelectedEntityType();
        }
    }



	async isFinancialAdvisor() {
        const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/getUserRoles/${impersonateID}`);

		if (data.length > 0) {
			data.forEach(element => {
				if (element.Role === 'FinancialAdvisor') {
					this.setState({ isFinancialAdvisor: true });
				}
			});
		}
	}



    onInputChange = (event, objName = 'details') => {
        const { [objName]: details } = this.state;
        const _details = { ...details };
        const { name, value: _value } = event.target;
        let value = _value;

		if (name === 'entityType' && value === 'Personal') {
			window.location = '/app/createNewUserEntity';
			// this.props.history.push({
			// 	pathname: '/app/VerifyDwolla',
			// 	state: {
			// 		'entity': 'newEntity'
			// 	}
			// });
		}

        if (name === 'phone') {
            value = formatPhoneNumber(_value);
        }

        if (objName === 'entityVerificationInfo' && name === 'businessCategories') {
            _details.businessClassification = '';
        }

        _details[name] = value;

        this.setState({ [objName]: _details });
    };



    setSelectedEntityType = () => {
        const { entityType } = this.props;
        const { details } = this.state;
        const detailsCopy = { ...details };

        detailsCopy.entityType = entityType;

        this.setState({ details: detailsCopy });
    };

    setSelectedAddressInfo = (addressInfo) => {
        const { details } = this.state;
        const _details = { ...details };

        const {
            administrative_area_level_1: state, country,
            locality: city, postal_code: zipCode, fullAddress,
        } = addressInfo;

        _details.city = this.getFromObj(city, 'long');
        _details.state = this.getFromObj(state, 'short');
        _details.country = this.getFromObj(country, 'long');
        _details.zipCode = this.getFromObj(zipCode, 'long');
        _details.address = fullAddress.split(',')
            .shift();

        this.setState({ details: _details });
    };

    getFromObj = (obj, key) => {
        if (!obj) {
            return null;
        }

        return obj[key];
    };

    onVerifyEntity = () => {
        this.refs.verifyNewEntityButton.disabled = true;

        const {
            isAdmin, verifyEntityEntity, investorId, isVerification,entityDetails
          
        } = this.props;
        const { createdEntityId,details, entityVerificationInfo: businessData } = this.state;
    
        const entityAddress = entityDetails && Object.keys(entityDetails).length > 0 ? entityDetails:details;

        const verificationInfo = {
            isVerification,
            entityAddress,
            businessData,
        };
   

            this.setState({ allowModalClose: true }, () => verifyEntityEntity(isAdmin, investorId,
                createdEntityId, verificationInfo, this.onCloseModalAndResetValues, this.goToFileVerificationStep));
   
        };

    onCreateNewEntity = () => {
        const { isAdmin, investorId, createNewEntity } = this.props;
        const { details } = this.state;

        createNewEntity(isAdmin, investorId, details, this.onVerifyNewEntity);
    };

    goToFileVerificationStep = (requiredDocs) => {
        let verifyController = false;
        let verifyBusiness = false;

        Object.keys(requiredDocs)
            .forEach((key) => {
                if (key === ENTITY_VERIFY_BUSINESS_WITH_DOC) {
                    verifyBusiness = true;
                }
                if (key === ENTITY_VERIFY_CONTROLLER_WITH_DOC) {
                    verifyController = true;
                }
                if (key === ENTITY_VERIFY_BUSINESS_AND_WITH_DOC) {
                    verifyController = true;
                    verifyBusiness = true;
                }
            });

        this.setState({
            step: 4,
            verifyBusiness,
            verifyController,
        });
    };

    onEntityControllerFileVerificationSelected = (selectedFile) => {
        const { entityVerificationDocs } = this.state;
        const copy = { ...entityVerificationDocs };

        copy.controllerFile = selectedFile;
        this.setState({ entityVerificationDocs: copy });
    };

    onEntityControllerFileType = ({ target }) => {
        const { entityVerificationDocs } = this.state;
        const copy = { ...entityVerificationDocs };
        const { value } = target;

        copy.documentType = value;
        this.setState({ entityVerificationDocs: copy });
    };

    onEntityBusinessFileVerificationSelected = (selectedFile) => {
        const { entityVerificationDocs, verifyController } = this.state;
        const copy = { ...entityVerificationDocs };

        copy.businessFiles = [...copy.businessFiles, ...selectedFile];
        const { length } = copy.businessFiles;

        if (verifyController) {
            copy.businessFiles.splice(3, length);
        } else {
            copy.businessFiles.splice(4, length);
        }

        this.setState({ entityVerificationDocs: copy });
    };

    onRemoveSelectedBusinessVerificationFile = (file) => {
        const { entityVerificationDocs } = this.state;
        const copy = { ...entityVerificationDocs };
        const fileIndex = copy.businessFiles.indexOf(file);

        copy.businessFiles.splice(fileIndex, 1);
        this.setState({ entityVerificationDocs: copy });
    };

    onUploadVerificationFiles = () => {
        const { uploadEntityVerificationDocument, isAdmin, investorId } = this.props;
        const { createdEntityId } = this.state;
        const payload = this.createPayload();

        uploadEntityVerificationDocument(isAdmin, investorId, createdEntityId, payload, this.onCloseModalAndResetValues);
    };

    createPayload = () => {
        const { entityVerificationDocs } = this.state;
        const { controllerFile, businessFiles, documentType } = entityVerificationDocs;
        const formData = new FormData();
        const mappedFileTypes = {};

        if (controllerFile) {
            const { type, name, size } = controllerFile;

            formData.append('controllerFile', controllerFile, controllerFile.name);
            mappedFileTypes[name] = {
                type, name, size, documentType,
            };
        }

        if (businessFiles && businessFiles.length) {
            businessFiles.forEach((file) => {
                const { type, name, size } = file;

                formData.append('businessFiles', file, file.name);
                mappedFileTypes[name] = {
                    type, name, size, documentType: 'other',
                };
            });
        }

        formData.append('fileTypes', JSON.stringify(mappedFileTypes));
        return formData;
    };

    onVerifyNewEntity = (entityId) => {
        this.setState({
            step: 3,
            allowModalClose: false,
            createdEntityId: entityId,
        });
    };

    onCloseModalAndResetValues = () => {
        const { allowModalClose } = this.state;
        const { stepNumber, createdEntityId, entityType } = this.props;
        const { onCloseModal, parentUpdateMethod, isVerification } = this.props;

        const initialState = { ...this.INITIAL_STATE };

        if (!allowModalClose) {
            // doesn't allow the user to close the modal
            // until they finish the validation process
            return;
        }

        if (isVerification) {
            initialState.step = stepNumber;
            initialState.createdEntityId = createdEntityId;
            initialState.details.entityType = entityType;
        }

        this.setState(initialState);
        onCloseModal();
        parentUpdateMethod();
    };

    validateFirstStepEntityInputs = () => {
        const { details } = this.state;
        const requiredKey = ['entityType', 'entityName', 'address', 'city', 'state', 'zipCode', 'phone', 'country'];

        if (this.isRocketDollarIRA()) {
            return false;
        }

        return requiredKey
            .map(key => (details[key] === '' || details[key] === null || details[key] === undefined))
            .some(item => item);
    };

    validateSecondStepEntityInputs = () => {
        const { details } = this.state;
        const requiredKey = ['questionAnswer'];

        return requiredKey
            .map(key => (details[key] === '' || details[key] === null || details[key] === undefined))
            .some(item => item);
    };

    validateEntityVerification = () => {
        const { entityVerificationInfo } = this.state;
        let notRequiredList = [];

        if (this.isTrust()) {
            notRequiredList = this.NOT_REQUIRED_FOR_VERIFICATION;
        } else if (this.isRocketDollarIRA()) {
            notRequiredList = this.NOT_REQUIRED_FOR_RD_IRA;
        } else if (this.isOtherIRA()) {
            notRequiredList = this.NOT_REQUIRED_FOR_OTHER_IRA;
        }


        return Object.keys(entityVerificationInfo)
            .filter(key => notRequiredList.indexOf(key) === -1)
            .map(key => entityVerificationInfo[key])
            .map(item => !item || item === '')
            .some(boolean => boolean);
    };



    isTrust = () => {
        const { details } = this.state;
        const { entityType } = details;

        return entityType.toLowerCase()
            .startsWith('trust');
    };



    isRocketDollarIRA = () => {
        const { details } = this.state;
        const { entityType } = details;

        return (entityType === 'Rocket Dollar IRA');
    };

    isOtherIRA = () => {
        const { details } = this.state;
        const { entityType } = details;

        return (entityType === "IRA or Other Retirement Account");
    };

    isLLC_Corp_Part = () => {
        const { details } = this.state;
        const { entityType } = details;

        return (entityType === 'LLC' || entityType === 'Partnership' || entityType === 'Corporation');
    };
    isLLC = () => {
        const { details } = this.state;
        const { entityType } = details;

        return (entityType.toLowerCase() === 'llc');
    };


    setTitle = (title, stepNumber) => {
        const { step, modalTitle } = this.state;

        if (step === stepNumber && modalTitle !== title) {
            this.setState({ modalTitle: title });
        }
    };

    // STEP MANAGER
    goToNextStep = () => {
        const { step } = this.state;
        const newStep = (step + 1);

        this.setState({ step: newStep });
    };

    goToPreviousStep = () => {
        const { step } = this.state;
        const newStep = (step - 1);

        this.setState({ step: newStep });
    };

    // RENDER MANAGER
    renderFirstStep = () => {
        const title = 'Step 1';
        const { step, details } = this.state;

        if (step !== 1) {
            return null;
        }

        this.setTitle(title, 1);

        return (
            <FirstStep
                {...details}
                onInputChange={this.onInputChange}
                isRocketDollarIRA={this.isRocketDollarIRA()}
                isOtherIRA={this.isOtherIRA}
                onSetSelectedAddressInfo={this.setSelectedAddressInfo}
				isFinancialAdvisor={this.state.isFinancialAdvisor}
            />
        );
    };

    renderSecondStep = () => {
        const title = 'Step 2';
        const { step, details } = this.state;

        if (step !== 2) {
            return null;
        }

        this.setTitle(title, 2);

        return (
            <SecondStep
                entityType={details.entityType}
                onInputChange={this.onInputChange}
                questionAnswer={details.questionAnswer}
            />
        );
    };

    renderThirdStep = () => {
        const title = 'Step 3: Entity Verification';
        const { businessCategories, mappedClassifications, dwollaStatus, legalFN, legalLN } = this.props;
        const { step, entityVerificationInfo } = this.state;

        if (step !== 3) {
            return null;
        }

        this.setTitle(title, 3);

        return (
            <RecordBusinessInfoComponent
                {...entityVerificationInfo}
                isTrustEntity={this.isTrust()}
                onInputChange={this.onInputChange}
                businessCategoriesList={businessCategories}
                isRocketDollarIRA={this.isRocketDollarIRA()}
                isOtherIRA={this.isOtherIRA}
                businessClassificationsList={mappedClassifications}
                isLLC_Corp_Part={this.isLLC_Corp_Part()}
                dwollaStatus={dwollaStatus}
                legalFN={legalFN}
                legalLN={legalLN}
            />
        );
    };

    renderFourthStep = () => {
        const title = 'Step 4: Upload Entity Verification Documents';
        const {
            step, verifyBusiness, verifyController,
            entityVerificationDocs, details,
        } = this.state;

        if (step !== 4) {
            return null;
        }

        this.setTitle(title, 4);

        return (
            <DwollaDocUpload
                entityType={details.entityType}
                verifyBusiness={verifyBusiness}
                verifyController={verifyController}
                entityVerificationDocs={entityVerificationDocs}
                onRemoveBusinessFile={this.onRemoveSelectedBusinessVerificationFile}
                onBusinessInputChange={this.onEntityBusinessFileVerificationSelected}
                onControllerInputChange={this.onEntityControllerFileVerificationSelected}
                onEntityControllerFileType={this.onEntityControllerFileType}
            />
        );
    };

    renderFirstStepButton = () => {
        const { step } = this.state;

        if (step !== 1) {
            return null;
        }

        return (
            <button
                className="btn btn-default capitalize-text"
                disabled={this.validateFirstStepEntityInputs()}
                onClick={this.goToNextStep}
                type="button"
                style={{ marginLeft: 10 }}
            >
                Next Step
                {' '}
                <Icon iconName="chevron-right" />
            </button>
        );
    };

    renderSecondStepButtons = () => {
        const { step } = this.state;

        if (step !== 2) {
            return null;
        }

        return (
            <React.Fragment>
                <button
                    className="btn btn-default capitalize-text"
                    onClick={this.goToPreviousStep}
                    type="button"
                    style={{ marginLeft: 10 }}
                >
                    <Icon iconName="chevron-left" />
                    {' '}
                    Previous Step
                </button>
                <button
                    disabled={this.validateFirstStepEntityInputs() || this.validateSecondStepEntityInputs()}
                    className="btn btn-primary capitalize-text"
                    onClick={this.onCreateNewEntity}
                    type="button"
                    style={{ marginLeft: 10 }}
                >
                    <Icon iconName="plus" extraClasses="white-text" />
                    {' '}
                    Create New Entity
                </button>
            </React.Fragment>
        );
    };

    renderThirdStepButton = () => {
        const { step } = this.state;

        if (step !== 3) {
            return null;
        }

        return (
            <button
                ref="verifyNewEntityButton"
                disabled={this.validateEntityVerification()}
                className="btn btn-primary capitalize-text"
                onClick={this.onVerifyEntity}
                style={{ marginLeft: 10 }}
                type="button"
            >
                <Icon iconName="check" extraClasses="white-text" />
                {' '}
                Verify New Entity
            </button>
        );
    };

    renderFourthStepButton = () => {
        const { step } = this.state;

        if (step !== 4) {
            return null;
        }

        return (
            <React.Fragment>
                <button
                    disabled={false}
                    className="btn btn-default capitalize-text"
                    onClick={this.onCloseModalAndResetValues}
                    style={{ marginLeft: 10 }}
                    type="button"
                >
                    <Icon iconName="times" />
                    {' '}
                    Cancel and Upload Files Later
                </button>

                <button
                    disabled={false}
                    className="btn btn-primary capitalize-text"
                    // onClick={this.onUploadVerificationFiles}
					onClick={() => window.location = '/app/UploadDwollaDocument'}
                    style={{ marginLeft: 10 }}
                    type="button"
                >
                    <Icon iconName="upload" extraClasses="white-text" />
                    {' '}
                    Go to Document Uploader
                </button>
            </React.Fragment>
        );
    };

    render = () => {
        const { step, modalTitle } = this.state;
        const { isModalOpen } = this.props;

        return (
            <Modal
                className="no-margin"
                show={isModalOpen}
                onHide={this.onCloseModalAndResetValues}
            >
                <Modal.Header closeButton>
                    <h3 className="heading-text capitalize-text">
                        Create a New Entity:
                        {' '}
                        {modalTitle}
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    {this.renderFirstStep()}
                    {this.renderSecondStep()}
                    {this.renderThirdStep()}
                    {this.renderFourthStep()}
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-xs-12">
                        <HideComponent hide={step === 3 || step === 4}>
                            <div className="pull-left">
                                <button
                                    className="btn btn-danger capitalize-text"
                                    onClick={this.onCloseModalAndResetValues}
                                    type="button"
                                >
                                    <Icon iconName="times" extraClasses="white-text" />
                                    {' '}
                                    Cancel
                                </button>
                            </div>
                        </HideComponent>
                        <div className="pull-left">
                            {this.renderFirstStepButton()}
                            {this.renderSecondStepButtons()}
                            {this.renderThirdStepButton()}
                            {this.renderFourthStepButton()}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}

NewEntityContainerComponent.propTypes = {
    businessCategories: PropTypes.array.isRequired,
    createNewEntity: PropTypes.func.isRequired,
    createdEntityId: PropTypes.number,
    entityType: PropTypes.string,
    getDwollaBusinessCategories: PropTypes.func.isRequired,
    investorId: PropTypes.number,
    isAdmin: PropTypes.bool,
    isModalOpen: PropTypes.bool.isRequired,
    isVerification: PropTypes.bool,
    mappedClassifications: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    parentUpdateMethod: PropTypes.func.isRequired,
    stepNumber: PropTypes.number,
    uploadEntityVerificationDocument: PropTypes.func.isRequired,
    verifyBusiness: PropTypes.bool,
    verifyController: PropTypes.bool,
    verifyEntityEntity: PropTypes.func.isRequired,
};

NewEntityContainerComponent.defaultProps = {
    businessCategories: [],
    createNewEntity: () => null,
    createdEntityId: null,
    entityType: '',
    getDwollaBusinessCategories: () => null,
    investorId: null,
    isAdmin: false,
    isModalOpen: false,
    isVerification: false,
    mappedClassifications: {},
    onCloseModal: () => null,
    parentUpdateMethod: () => null,
    stepNumber: 1,
    uploadEntityVerificationDocument: () => null,
    verifyBusiness: false,
    verifyController: false,
    verifyEntityEntity: () => null,
};

const mapStateToProps = _state => ({ ..._state.newEntity });
const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewEntityContainerComponent);
