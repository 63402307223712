import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Wildcat_Project_ID } from '../../utils/General';

class DrillingFund1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			selectedProjectCategory: "all",
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0, 0);
	}

	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
		const documentIcon = "/img/document.svg";
		const ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		const wildcatFundOverview = "/img/investmentopportunities/wildcat-fund-overview.png";
		const wildcatFundSummary = "/img/investmentopportunities/wildcat-fund-summary.png";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>EnergyFunders Preferred Equity Drilling Fund I LLC</h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
              <h3>Preferred Equity Drilling Fund</h3>
								<p>
                Support American energy production, protect your portfolio from future inflation and potentially enjoy some of the best tax deductions in the US tax code.
                </p>
                <h3>What is the Preferred Equity Drilling Fund?</h3>
								<p>
                This is a private equity fund that is acquiring preferred equity ownership in private market oil and gas developments in the United States. We are blending the potential for strong mid teen IRRs with three distinct risk mitigation measures. First, by targeting PUDs we significantly decrease our downside risk by participating in wells that have a much higher likelihood of being recoverable under current economic conditions and technological practices. Second, by taking a preferred equity position in the capital stack, the fund's position in a well has seniority over the original working interest owner  when distributions are made. In the event a well is underperforming, this can give us a much better opportunity to achieve our fund IRR targets. Third, by allocating capital into a diverse collection of PUDs, we aim to minimize any one-off operational or geological risk.
                <br /><br />
                Finally, investors in this fund may benefit from some of the best tax advantages available in the US Tax code being it exclusively targets drilling opportunities. You can learn more about the tax advantages here and as always, we strongly encourage you to discuss any potential tax benefits with a tax professional.
                <br /><br />
                Learn about the potential tax breaks <a href="https://www.energyfunders.com/images/documents/EFEbook-Tax-Benefitsof-Oil-and-Gas-Investing.pdf" target="_blank">here</a>
                </p>  
                <h3>Who We Are</h3>
								<p>Our vertically integrated in house team brings together decades of experience in geology, petroleum engineering, financial analysis, asset management and technological development. This gives us the ability to swiftly analyze and diligence any private market opportunity that may come through our professional network, giving us the edge to ultimately extract value where others may miss it.
                </p>  
                <h3>How the Fund Works</h3>
								<p>
                Our team has a strong pipeline of off-market or private oil and gas deals that we are constantly analyzing and running diligence on. Once we have identified a target we would like to invest in, we deploy capital into the project via a preferred equity arrangement known as a Drillco agreement. This ensures seniority with respect to the first 15% return on the opportunity while still participating at a split (usually between 10-25%) for the remaining upside in the project.
                <br /><br />
                We continue this process until all capital is deployed. After drilling and completion is done for each well in the portfolio, distributions are made to investors for the life of the fund. Please keep in mind, we may continue to reinvest distributions into other opportunities in an effort to further grow the Fund’s asset base in hopes of ultimately maximizing investor returns.
                <br /><br />
                As the fund's term draws near, we plan to begin divesting in these assets to other buyers in the market. At this point, the now producing assets would be considered "PDP" (Proved Developed Producing), and the value would be based on a combination of oil prices, reserves forecasts, and other market drivers such as interest rates.
                <br /><br />
                Once asset divestment occurs, we intend to begin returning all capital to our investors.
                </p>                           
							</div>
							<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-left py-3 px-3">
												<ReactSVG src="/img/investmentopportunities/oil.svg" />
												<span className="pl-4">
													100% Oil &amp; Gas
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={() => this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Wildcat_Project_ID,

												}
											})

											}
										>
											Start Investing Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>5-8 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>Periodic</span> Payments
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>Moderate</span> Risk Level
								</li>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/chart.svg" />
									<span></span>
								</li> */}
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$50,000</span> Minimum Investment
								</li>

								<li>
									<ReactSVG src="/img/investmentopportunities/taxes.svg" />
									<span>Tax Savings</span> Potential
									Deductions Against Active Income
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li> */}
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/exclusive.svg" />
									<span>Exclusive Access</span> Private Market
									Opportunities
								</li> */}
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							{/* <div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												3.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$500,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div> */}
							<div className="col-md-12">
              <h4 className="mt-0">
									Origination Fee{" "}
								</h4>
								<p>
                2% of the amount invested by each Investor.
								</p>
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								<h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 12%. And second, 80% to Investors
									and 20% to the holder of the Class C Shares.
								</p>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4"><a href="/documents/pedrillingfund/LLCAgreement-PreferredEquityDrillingFundILLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4"><a href="/documents/pedrillingfund/DisclosureDocument-PreferredEquityDrillingFundILLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${agreement})` }}></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4"><a href="/documents/pedrillingfund/InvestmentAgreement-PreferredEquityDrillingFundILLC-Final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								{/* <li>
									<div className="img-wrapper" style={{ backgroundImage: `url(${wildcatFundSummary})` }}></div>
									<h3>Drilling Fund I LLC Two-page Summary</h3>
									<div className="mt-4"><a href="/documents/pedrillingfund/drillingfund-2-pager.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li> */}
							</ul>
						</div>
						{/* <OurTeam /> */}
						<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
							<div className="pull-left d-flex align-items-center left-sec"><ReactSVG src={ProjectUpdatesIcon} /> <span>EnergyFunders Preferred Equity Drilling Fund I LLC</span></div>
							<div className="pull-right rt-sec"><button className="btn btn-primary  px-5 py-4 ml-3 font-bold" onClick={() => this.props.history.push({
								pathname: '/app/invest',
								state: {
									ProjectType: 'RegD',
									ProjectID: Wildcat_Project_ID,

								}
							})

							}>
								Start Investing Today
							</button></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

DrillingFund1.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(DrillingFund1);

export default withRouter(connectedComponent);
