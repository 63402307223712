import PropTypes from 'prop-types';
import React from 'react';
import SelectComponent from '../InputComponents/SelectComponent/SelectComponent';
import SingleLineInputComponent from '../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import MultilineInputComponent from '../InputComponents/MultilineInputComponent/MultilineInputComponent';
import {
  PROJECT_REG_CF,
  PROJECT_REG_D,
  YEAR_MONTHS,
} from '../../../utils/ApplicationConstants';
import Icon from '../IconComponent/IconComponent';
import NotificationService from '../../../utils/NotificationService';
import {
  convertObjectToArray,
  yearSelectGenerator,
} from '../../../utils/General';

class FileDetailsEditorComponent extends React.Component {
  K1_TAX_FILE_ID = 1;
  CERTIFICATES = 3;
  MONTHLY_STATEMENT_FILE_ID = 4;

  MESSAGE_DISPLAY_MIL_SEC = 6000;

  FILE_YEARS = yearSelectGenerator(2014, new Date().getFullYear());

  PROJECT_TYPES = [
    {
      id: PROJECT_REG_D,
      title: 'Reg D Project',
    },
    {
      id: PROJECT_REG_CF,
      title: 'Reg CF Project',
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      docYear: '',
      fileType: 0,
      docMonth: '',
      projectType: '',
      docDescription: '',
      selectedProjectId: 0,
      displayNotes: '',
      privateNotes: '',
      selectedFile: null,
    };

    this.refFileInput = React.createRef();
  }

  componentDidMount() {
    this.setStateInfo();
  }

  componentDidUpdate(prevProps) {
    const _fileId = prevProps.fileId;
    const { fileId } = this.props;

    if (_fileId !== fileId) {
      this.setStateInfo();
    }
  }

  setStateInfo = () => {
    const { fileToEdit } = this.props;
    const {
      docYear,
      fileType,
      docMonth,
      projectType,
      docDescription,
      selectedProjectId,
      displayNotes,
      privateNotes,
    } = fileToEdit;

    this.setState({
      docYear,
      fileType,
      docMonth,
      projectType,
      docDescription,
      selectedProjectId,
      displayNotes,
      privateNotes,
    });
  };

  onInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  onSaveChanges = () => {
    const { isMassUpload } = this.props;

    if (isMassUpload) {
      this.saveDataAsIs();
    } else {
      this.saveFileUpdate();
    }
  };

  saveDataAsIs = () => {
    const {
      docYear,
      fileType,
      docMonth,
      projectType,
      docDescription,
      displayNotes,
      privateNotes,
      selectedProjectId,
    } = this.state;
    const { onSaveChanges } = this.props;

    if (this.verifyMissingFields(fileType)) {
      return;
    }

    onSaveChanges({
      docYear,
      fileType,
      docMonth,
      projectType,
      privateNotes,
      displayNotes,
      docDescription,
      selectedProjectId,
    });
  };

  saveFileUpdate = () => {
    const { onSaveChanges } = this.props;
    const fileData = { ...this.state };
    const {
      fileType,
      docYear,
      docMonth,
      projectType,
      selectedProjectId,
      selectedFile,
    } = fileData;
    const isK1TaxFile = parseInt(fileType) === this.K1_TAX_FILE_ID;
    const isCertificate = parseInt(fileType) === this.CERTIFICATES;
    const isMonthlyFile = parseInt(fileType) === this.MONTHLY_STATEMENT_FILE_ID;

    if (this.verifyMissingFields(fileType)) {
      return;
    }

    fileData.docYear = !isK1TaxFile && !isMonthlyFile ? '' : docYear;
    fileData.docMonth = !isMonthlyFile ? '' : docMonth;
    fileData.projectType = !isK1TaxFile && !isCertificate ? '' : projectType;
    fileData.selectedProjectId =
      !isK1TaxFile && !isCertificate ? 0 : selectedProjectId;

    onSaveChanges(fileData, selectedFile);
  };

  verifyMissingFields = (fileType) => {
    const isTaxFile = parseInt(fileType) === this.K1_TAX_FILE_ID;
    const isMonthlyFile = parseInt(fileType) === this.MONTHLY_STATEMENT_FILE_ID;

    let requiredFields = [];
    let errorMessage = '';

    if (isTaxFile) {
      requiredFields = ['docYear', 'projectType', 'selectedProjectId'];
      errorMessage =
        'You have to fill the following fields: \n' +
        'Project type, Doc Year and you have to select a project';
    } else if (isMonthlyFile) {
      requiredFields = ['docYear', 'docMonth'];
      errorMessage =
        'You have to fill the following fields: \n' +
        'Project type and the Doc Year';
    }

    if (!isTaxFile && !isMonthlyFile) return false;

    return this.hasMissingFields(requiredFields, errorMessage);
  };

  hasMissingFields = (requiredFields, errorMessage) => {
    const stateCopy = { ...this.state };

    const result = Object.keys(stateCopy)
      .filter((key) => requiredFields.indexOf(key) !== -1)
      .map((key) => stateCopy[key])
      .map((item) => !item || item === '' || item === 0)
      .some((_bool) => _bool);

    if (result) {
      NotificationService.warning(errorMessage, this.MESSAGE_DISPLAY_MIL_SEC);
    }

    return result;
  };

  onFileInputChange = (event) => {
    const { files } = event.target;

    const _files = convertObjectToArray(files)
      .filter(this.isCorrectSize)
      .filter(this.isAllowedFileType);

    if (_files.length !== files.length) {
      NotificationService.warning(
        'Remember that the files must be 32MB of size or less and we ' +
          'only accept this files types:\n"PDF", "DOC", "DOCX", "XLS", "XLSX", ' +
          '"PPT", "PPTX", "GIF", "JPG", "PNG" and "TXT"',
        10000
      );
    }

    const [selectedFile] = _files;

    this.setState({ selectedFile });
    this.refFileInput.current.value = null;
  };

  isAllowedFileType = (file) => {
    const acceptedFileTypes = [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'gif',
      'jpg',
      'png',
      'txt',
    ];
    const type = file.name.split('.').pop();

    return acceptedFileTypes.some((fType) => fType === type);
  };

  isCorrectSize = (file) => {
    const MAX_MB_PER_FILE = 32;
    const fileSize = file.size;
    const result = parseInt(fileSize / 1024 / 1000);

    return MAX_MB_PER_FILE >= result;
  };

  removeNewlySelectedFile = () => {
    this.setState({ selectedFile: null });
  };

  // RENDER MANAGER
  renderStoredFileInputUpdate = () => {
    const { editStoredFiles } = this.props;
    const { selectedFile } = this.state;

    const hasSelectedFile = !!selectedFile;

    if (!editStoredFiles) return null;

    return (
      <div className="pull-right">
        {!hasSelectedFile && (
          <label
            className="btn btn-default no-margin capitalize-text"
            htmlFor="fileUpdateInput"
          >
            Change Document
            <input
              type="file"
              multiple={false}
              id="fileUpdateInput"
              ref={this.refFileInput}
              onChange={this.onFileInputChange}
              className="no-display"
            />
          </label>
        )}
        {hasSelectedFile && (
          <button
            className="btn btn-danger no-margin capitalize-text"
            onClick={this.removeNewlySelectedFile}
            type="button"
          >
            <Icon iconName="times" />
            &ensp; remove file
          </button>
        )}
      </div>
    );
  };

  render() {
    const {
      investorFileTypes,
      selectedFileName,
      onCancelFileEdit,
      regCfProjectList,
      regDProjectList,
      isMassUpload,
    } = this.props;

    const {
      fileType,
      docDescription,
      docYear,
      docMonth,
      projectType,
      selectedProjectId,
      displayNotes,
      privateNotes,
      selectedFile,
    } = this.state;

    let selectedProjectList = [];

    if (projectType === PROJECT_REG_D) {
      selectedProjectList = regDProjectList;
    } else if (projectType === PROJECT_REG_CF) {
      selectedProjectList = regCfProjectList;
    }

    selectedProjectList.sort((a, b) => a.title.localeCompare(b.title));

    const isK1File = parseInt(fileType) === this.K1_TAX_FILE_ID; // eslint-disable-line
    const isCertificate = parseInt(fileType) === this.CERTIFICATES; // eslint-disable-line
    const isMonthlyStatement =
      parseInt(fileType) === this.MONTHLY_STATEMENT_FILE_ID; // eslint-disable-line

    const docYearClass = `col-xs-12 ${isK1File ? 'col-md-4' : 'col-md-3'}`;
    const selectComponentSize = isK1File ? 4 : isMonthlyStatement ? 2 : 6; // eslint-disable-line
    const docDescriptionClass = `col-xs-12 ${
      isMonthlyStatement || isK1File ? 'col-md-4' : 'col-md-6'
    }`;

    const mewFileName = (
      <span>
        <strong>
          New File Name: &ensp;
          {selectedFile && selectedFile.name}
        </strong>
      </span>
    );

    let fileName = !selectedFile
      ? `Edit File: ${selectedFileName}`
      : mewFileName;

    fileName = isMassUpload
      ? 'Filename must contain the UserID followed by a dash at the front of the filename. All data set here will be applied to all documents uploaded in this batch.'
      : fileName;

    return (
      <fieldset className="col-xs-12">
        <legend>
          {fileName}
          {this.renderStoredFileInputUpdate()}
        </legend>
        <div className="container">
          <div className="col-xs-12">
            <div className="row">
              <SelectComponent
                xs={12}
                md={selectComponentSize}
                name="fileType"
                onChange={this.onInputChange}
                options={investorFileTypes}
                selectTitle="file type"
                value={fileType}
              />

              <div className={docDescriptionClass}>
                <SingleLineInputComponent
                  elementValue={docDescription}
                  inputName="docDescription"
                  inputTitle="doc descr"
                  maxInputLength={255}
                  onInputUpdate={this.onInputChange}
                />
              </div>

              {(isK1File || isMonthlyStatement) && (
                <div className={docYearClass}>
                  <SelectComponent
                    name="docYear"
                    onChange={this.onInputChange}
                    options={this.FILE_YEARS}
                    selectTitle="doc year"
                    value={docYear}
                  />
                </div>
              )}

              {isMonthlyStatement && (
                <div className="col-xs-12 col-md-3">
                  <SelectComponent
                    name="docMonth"
                    onChange={this.onInputChange}
                    options={YEAR_MONTHS}
                    selectTitle="doc month"
                    value={docMonth}
                  />
                </div>
              )}
            </div>

            {(isK1File || isCertificate) && (
              <div className="row">
                <SelectComponent
                  xs={12}
                  md={6}
                  name="projectType"
                  onChange={this.onInputChange}
                  options={this.PROJECT_TYPES}
                  selectTitle="Project Type"
                  value={projectType}
                />

                <SelectComponent
                  xs={12}
                  md={6}
                  name="selectedProjectId"
                  onChange={this.onInputChange}
                  options={selectedProjectList}
                  selectTitle="select project"
                  value={selectedProjectId}
                />
              </div>
            )}

            <div className="row">
              <div className="col-xs-12 col-md-6">
                <MultilineInputComponent
                  elementValue={displayNotes}
                  inputName="displayNotes"
                  inputTitle="Display notes"
                  maxInputLength={null}
                  onInputUpdate={this.onInputChange}
                />
              </div>

              <div className="col-xs-12 col-md-6">
                <MultilineInputComponent
                  elementValue={privateNotes}
                  inputName="privateNotes"
                  inputTitle="private notes"
                  maxInputLength={null}
                  onInputUpdate={this.onInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="pull-right">
            <button
              className="btn btn-warning capitalize-text"
              onClick={onCancelFileEdit}
              type="button"
            >
              <Icon iconName="times" extraClasses="white-text" /> cancel
            </button>
          </div>

          <div className="pull-right">
            <button
              className="btn btn-primary capitalize-text"
              onClick={this.onSaveChanges}
              type="button"
            >
              <Icon
                iconName={isMassUpload ? 'upload' : 'floppy-o'}
                extraClasses="white-text"
              />{' '}
              {isMassUpload ? 'Upload files' : 'save'}
            </button>
          </div>
        </div>
      </fieldset>
    );
  }
}

FileDetailsEditorComponent.propTypes = {
  editStoredFiles: PropTypes.bool.isRequired,
  fileId: PropTypes.number.isRequired,
  fileToEdit: PropTypes.object.isRequired,
  investorFileTypes: PropTypes.array.isRequired,
  isMassUpload: PropTypes.bool.isRequired,
  onCancelFileEdit: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func.isRequired,
  regCfProjectList: PropTypes.array.isRequired,
  regDProjectList: PropTypes.array.isRequired,
  selectedFileName: PropTypes.string.isRequired,
};

FileDetailsEditorComponent.defaultProps = {
  editStoredFiles: false,
  fileId: 0,
  fileToEdit: {},
  investorFileTypes: [],
  isMassUpload: false,
  onCancelFileEdit: () => null,
  onSaveChanges: () => null,
  regCfProjectList: [],
  regDProjectList: [],
  selectedFileName: '',
};

export default FileDetailsEditorComponent;
