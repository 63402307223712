import React, { Component, useEffect } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestService from './api/RequestService';
import { getHash, getImpersonateID } from './utils/General';

const Observer = ({ value, didUpdate }) => {
    useEffect(() => {
        console.log('useEffect', value);
        if (value.status === 'done' && !!value.EntityID) {
            console.log('configuring dwolla');
            didUpdate(value.EntityID);
        } else {
            console.log('Skip configuring dwolla');
        }
    }, [value]);
    return null; // component does not render anything
};

class MicDeposits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            investorEntities: [],
            Account: '',
            Fundingsource: [],
            FundingSourceId: 0,
            entityId: 0,
            DwollaFundingSourceId: '',
            entitySelected: false,
            status: '',
        };

        // this.onEntityChange = this.onEntityChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getInvestorEntities();
        this.doLoad();
    }

	loadScript = (src) => new Promise((resolve, reject) => {
	        const script = document.createElement('script');

	        script.src = src;
	        script.addEventListener('load', () => {
	            resolve();
	        });
	        script.addEventListener('error', (e) => {
	            reject(e);
	        });
	        document.body.appendChild(script);
	    });

	doLoad = () => {
	    const self = this;

	    if (window.dwolla) {
	        self.setState({ status: 'done' });
	        console.log('window.dwolla = ', window.dwolla);
	        return;
	    }
	    const my_script = this.loadScript(
	        '//cdn.dwolla.com/v2.2.0/dwolla-web.js',
	    );

	    my_script
	        .then(() => {
	            self.setState({ status: 'done' });
	            console.log('window.dwolla = ', window.dwolla);
	        })
	        .catch((err) => {
	            self.setState({ status: 'error' });
	            console.log('Dwolla load failed', err);
	        });
	};

	async getInvestorEntities() {
	    try {
	        const impersonateID = await getImpersonateID();
	        const { data: status } = await RequestService.get(
	            `/api/getInvestorEntities/${impersonateID}`,
	        );
	        const obj = {};

	        status.rows.forEach((item) => (obj[item.FundingSourceID] = item));

	        if (status.code === 200) {
	            this.setState({
	                investorEntities: status.rows,
	                mappedEntities: obj,
	            });
	        } else {
	            swal(
	                'No bank account to be verified.',
	                'Please add a bank account first.',
	                'error',
	            );
	        }
	    } catch (err) {
	        console.log(err);
	    }
	}

	async onSubmit(e) {
	    e.preventDefault();
	    this.refs.mdSubmitButton.setAttribute('disabled', 'disabled');
	    this.refs.mdSubmitButton.innerHTML = 'Please Wait ... ';

	    if (this.refs.entity.value === '0') {
	        swal('Please select a Person or Entity.', '', 'info');
	        this.refs.mdSubmitButton.removeAttribute('disabled');
	        this.refs.mdSubmitButton.innerHTML = 'Submit';
	    } else if (this.refs.dep1.value === '' || this.refs.dep2.value === '') {
	        swal(
	            'Please correctly enter the micro deposit amounts.',
	            '',
	            'info',
	        );
	        this.refs.mdSubmitButton.removeAttribute('disabled');
	        this.refs.mdSubmitButton.innerHTML = 'Submit';
	    } else {
	        let formBody = [];

	        formBody.push(
	            `entityID=${encodeURIComponent(this.state.entityId)}`,
	        );
	        formBody.push(
	            `Fundingsource=${encodeURIComponent(this.state.Fundingsource)}`,
	        );
	        formBody.push(
	            `deposit1=${encodeURIComponent(this.refs.dep1.value)}`,
	        );
	        formBody.push(
	            `deposit2=${encodeURIComponent(this.refs.dep2.value)}`,
	        );
	        formBody.push(
	            `fundingSourceId=${encodeURIComponent(
	                this.state.FundingSourceId,
	            )}`,
	        );
	        formBody = formBody.join('&');
	        const impersonateID = await getImpersonateID();
	        const { data: status } = await RequestService.post({
	            url: `/api/verifyMicroDeposits/${impersonateID}`,
	            data: formBody,
	            headers: {
	                Accept: 'application/json',
	                'Content-Type': 'application/x-www-form-urlencoded',
	            },
	        });

	        if (status.code === 200) {
	            swal('Your bank account has been verified', '', 'success').then(
	                () => {
	                    this.props.history.push('/app/UserNotifications');
	                },
	            );
	        } else if (status.code === 400) {
	            swal(status.msg, 'Please try again.', 'error');
	        } else {
	            alert('Unknown Error');
	        }
	    }
	}

	render() {
	    const { DwollaFundingSourceId, DwollaCustomerId } = this.state;
	    const pathname = window.location.pathname.toLocaleLowerCase();
	    const openMDDwollaDropInComponent = (EntityID) => {
	        const { dwolla } = window;

	        console.log('status', this.state?.status, 'dwolla', dwolla);

	        if (!EntityID) {
	            swal('Please select a Person or Entity.', '', 'info');
	        } else {
	            dwolla.configure({
	                environment: 'production',
	                tokenUrl: `/api/getDwollaToken/${this.state?.id}/${EntityID}`,
	                success: (res) => Promise.resolve(res).then(async (res) => {
	                        console.log(
	                            'mic verified successfully res is',
	                            res,
	                        );
	                        let formBody = [];

	                        formBody.push(
	                            `userId=${encodeURIComponent(this.state?.id)}`,
	                        );
	                        formBody.push(
	                            `entityID=${encodeURIComponent(EntityID)}`,
	                        );
	                        formBody.push(
	                            `Fundingsource=${encodeURIComponent(
									this.state?.Fundingsource,
	                            )}`,
	                        );
	                        formBody.push(
	                            `verifyMdRes=${encodeURIComponent(
	                                JSON.stringify(res),
	                            )}`,
	                        );
	                        formBody.push(
	                            `fundingSourceId=${encodeURIComponent(
									this.state?.FundingSourceId,
	                            )}`,
	                        );
	                        console.log('formbody', formBody);
	                        formBody = formBody.join('&');
	                        const impersonateID = await getImpersonateID();

	                        if (res?.response?._links?.self?.href) {
	                            if (pathname.match('/verifymicrodeposits')) {
	                                const { data: status } =										await RequestService.post({
										    url: `/api/microDepositsverified/${
										        impersonateID ?? this.state?.id
										    }`,
										    data: formBody,
										    headers: {
										        Accept: 'application/json',
										        'Content-Type':
													'application/x-www-form-urlencoded',
										    },
	                            });

	                                if (status.code === 200) {
	                                    swal(
	                                        'Your bank account has been verified',
	                                        '',
	                                        'success',
	                                    ).then(() => {
	                                        this.props.history.push(
	                                            '/app/UserNotifications',
	                                        );
	                                        window.location.reload();
	                                    });
	                                } else if (status.code === 400) {
	                                    swal(
	                                        status.msg,
	                                        'Please try again.',
	                                        'error',
	                                    ).then(() => window.location.reload());
	                                } else {
	                                    alert('Unknown Error');
	                                }
	                            } else {
	                                console.log('wrong pathname');
	                                swal(
	                                    'Error occured Please Try again',
	                                    '',
	                                    'info',
	                                ).then(() => {
	                                    window.location.reload();
	                                });
	                            }
	                        } else if (
								res?.response?.message == 'Too many attempts.'
	                        ) {
	                            let failedFormBody = [];

	                            failedFormBody.push(
	                                `userId=${encodeURIComponent(
										this.state?.id,
	                                )}`,
	                            );
	                            failedFormBody.push(
	                                `entityID=${encodeURIComponent(EntityID)}`,
	                            );
	                            failedFormBody.push(
	                                `Fundingsource=${encodeURIComponent(
										this.state?.Fundingsource,
	                                )}`,
	                            );
	                            failedFormBody.push(
	                                `failedMdRes=${encodeURIComponent(
	                                    JSON.stringify(res),
	                                )}`,
	                            );
	                            failedFormBody.push(
	                                `fundingSourceId=${encodeURIComponent(
										this.state?.FundingSourceId,
	                                )}`,
	                            );
	                            console.log('failedFormbody', failedFormBody);
	                            failedFormBody = failedFormBody.join('&');
	                            const { data: failedRes } =									await RequestService.post({
									    url: '/api/DeleteFundingSource/',
									    data: failedFormBody,
									    headers: {
									        Accept: 'application/json',
									        'Content-Type':
												'application/x-www-form-urlencoded',
									    },
	                        });

	                            if (failedRes?.code === 200) {
	                                swal(
	                                    'Your bank account has not yet been properly verified. Please wait 48 hours and try again, or contact customer support at 713-863-3297 or info@energyfunders.com for assistance',
	                                    '',
	                                    'error',
	                                ).then(() => {
	                                    this.props.history.push(
	                                        '/app/UserNotifications',
	                                    );
	                                    window.location.reload();
	                                });
	                            }
	                        } else {
	                            console.log('Invalid micro deposite amount');
	                        }
	                    }),
	                error: async (err) => {
	                    console.log('error on setting up dwolla', err);
	                    swal(
	                        'Error while adding bank account.',
	                        `${JSON.stringify(err)}`,
	                        'error',
	                    );
	                },
	            });
	        }
	    };

	    const onEntityChange = ({ target }) => {
	        const { mappedEntities } = this.state;
	        const { value } = target;
	        const account = mappedEntities?.[value];
	        const AccountNum = account.AccountNumber?.slice(
	            account.AccountNumber.length - 4,
	            account.AccountNumber.length,
	        );

	        this.setState({
	            Account: `xxxx-xxxx-${AccountNum}`,
	            Fundingsource: account?.FundingSourceURL,
	            FundingSourceId: account?.FundingSourceID,
	            entityId: account?.EntityID,
	            DwollaFundingSourceId:
					account.FundingSourceURL?.split('funding-sources/')[1],
	            entitySelected: true,
	            DwollaCustomerId:
					account?.DwollaCustomerURL?.split('customers/')[1],
	        });
	        // openMDDwollaDropInComponent(account.EntityID);
	    };

	    console.log('this.state', this.state);
	    return (
	        <div className="container body">
	            <Observer
	                value={{
	                    EntityID: parseFloat(this.state.entityId),
	                    status: this.state.status,
	                }}
	                didUpdate={openMDDwollaDropInComponent}
    />
	            <div className="main_container">
	                {/* page content */}
	                <div className="right_col" role="main">
	                    <div className="container">
	                        <div className="row">
	                            <div className="col-md-2"> </div>

	                            <div
	                                className="col-sm-8"
	                                style={{ paddingTop: 50 }}
    >
	                                <div className="panel panel-default">
	                                    <div className="panel-body form-horizontal payment-form">
        <p>It may take 2-3 business days for the micro deposits to be completed. Once received, please select your Entity and enter the amounts to complete the verification.</p>
	                                        <div className="form-group">
	                                            <label
	                                                htmlFor="text"
	                                                className="mb-4 control-label"
    >
        Select Your Entity:
    </label>
	                                            <select
	                                                className="col-sm-9 col-xs-12 col-md-9 microdeposit-form-control form-control"
	                                                ref="entity"
	                                                onChange={onEntityChange}
	                                                id="category"
    >
	                                                <option value={0}>
        Select a Person or
        Entity
	                                                </option>
	                                                {this.state.investorEntities.map(
	                                                    (ent) => (
	                                                        <option
	                                                            key={getHash(
	                                                                ent,
	                                                            )}
	                                                            value={
	                                                                ent.FundingSourceID
	                                                            }
    >
	                                                            {`
                                                                    Account: ${ent.AccountName} |
                                                                    Entity: ${ent.EntityName} | 
                                                                    Bank: ${ent.BankName}
                                                                    `}
    </option>
	                                                    ),
	                                                )}
    </select>
    </div>
	                                        {this.state.entitySelected && (
    <div className="text-center mb-2">
	                                                <dwolla-micro-deposits-verify
	                                                    customerId={
	                                                        DwollaCustomerId
	                                                    }
	                                                    fundingSourceId={
	                                                        DwollaFundingSourceId
	                                                    }
        />
	                                            </div>
	                                        )}
	                                        {/* <div className="form-group">
												<label htmlFor="concept" className="col-sm-3 col-xs-12 col-md-3 control-label">Account Number :</label>
												<div className="col-sm-9 col-xs-12 col-md-9">
													{this.state.Account}
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="description" className="col-sm-3 col-xs-12 col-md-3 control-label">Deposit 1: &nbsp;&nbsp; $</label>
												<div className="col-sm-9 col-xs-12 col-md-9" style={{ paddingLeft: 0 }}>
													<input type="text" ref="dep1" id="text" placeholder="0.00" name="text" className="microdeposit-form-control form-control" />
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="text" className="col-sm-3 col-xs-12 col-md-3 control-label">Deposit 2: &nbsp;&nbsp; $</label>
												<div className="col-sm-9 col-xs-12 col-md-9" style={{ paddingLeft: 0 }}>
													<input type="text" ref="dep2" id="text1" placeholder="0.00" name="text" className="microdeposit-form-control form-control" />
												</div>
											</div> */}
	                                        {/* <center><button type="button" ref="mdSubmitButton" onClick={this.onSubmit} className="btn btn-success">Submit</button></center> */}
    </div>
    </div>
    </div>

	                            <div className="col-md-2"> </div>
    </div>
    </div>
    </div>
    </div>
    </div>
	    );
	}
}

const { object, bool } = PropTypes;

MicDeposits.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(MicDeposits));
